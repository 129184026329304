<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.3183 10.5867L17.2683 5.63672L18.6823 7.05072L13.7323 12.0007L18.6823 16.9507L17.2683 18.3647L12.3183 13.4147L7.36835 18.3647L5.95435 16.9507L10.9043 12.0007L5.95435 7.05072L7.36835 5.63672L12.3183 10.5867Z"
      fill="#242C33"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CloseIcon',
});
</script>
