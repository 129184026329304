import { Module } from 'vuex';
import { RootState } from '../State';
import { Vehicle, Dealership } from '@/types/ServiceModels';

export interface ScheduleTestDriveContact {
  first: string;
  last: string;
  email: string;
  mobile: string;
}

type TestDriveModalView = 'dayAndTime' | 'contactInfo' | 'confirmation';

export interface ScheduleTestDriveState {
  day: string;
  time: string;
  contact: ScheduleTestDriveContact;
  view: TestDriveModalView;
  vehicle: Vehicle;
  dealership: Dealership;
}

const state = (): ScheduleTestDriveState => ({
  day: '',
  time: '',
  contact: {
    first: '',
    last: '',
    email: '',
    mobile: '',
  },
  view: 'dayAndTime',
  vehicle: {
    year: 0,
    make: '',
    model: '',
    trim: '',
    modelNumber: '',
    vin: '',
    miles: 0,
    stockNumber: '',
    dealership: '',
    sellingPrice: 0,
    images: [],
    lowRezImage: '',
    estimatedPayLow: 0,
    estimatedPayHigh: 0,
    url: '',
    shippable: false,
  },
  dealership: {
    distance: 0,
    zip: '',
    storeName: '',
    storeId: '',
    dealerId: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    url: '',
    storeHours: '',
    timeZone: '',
  },
});

export const ScheduleTestDriveModule = (): Module<ScheduleTestDriveState, RootState> => {
  return {
    namespaced: true,
    state,
    mutations: {
      setTestDriveContact(state, value: ScheduleTestDriveContact) {
        state.contact = value;
      },
      setTestDriveDay(state, value: string) {
        state.day = value;
      },
      setTestDriveTime(state, value: string) {
        state.time = value;
      },
      setTestDriveView(state, value: TestDriveModalView) {
        state.view = value;
      },
      setTestDriveVehicle(state, value: Vehicle) {
        state.vehicle = value;
      },
      setTestDriveDealership(state, value: Dealership) {
        state.dealership = value;
      },
      resetStoreDefaults(state) {
        state.contact = {
          first: '',
          last: '',
          email: '',
          mobile: '',
        };
        state.day = '';
        state.time = '';
        state.view = 'dayAndTime';
        state.vehicle = {
          year: 0,
          make: '',
          model: '',
          trim: '',
          modelNumber: '',
          vin: '',
          miles: 0,
          stockNumber: '',
          dealership: '',
          sellingPrice: 0,
          images: [],
          lowRezImage: '',
          estimatedPayLow: 0,
          estimatedPayHigh: 0,
          url: '',
          shippable: false,
        };
        state.dealership = {
          distance: 0,
          zip: '',
          storeName: '',
          storeId: '',
          dealerId: '',
          phone: '',
          address: '',
          city: '',
          state: '',
          url: '',
          storeHours: '',
          timeZone: '',
        };
      },
    },
    actions: {},
    getters: {
      day: (state) => state.day,
      time: (state) => state.time,
      view: (state) => state.view,
      dealership: (state) => state.dealership,
      vehicle: (state) => state.vehicle,
      contact: (state) => state.contact,
    },
  };
};
