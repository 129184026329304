import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_ctx.languageIsChanging)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('Language is changing')) + "...", 1))
      : _createCommentVNode("", true)
  ], 64))
}