import * as routeUtil from '@/util/routeUtilFunctions';
import { Router } from 'vue-router';
import emitter from '@/util/eventBus';
import { Store } from 'vuex';
import { RootState } from '@/store/State';

export function resolveUrl(store: Store<RootState>, router: Router, params) {
  const { url, canonicalUrl } = resolveUrls(params);
  setUrl(store, router, url, canonicalUrl);
}

function resolveUrls(params) {
  const { facetFilters, itemPath } = params;

  const buildUrlWithOptions = (options) => {
    const pathParams = buildPathParams(facetFilters, options);
    const queryParamsList = buildQueryParams(params, options);
    const finalPath = buildFinalPath(pathParams, queryParamsList);
    return buildUrl(finalPath, itemPath);
  };

  const urlOptions = {
    addNumeric: true,
    addRadius: true,
    addMmt: true,
    addShip: true,
    addSort: true,
    addDealerships: true,
    addString: true,
    addPage: true,
    addRemainder: true,
    isCanonical: false,
  };

  const canonicalOptions = {
    addNumeric: false,
    addRadius: false,
    addMmt: true,
    addShip: false,
    addSort: false,
    addDealerships: false,
    addString: true,
    addPage: true,
    addRemainder: false,
    isCanonical: true,
  };

  const url = buildUrlWithOptions(urlOptions);
  const canonicalUrl = buildUrlWithOptions(canonicalOptions);

  return { url, canonicalUrl };
}

function buildPathParams(facetFilters, options) {
  const params = [];

  if (options.addMmt) routeUtil.addMmtPathParam(params, facetFilters, options.isCanonical);
  if (options.addString) routeUtil.addStringPathParams(params, facetFilters, options.isCanonical);

  return params;
}

function buildQueryParams(params, options) {
  const {
    facetFilters,
    queryParams,
    ship,
    sortType,
    includedDealerships,
    radius,
    shopByStore,
    layoutPath,
    remainingParams,
  } = params;
  const qParams: string[] = [];

  if (options.addNumeric) routeUtil.addNumericQueryParams(qParams, facetFilters);
  if (options.addPage) routeUtil.addPageQueryParam(qParams, queryParams);
  if (options.addShip) routeUtil.addShipQueryParam(qParams, ship);
  if (options.addSort) routeUtil.addSortQueryParam(qParams, sortType);
  if (options.addDealerships)
    routeUtil.addDealershipsQueryParam(qParams, includedDealerships, layoutPath);
  if (options.addRadius) routeUtil.addRadiusQueryParam(qParams, radius, shopByStore);
  if (options.addRemainder) routeUtil.addRemainingQueryParam(qParams, remainingParams);
  return qParams;
}

function buildFinalPath(pathParams, queryParams) {
  let finalPath = pathParams.length > 0 ? `/${pathParams.join('/')}` : '';
  if (queryParams.length > 0) finalPath += `?${queryParams.join('&')}`;
  return finalPath;
}

export function buildUrl(finalPath, itemPath) {
  return itemPath === '/' ? finalPath : `${itemPath}${finalPath}`;
}

function setUrl(store: Store<RootState>, router: Router, url, canonicalUrl) {
  if (typeof window !== 'undefined') {
    if (!window.location.href.endsWith(url)) {
      emitter.emit('header-data-changed', {
        canonical: window.location.origin + canonicalUrl,
      });
      router.push(url);
      store.commit('common/incrementUrlCount');
    }
  }
}
