import VehicleSearchClient from '@/clients/vehicleSearchClient';
import DealershipSearchClient from '@/clients/dealershipSearchClient';
import CountSearchClient from '@/clients/countSearchClient';
import { Dealership } from '@/types/ServiceModels';
import conf from '@/appConfig';
import axios from 'axios';
import { Store } from 'vuex';
import { RootState } from '@/store/State';

async function executeClientRequest(store: Store<RootState>, client, setDataCallback) {
  let data = null;
  store.commit('searchResults/setLoading', true);
  try {
    const response = await client.execute();
    data = response.data;
    setDataCallback(data);
  } catch (error) {
    console.error(error);
  }
  store.commit('searchResults/setLoading', false);
}

function addCommonClientParameters(store: Store<RootState>, client) {
  const zip = store.state['common'].zipCode;

  const shopByStore = store.state['searchResults'].shopByStore;
  const includedDealerships = store.state['searchResults'].includedDealerships;
  const shipToStore = store.state['searchResults'].shipToStore;
  const radius = store.state['searchResults'].radius;

  client.addZip(zip);

  if (shopByStore) {
    client.addDealers(includedDealerships);
    client.setShippable(shipToStore);
  } else {
    client.addRadius(radius);
    client.setShippable(shipToStore ?? true);
  }
}

export async function fetchSrpVehicles(store: Store<RootState>, context) {
  const { facetFilters, take, page, sortType } = context;

  const client = VehicleSearchClient(axios, conf.vehicleApiEndpoint);

  Object.keys(facetFilters).forEach((facetFilter) => {
    client.addFacetFilter(facetFilter, facetFilters[facetFilter]);
  });

  client.setPageSize(take);
  client.setPageNumber(page);

  addCommonClientParameters(store, client);

  if (sortType.sortBy !== null && sortType.sortDirection !== null) {
    client.setSortBy(sortType.sortBy);
    client.setSortDirection(sortType.sortDirection);
  }

  await executeClientRequest(store, client, (data) => {
    if (data) {
      store.commit('searchResults/setSrpVehiclesData', data);
    }
  });
}

/* vue3 upgrade note: changed name from fetchSrpDealerships to fetchDealerships */
export async function fetchDealerships(zipCode: string): Promise<Dealership[]> {
  const client = DealershipSearchClient(axios, conf.dealershipApiEndpoint, zipCode);
  const response = await client.execute();
  return response.data?.items || [];
}

export async function fetchFacetCounts(store: Store<RootState>, facetToExclude: string) {
  const facetFilters = store.state['searchResults'].facetFilters;

  const client = CountSearchClient(axios, conf.countApiEndpoint, undefined);

  Object.keys(facetFilters).forEach((facetFilter) => {
    if (facetFilter !== facetToExclude) {
      client.addFacetFilter(facetFilter, facetFilters[facetFilter]);
    }
  });

  addCommonClientParameters(store, client);

  await executeClientRequest(store, client, (data) => {
    if (data?.facets) {
      store.commit('searchResults/setFacetCounts', data.facets);
    }
  });
}

export function scrollToTop() {
  if (typeof window !== 'undefined') {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}
