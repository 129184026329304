<template>
  <div class="divRangeDropdown" data-testid="div-range-dropdown">
    <span class="lblTitle" data-testid="label-title">{{ title }}</span>
    <div class="divLblMinMaxOuter" data-testid="label-min-max-outer">
      <label class="selectMinLbl" for="textbox" data-testid="range-label-min">{{ minLabel }}</label>
      <label class="selectMaxLbl" for="textbox" data-testid="range-label-max">{{ maxLabel }}</label>
    </div>
    <div class="divMinMaxSelectContainer" data-testid="min-max-select-container">
      <div class="divMinSelectContainer" data-testid="min-select-container">
        <Dropdown
          :options="options.map((option) => ({ value: option, label: option }))"
          @optionSelected="emitUpdate"
          v-model="selectedValue[0]"
        />
      </div>
      <div class="divMaxSelectContainer" data-testid="max-select-container">
        <Dropdown
          :options="options.map((option) => ({ value: option, label: option }))"
          @optionSelected="emitUpdate"
          v-model="selectedValue[1]"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Dropdown from './Dropdown.vue';

export default defineComponent({
  name: 'RangeDropdown',
  props: {
    title: {
      type: String,
    },
    minLabel: {
      type: String,
    },
    maxLabel: {
      type: String,
    },
    options: {
      type: Array<string>,
      default: function () {
        return ['2000', '2023'];
      },
    },
    selected: {
      type: Array<string>,
      default: function () {
        return ['2000', '2023'];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    emitUpdate() {
      this.$emit('optionSelected', this.selected);
    },
  },
  components: {
    Dropdown,
  },
  computed: {
    selectedValue() {
      return this.selected;
    },
  },
});
</script>

<style lang="scss">
.divRangeDropdown {
  .divLblMinMaxOuter,
  .divMinMaxSelectContainer {
    padding: 0 24px 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .divMinMaxSelectContainer {
    padding-bottom: 30px;
  }
  .selectMinLbl,
  .selectMaxLbl {
    display: inline-block;
    width: 100px;
    color: var(--park-gray-20);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .divMinSelectContainer,
  .divMaxSelectContainer {
    width: 100px;

    select {
      width: 100%;
    }
  }
  .selectMin,
  .selectMax {
    border-radius: 8px;
    border: 1px solid var(--park-gray-80);
    padding: 8px;
    width: 100%;
    height: 40px;
  }
  .unselectedTrack {
    background-color: var(--park-gray-20);
  }
}
</style>
