import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-vin"]
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "vehicle-card-top" }
const _hoisted_4 = { class: "vehicleInfoWrapper" }
const _hoisted_5 = { class: "vehicleInfoWrapperPriceLocation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FavoriteButton = _resolveComponent("FavoriteButton")!
  const _component_VehicleCardCarousel = _resolveComponent("VehicleCardCarousel")!
  const _component_VehicleCardDetails = _resolveComponent("VehicleCardDetails")!
  const _component_VehicleCardPrice = _resolveComponent("VehicleCardPrice")!
  const _component_VehicleCardLocation = _resolveComponent("VehicleCardLocation")!
  const _component_VehicleCardButtons = _resolveComponent("VehicleCardButtons")!

  return (_openBlock(), _createElementBlock("div", {
    class: "vehicleCardPaper",
    "data-vin": _ctx.vehicle.vin
  }, [
    _createElementVNode("a", {
      href: _ctx.vehicle.url,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFullTileClick && _ctx.handleFullTileClick(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FavoriteButton, {
          favorite: _ctx.isCarFavoriteData,
          onToggle: _ctx.handleToggle
        }, null, 8, ["favorite", "onToggle"]),
        _createVNode(_component_VehicleCardCarousel, {
          imageList: _ctx.imageList,
          lowRezImage: _ctx.vehicle.lowRezImage || _ctx.imageList[0],
          imageAltText: `${_ctx.vehicle.year} ${_ctx.vehicle.make} ${_ctx.vehicle.model}`,
          seeMoreOnClick: _ctx.handleFullTileClick
        }, null, 8, ["imageList", "lowRezImage", "imageAltText", "seeMoreOnClick"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_VehicleCardDetails, { vehicle: _ctx.vehicle }, null, 8, ["vehicle"]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_VehicleCardPrice, {
            vehicle: _ctx.vehicle,
            showPayEstimate: _ctx.showPaymentEstimates,
            ref: "vehicleCardPrice"
          }, null, 8, ["vehicle", "showPayEstimate"]),
          _createVNode(_component_VehicleCardLocation, {
            shippingLabel: _ctx.shippingTitle,
            shippingStore: _ctx.shippingDetails
          }, null, 8, ["shippingLabel", "shippingStore"])
        ]),
        _createVNode(_component_VehicleCardButtons, { vehicle: _ctx.vehicle }, null, 8, ["vehicle"])
      ])
    ], 8, _hoisted_2)
  ], 8, _hoisted_1))
}