import https from 'https';
import * as facetManager from '@util/facetFiltersFactory';

const CountSearchClient = function (axios, baseUrl, _facetName) {
  const __baseUrl = baseUrl;
  const __searchParameters = {};
  const __searchFacets = facetManager.FacetFiltersManager();

  const addParameter = function (name, value) {
    __searchParameters[name] = value;
  };

  /**
   * @param {string} facetName
   */
  const addFacetName = function (facetName) {
    addParameter('facetName', facetName);
  };

  const addFacetFilter = function (name, value) {
    const facetFilter = __searchFacets.createFacetFilter(name, value);
    __searchFacets.addFacetFilter(facetFilter);
  };

  /**
   * @param {number} zip
   */
  const addZip = function (zip) {
    addParameter('zip', zip);
  };

  /**
   * @param {number} radius
   */
  const addRadius = function (radius) {
    if (radius > 0) {
      addParameter('radius', radius);
    }
  };

  /**
   * @param [] dealers
   */
  const addDealers = function (dealers) {
    addParameter('dealer', dealers);
  };

  /**
   * @param {boolean} shippable - False when value = 0.
   */
  const setShippable = function (shippable) {
    addParameter('ship', shippable);
  };

  /**
   * @param {number} storeId
   */
  const setDealershipIdAsLocation = function (storeId) {
    addParameter('storeloc', storeId);
  };

  /**
   * @param {string} dealershipName
   */
  const setDealershipNameAsLocation = function (dealershipName) {
    addParameter('dealerloc', dealershipName);
  };

  const execute = function () {
    for (const key in __searchParameters) {
      if (Array.isArray(__searchParameters[key])) {
        __searchParameters[key] = __searchParameters[key].join('|');
      }
    }

    const params = {
      ...__searchParameters,
    };

    if (__searchFacets.facetCount() > 0) {
      params['facets'] = __searchFacets.toQueryString();
    }

    return axios.get(__baseUrl, {
      params,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    });
  };

  return {
    addFacetName,
    execute,
    addRadius,
    addZip,
    addDealers,
    setShippable,
    setDealershipIdAsLocation,
    setDealershipNameAsLocation,
    addFacetFilter,
  };
};

export default CountSearchClient;
