import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mileage-filter" }
const _hoisted_2 = { "data-role": "facetfilter" }
const _hoisted_3 = { class: "lblTitle" }
const _hoisted_4 = {
  class: "mileageDropdown",
  "data-action-set": "mileage-value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMenuLineItem = _resolveComponent("FilterMenuLineItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterMenuLineItem, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandFilter())),
            isSelected: !!_ctx.facetFilters[_ctx.filterType],
            title: _ctx.$t('Mileage Filter Title')
          }, null, 8, ["isSelected", "title"])
        ], 512), [
          [_vShow, !_ctx.mileageFilterSelected && !_ctx.filterSelected]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_3, [
            _createElementVNode("b", null, _toDisplayString(_ctx.title), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Dropdown, {
              options: _ctx.mileOptions,
              onInput: _ctx.handleOptionSelected,
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event))
            }, null, 8, ["options", "onInput", "modelValue"])
          ])
        ], 512), [
          [_vShow, _ctx.mileageFilterSelected]
        ])
      ])
    ]),
    _: 1
  }))
}