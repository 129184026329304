<template>
  <div class="round-check-toggle-switch">
    <label
      class="switch"
      :for="componentName"
      @click.prevent="updateToggle"
      @keyup.prevent="updateToggle"
      tabindex="0"
    >
      <input
        type="checkbox"
        v-model="toggleValue"
        :checked="toggleValue"
        :disabled="disabled"
        :id="componentName"
      />
      <span class="slider round" :class="{ 'toggle-disabled': disabled }"></span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RoundCheckToggleSwitch',
  props: {
    modelValue: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    componentName: String,
  },
  computed: {
    toggleValue() {
      return this.modelValue;
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateToggle() {
      if (!this.disabled) {
        this.$emit('update:modelValue', !this.toggleValue);
      }
    },
  },
});
</script>

<style lang="scss">
.round-check-toggle-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 27px;

    input {
      display: none;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
    &.toggle-disabled {
      background-color: #d4d7d9;
    }
    &:before {
      position: absolute;
      content: '';
      height: 21px;
      width: 21px;
      left: 3px;
      bottom: 3.2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }
  input:checked + .slider {
    background-color: #5c9c08;

    &:before {
      background-image: url(@assets/icons/toggleOn.svg);
      transform: translateX(26px);
    }
  }
}
</style>
