import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@icons/calculator-hover.svg'
import _imports_1 from '@icons/calculator.svg'
import _imports_2 from '@icons/steeringWheel-hover.svg'
import _imports_3 from '@icons/steeringWheel.svg'
import _imports_4 from '@icons/subtract-hover.svg'
import _imports_5 from '@icons/subtract.svg'


const _hoisted_1 = { class: "SrpButton" }
const _hoisted_2 = {
  key: 0,
  tabindex: "0",
  class: "default-button calculator-button"
}
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  class: "onhover",
  alt: "calculator icon"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  class: "initial",
  alt: "calculator icon"
}
const _hoisted_5 = {
  key: 0,
  class: "onhover",
  src: _imports_2,
  alt: "steering wheel icon"
}
const _hoisted_6 = {
  key: 1,
  class: "initial",
  src: _imports_3,
  alt: "steering wheel icon"
}
const _hoisted_7 = {
  key: 2,
  class: "onhover",
  src: _imports_4,
  alt: "subtract icon"
}
const _hoisted_8 = {
  key: 3,
  class: "initial",
  src: _imports_5,
  alt: "subtract icon"
}
const _hoisted_9 = { class: "SrpButtonText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.onlyIcon)
      ? (_openBlock(), _createElementBlock("button", _hoisted_2, [
          (_ctx.calculator)
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.calculator)
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.navigateToLinkClick && _ctx.navigateToLinkClick(...args))),
          tabindex: "0",
          class: _normalizeClass(["wideButton", { 'carousel-button': _ctx.carousel, 'default-button': !_ctx.carousel }])
        }, [
          (_ctx.steeringWheel)
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.steeringWheel)
            ? (_openBlock(), _createElementBlock("img", _hoisted_6))
            : _createCommentVNode("", true),
          (_ctx.subtract)
            ? (_openBlock(), _createElementBlock("img", _hoisted_7))
            : _createCommentVNode("", true),
          (_ctx.subtract)
            ? (_openBlock(), _createElementBlock("img", _hoisted_8))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.buttonText), 1)
        ], 2))
  ]))
}