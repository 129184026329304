<template>
  <div v-if="hasResults" class="disclaimer-container" id="disclaimer-container">
    <sc-rich-text :field="fields.description"> </sc-rich-text>
  </div>
</template>

<script lang="ts">
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { mapState } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Disclaimer',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ScRichText: RichText,
  },
  computed: {
    ...mapState('searchResults', ['srpVehiclesData', 'loading']),
    resultCount() {
      if (!this.srpVehiclesData) return -1;
      return this.srpVehiclesData.resultCount;
    },
    hasResults() {
      return !this.loading && this.resultCount > 0;
    },
  },
});
</script>

<style lang="scss">
.disclaimer-container {
  order: 8;
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: justify;
  padding: 14px 24px 10px;
  max-width: 100vw;

  @media only screen and (min-width: 1240px) {
    max-width: calc(100vw - 324px);
  }
}
.disclaimer-container-dynamic-link-area {
  padding-bottom: 40px;
}
</style>
