<template>
  <div class="text-button-carousel">
    <h6 class="textButtonCarousel-title">
      {{ title }}
    </h6>
    <nav class="textButtonCarousel-container">
      <ul ref="carouselScrollArea" :id="carouselScrollAreaId" class="textButtonCarousel-scrollArea">
        <li v-for="txtButton in textBunttonList" :key="txtButton.link">
          <a :href="txtButton.link">{{ txtButton.text }}</a>
        </li>
      </ul>
      <ul class="textButtonCarousel-navigation">
        <li>
          <button
            ref="btnNavLeft"
            :id="btnNavLeftId"
            type="button"
            v-on:click="slideLeft()"
            class="navLeft navButton"
            disabled
          >
            <svg
              :width="buttonSize"
              :height="buttonSize"
              :viewBox="buttonViewboxSize"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1722 12L8.22217 7.04999L9.63617 5.63599L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z"
                fill="#242C33"
              />
            </svg>
          </button>
        </li>
        <li>
          <button :id="btnNavRightId" type="button" v-on:click="slideRight()" class="navButton">
            <svg
              :width="buttonSize"
              :height="buttonSize"
              :viewBox="buttonViewboxSize"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1722 12L8.22217 7.04999L9.63617 5.63599L16.0002 12L9.63617 18.364L8.22217 16.95L13.1722 12Z"
                fill="#242C33"
              />
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextButtonCarousel',
  props: {
    textBunttonList: Array,
    carouselId: String,
    title: String,
    btnStyle: String,
  },
  data() {
    return {
      buttonSize: 25,
    };
  },
  computed: {
    buttonViewboxSize() {
      return `0 0 ${this.buttonSize} ${this.buttonSize}`;
    },
    carouselScrollAreaId() {
      return this.carouselId + 'ScrollArea';
    },
    btnNavRightId() {
      return this.carouselId + 'NavRight';
    },
    btnNavLeftId() {
      return this.carouselId + 'NavLeft';
    },
  },
  components: {},
  methods: {
    slideLeft() {
      const carousel = this.$refs.carouselScrollArea as HTMLUListElement;
      let firstHidden = getFirstHiddenToLeft(carousel);
      if (firstHidden) {
        let parent = carousel;

        carousel.scrollLeft = carousel.scrollLeft - carousel.getBoundingClientRect().width - 12;

        if (this.$refs.btnNavRight) {
          (this.$refs.btnNavRight as HTMLButtonElement).disabled = false;
        }
        if (parent.scrollLeft === 0 && this.$refs.btnNavLeft) {
          (this.$refs.btnNavLeft as HTMLButtonElement).disabled = true;
        }
      } else if (this.$refs.btnNavLeft) {
        (this.$refs.btnNavLeft as HTMLButtonElement).disabled = true;
      }
    },
    slideRight() {
      const carousel = this.$refs.carouselScrollArea as HTMLUListElement;
      let firstHidden = getFirstHiddenToRight(carousel);
      if (firstHidden && firstHidden instanceof Element) {
        let scrollWidth = carousel.scrollWidth;
        let parentRect = carousel.getBoundingClientRect();
        let childRect = firstHidden.getBoundingClientRect();

        carousel.scrollLeft += childRect.left - parentRect.left + 24;

        if (this.$refs.btnNavLeft) {
          (this.$refs.btnNavLeft as HTMLButtonElement).disabled = false;
        }
        if (
          Math.floor(carousel.scrollLeft + childRect.right) > scrollWidth &&
          this.$refs.btnNavRight
        ) {
          (this.$refs.btnNavRight as HTMLButtonElement).disabled = true;
        }
      } else if (this.$refs.btnNavRight) {
        (this.$refs.btnNavRight as HTMLButtonElement).disabled = true;
      }
    },
    validateAndDisableRightArrow() {
      const carousel = this.$refs.carouselScrollArea as HTMLUListElement;
      if (!carousel) return;

      let scrollWidth = carousel.scrollWidth;
      let elemWidth = carousel.clientWidth;

      if (
        Math.floor(scrollWidth - carousel.scrollLeft - elemWidth) === 0 &&
        this.$refs.btnNavRight
      ) {
        (document.getElementById(this.btnNavRightId) as HTMLButtonElement).disabled = true;
      }
    },
  },
  mounted() {
    this.validateAndDisableRightArrow();
  },
  watch: {
    textBunttonList: {
      handler() {
        setTimeout(() => {
          this.slideLeft();
          this.validateAndDisableRightArrow();
        }, 500);
      },
    },
  },
});

function getFirstHiddenToRight(parent) {
  let lastChildTestedVisibility = true;
  let currentChildTested = true;
  let firstHidden;
  let lastChildTested;

  Array.prototype.slice
    ?.call(parent.childNodes)
    ?.reverse()
    ?.forEach((child) => {
      currentChildTested = isInViewRight(child, parent?.getBoundingClientRect()?.right) || false;
      if (!lastChildTestedVisibility && currentChildTested) {
        firstHidden = lastChildTested;
      }
      lastChildTestedVisibility = currentChildTested;
      lastChildTested = child;
    });
  return firstHidden;
}

function getFirstHiddenToLeft(parent) {
  let lastChildTestedVisibility = true;
  let currentChildTested = true;
  let firstHidden;
  let lastChildTested;

  if (!parent?.childNodes) {
    return;
  }

  Array.prototype.slice?.call(parent?.childNodes)?.forEach((child) => {
    currentChildTested = isInViewLeft(child, parent.getBoundingClientRect().left) || false;

    if (!lastChildTestedVisibility && currentChildTested) {
      firstHidden = lastChildTested;
    }
    lastChildTestedVisibility = currentChildTested;
    lastChildTested = child;
  });
  return firstHidden;
}

function isInViewRight(element, parent) {
  if (element instanceof Element) {
    const rect = element?.getBoundingClientRect();
    return rect?.right < parent;
  }
}

function isInViewLeft(element, parent) {
  if (element instanceof Element) {
    const rect = element.getBoundingClientRect();
    return rect.left > parent;
  }
}
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.text-button-carousel {
  .navLeft {
    transform: scaleX(-1);
  }
  .navButton {
    background-color: transparent;
    border: 0;
    width: fit-content;
    display: flex;
    padding: $spacing_inset-nano;

    &:disabled svg {
      opacity: 0.5;
    }
  }
}
.textButtonCarousel-title {
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-bottom: 24px;
}
.textButtonCarousel-container {
  width: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: var(--base-white-100);
  border: 1px solid var(--park-gray-80);
  border-radius: 12px;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  align-items: center;
  padding: $spacing_squish-nano;
}
.textButtonCarousel-scrollArea {
  white-space: nowrap;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth !important;
  width: 95%;
  display: flex;
  margin-bottom: 0;
  list-style: none;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  padding-left: 24px;
  padding-right: 24px;

  @include media-breakpoint-up(lg) {
    box-shadow: inset 15px 0 9px -7px var(--base-white-100);
  }

  &::-webkit-scrollbar {
    display: none;
  }
  li {
    &:first-child a {
      padding-left: 0;
    }
    &:last-child a {
      padding-right: 80px;
    }
  }
  a {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.textButtonCarousel-navigation {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 16px;
  display: flex;
  border-left: 1px solid var(--park-gray-80);
  background-color: var(--base-white-100);
  margin-bottom: 0;
  list-style: none;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;

  @include media-breakpoint-up(lg) {
    box-shadow: -15px 0 15px 0 var(--base-white-100);
  }
}
</style>
