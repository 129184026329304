import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@icons/Path_filtermain.svg'


const _hoisted_1 = ["data-action-select-filter"]
const _hoisted_2 = {
  class: "btn btn-sm px-4",
  tabindex: "-1"
}
const _hoisted_3 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('click')), ["enter"])),
    class: "filter-list-item",
    tabindex: "0"
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(["facet-name", { 'selected-filter': _ctx.isSelected }]),
      "data-action-select-filter": _ctx.title
    }, _toDisplayString(_ctx.title), 11, _hoisted_1),
    _createElementVNode("button", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "arrow-icon",
        alt: _ctx.$t('srp.common.rightArrowIcon')
      }, null, 8, _hoisted_3)
    ])
  ], 32))
}