import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "signInOuterContainer" }
const _hoisted_2 = {
  id: "signInContainer",
  class: "create_account"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sc_rich_text, {
        field: $props.fields.html
      }, null, 8, ["field"])
    ])
  ]))
}