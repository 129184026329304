<template>
  <div class="divRangeInput">
    <span class="lblTitle" v-if="title">{{ title }}</span>
    <div class="divLblMinMaxOuter">
      <label class="inputMinLbl" :for="`inputMin-${componentName}`">{{ minLabel }}</label>
      <label class="inputMaxLbl" :for="`inputMax-${componentName}`">{{ maxLabel }}</label>
    </div>
    <div class="divMinMaxInputContainer">
      <div class="divMinInputContainer">
        <CurrencyInput
          v-if="currency"
          :id="`inputMin-${componentName}`"
          @emitUpdate="emitUpdate"
          v-model="rangeValue[0]"
          @focus="handleFocus"
          :isMin="true"
        />
        <input
          v-else
          :id="`inputMin-${componentName}`"
          class="inputMin"
          @blur="emitUpdate"
          @keyup.enter="emitUpdate"
          @focus="handleFocus"
          type="number"
          :step="step"
          v-model="rangeValue[0]"
          data-testid="input-range-min"
        />
      </div>
      <div class="divMaxInputContainer">
        <CurrencyInput
          v-if="currency"
          :id="`inputMax-${componentName}`"
          @emitUpdate="emitUpdate"
          @focus="handleFocus"
          v-model="rangeValue[1]"
          :isMin="false"
        />
        <input
          v-else
          :id="`inputMax-${componentName}`"
          class="inputMax"
          @blur="emitUpdate"
          @keyup.enter="emitUpdate"
          @focus="handleFocus"
          type="number"
          :step="step"
          v-model="rangeValue[1]"
          data-testid="input-range-max"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CurrencyInput from './CurrencyInput.vue';

export default defineComponent({
  name: 'RangeInput',
  components: {
    CurrencyInput,
  },
  props: {
    title: {
      type: String,
    },
    minLabel: {
      type: String,
    },
    maxLabel: {
      type: String,
    },
    currencyIcon: {
      type: String,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Array<number>,
      default: function () {
        return [0, 100];
      },
    },
    setRange: {
      type: Array<number>,
      default: function () {
        return [0, 100];
      },
    },
    step: {
      type: Number,
      default: 1,
    },
    componentName: {
      type: String,
    },
  },
  emits: ['focus', 'inputValueSet'],
  data() {
    return {
      inputValue: 0,
    };
  },
  methods: {
    emitUpdate() {
      this.$emit('inputValueSet', this.range);
    },
    handleFocus() {
      this.$emit('focus');
    },
  },
  computed: {
    rangeValue() {
      return this.range;
    },
  },
});
</script>

<style lang="scss">
.divRangeInput {
  .divLblMinMaxOuter,
  .divMinMaxInputContainer {
    padding: 0 24px 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .inputMinLbl,
  .inputMaxLbl {
    display: inline-block;
    width: 100px;
    color: var(--park-gray-20);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .divMinMaxInputContainer {
    padding-bottom: 30px;
  }
  .divMinInputContainer,
  .divMaxInputContainer {
    width: 100px;
  }
  .inputMin,
  .inputMax {
    border-radius: 8px;
    border: 1px solid var(--park-gray-80);
    padding: 8px;
    width: 100%;
    height: 40px;
  }
}
</style>
