<template>
  <!--
          This component is separated into 3 sections:
          1. Checkbox behavior
          2. leftContent (slot)
          3. rightContent (slot)
      -->
  <div class="facet-list-item" :class="{ 'filter-disabled': isCheckboxDisabled }">
    <div class="divItemInfo">
      <div v-if="isCheckboxDisabled && isSelected" class="image-icon">
        <img
          src="@icons/check_disabled.png"
          alt="Check"
          class="imgCheck"
          data-testid="imgCheckSelected"
        />
      </div>
      <div v-else-if="isSelected || isInSelection" class="image-icon">
        <img
          src="@icons/check.png"
          alt="Check"
          class="imgCheck"
          data-testid="imgCheckInSelection"
        />
      </div>
      <div>
        <slot name="leftContent">
          <span :data-select-filter="item.name">{{ item.name }}&nbsp;</span>
          <span class="lblCount">({{ item.value }})</span>
        </slot>
      </div>
      <div class="rightContent">
        <slot name="rightContent">
          <sc-image
            v-if="item.scImage"
            :media="item.scImage"
            :class="{
              inSelection:
                isInSelection &&
                filterType !== facetMetaData.extcolor.key &&
                filterType !== facetMetaData.intcolor.key,
            }"
            class="sc-image float-right"
          ></sc-image>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Image } from '@sitecore-jss/sitecore-jss-vue';
import { facetMetaData } from '@util/facetHelper';
import { FacetListItemValue } from '@/types/VehicleSearch/Facets';

export default defineComponent({
  name: 'FacetListItem',
  props: {
    /* Input item:
        - name
        - value
        - scImage (optional)
    */
    item: {
      type: Object as PropType<FacetListItemValue>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isInSelection: {
      type: Boolean,
      default: false,
    },
    isCheckboxDisabled: {
      // freeze the isSelected state
      type: Boolean,
      default: false,
    },
    filterType: {
      type: String,
      default: '',
    },
  },
  components: {
    ScImage: Image,
  },
  data() {
    return {
      facetMetaData: facetMetaData,
    };
  },
});
</script>

<style lang="scss">
.facet-list-item {
  border-bottom: 1px solid var(--shadow-white);
  padding: 16px 24px 15px;
  align-self: stretch;
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  &:active:not(.filter-disabled) {
    background-color: var(--shadow-white);
  }
  &:hover:not(.filter-disabled) .facetIcon {
    filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%) contrast(103%);
  }
  .inSelection {
    filter: invert(34%) sepia(92%) saturate(1674%) hue-rotate(65deg) brightness(90%) contrast(103%);
  }
  .colorFilter & {
    padding: 8px 24px 7px;
  }
  .divItemInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .image-icon {
      display: flex;

      img {
        padding-right: 8px;
      }
    }
  }
  .rightContent {
    margin-left: auto;

    .featureFilter & img {
      height: 24px;
    }
  }
  .divFacetList .lblCount {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }
  li:not(.selected) &:hover:not(.filter-disabled),
  &:hover:not(.filter-disabled) {
    background-color: var(--park-gray-100);
    cursor: pointer;
  }
  li.selected &:hover:not(.filter-disabled) {
    background-color: var(--green-90);
    text-decoration: underline;
  }
}
</style>
