<template>
  <div class="footer" ref="footer">
    <sc-rich-text :field="fields.html" />
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, PropType } from 'vue';
import { MvcDatasourceModel } from '@lib/CommonDatasourceModels';
import RichText from '@components-vue/Foundation/Common/RichText';

export default defineComponent({
  // eslint-disable-next-line vue/no-reserved-component-names
  name: 'Footer',
  props: {
    fields: {
      type: Object as PropType<MvcDatasourceModel>,
      default: () => ({}),
    },
  },
  components: {
    ScRichText: RichText,
  },
  mounted() {
    const cookieBanner = document.getElementById('cookiePopup');
    if (cookieBanner) {
      cookieBanner.classList.add('d-none');
    }
    var contactUs = this.footer?.querySelector<HTMLElement>(
      '[data-modal-target="contactUs_modal"]'
    );
    if (contactUs && contactUs != null) {
      contactUs.setAttribute('href', '/faq');
    }
  },

  updated() {
    const cookieBanner = document.getElementById('cookiePopup');
    if (cookieBanner) {
      cookieBanner.classList.add('d-none');
    }
  },

  setup() {
    const footer = ref<HTMLElement>();
    return {
      footer,
    };
  },
});
</script>

<style lang="scss">
.footer {
  background-color: var(--park-gray-0);
  position: relative;
}

.container-fluid {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container-fluid {
    padding-right: 40px;
    padding-left: 40px;
  }
}

@media (min-width: 1440px) {
  .container-fluid {
    padding-left: 112px;
    padding-right: 112px;
  }
}

@media (min-width: 1440px) {
  .footer .container {
    max-width: inherit;
    padding-left: 112px;
    padding-right: 112px;
  }
}
.footer-primary {
  padding-top: 24px;
  margin-left: -12px;
  margin-right: -12px;
}
@media (min-width: 992px) {
  .footer-primary {
    padding: 40px 0;
    display: flex;
    flex-wrap: wrap;
  }
}
.footer-leftSection {
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
  padding-right: 12px;
}
@media (min-width: 992px) {
  .footer-leftSection {
    width: 25%;
    max-width: 310px;
    display: block;
  }
}
.footer-logo {
  width: 100%;
  max-width: 50%;
  margin-bottom: 24px;
}
@media (min-width: 992px) {
  .footer-logo {
    max-width: 176px;
  }
}
.footer-logo a img {
  width: 100%;
  max-width: 95px;
  display: inline-block;
}
@media (min-width: 992px) {
  .footer-logo a img {
    max-width: initial;
  }
}
.footer-social-links {
  width: 100%;
  max-width: 100%;
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .footer-social-links {
    max-width: 176px;
    margin-bottom: 24px;
  }
}
.footer-social-links ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 992px) {
  .footer-social-links ul {
    justify-content: space-between;
  }
}
.footer-social-links ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0 12px;
}
@media (min-width: 992px) {
  .footer-social-links ul li {
    margin: 0;
  }
}
.footer-phone {
  width: 100%;
  max-width: 50%;
  margin-bottom: 24px;
  margin-top: 26px;
  text-align: right;
}

@media (min-width: 375px) {
  .footer-phone {
    margin-top: 4px;
  }
}
@media (min-width: 992px) {
  .footer-phone {
    max-width: 25%;
    padding-right: 12px;
    padding-left: 12px;
    margin: 0 0 0 auto;
  }
}
#ancFooterPhoneDesktop {
  visibility: hidden;
}
@media (min-width: 992px) {
  #ancFooterPhoneDesktop {
    visibility: visible;
  }
}
.footer-phone .phNumber {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
  letter-spacing: normal;
  color: var(--base-white-100);
  text-decoration: none;
  padding-top: 32px;
  background: url(@assets/images/icons-communication-phone.svg) no-repeat top center;
}
@media (min-width: 375px) {
  .footer-phone .phNumber {
    padding-left: 32px;
    padding-top: 0;
    background: url(@assets/images/icons-communication-phone.svg) no-repeat left center;
  }
}
@media (min-width: 1440px) {
  .footer-phone .phNumber {
    font-size: 22px;
    font-weight: 700;
  }
}
.footer-phone .phNumber img {
  margin-right: 4px;
}

.footer-phone .phNumber,
.guest-user,
.guest-user:focus,
.guest-user:hover {
  color: var(--base-white-100);
}

.footer-phone .phNumber,
.guest-user {
  font-family: Roboto, sans-serif;
  font-style: normal;
}

.footer-phone .phNumber,
.footer-primary-navigation .footer-title,
.footer-primary-navigation li a {
  font-family: Roboto, sans-serif;
  font-style: normal;
  color: var(--base-white-100);
}

.footer-primary-navigation {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 576px) {
  .footer-primary-navigation {
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 7%;
  }
}
@media (min-width: 992px) {
  .footer-primary-navigation {
    padding-left: 0;
    width: 50%;
    max-width: 620px;
    margin-left: 0;
    margin-right: 0;
  }
}
.footer-primary-navigation-section {
  width: 50%;
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .footer-primary-navigation-section {
    width: 33.3333%;
  }
}
@media (min-width: 992px) {
  .footer-primary-navigation-section {
    margin-bottom: 0;
  }
}
.footer-primary-navigation .footer-title {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--base-white-100);
  color: var(--park-gray-60);
  font-weight: 700;
  margin-bottom: 16px;
}
.footer-primary-navigation li {
  line-height: normal;
}
.footer-primary-navigation li:not(:last-child) {
  margin-bottom: 13px;
}
.footer-primary-navigation li a {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--base-white-100);
  text-decoration: none;
  line-height: 1.14;
  display: inline-block;
}
.footer-secondary-navigation {
  padding-bottom: 40px;
}
.footer_secondary_links {
  text-align: center;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .footer_secondary_links {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .footer_secondary_links {
    margin-bottom: 0;
  }
  .order-3.order-lg-first {
    order: -1 !important;
  }
}
.footer_secondary_links ul {
  display: inline-flex;
}
.footer_secondary_links ul li {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--base-white-100);
  display: inline-block;
  vertical-align: top;
  line-height: 2.33;
}
.footer_secondary_links ul li a {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--base-white-100);
  line-height: 2.33;
  text-decoration: none;
  margin: 0 5px;
}
@media (min-width: 992px) {
  .footer_secondary_links ul li a {
    line-height: 1.33;
  }
}
.footer_secondary_links ul li:not(:last-child):after {
  content: '|';
  display: inline-block;
  vertical-align: top;
}
.footer_card {
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .footer_card {
    margin-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .footer_card {
    margin-bottom: 0;
  }
}
.footer_card ul li {
  display: inline-block;
  vertical-align: middle;
}
.footer_card ul li:not(:first-child) {
  margin-left: 20px;
}
@media (min-width: 1240px) {
  .footer_card ul li:not(:first-child) {
    margin-left: 40px;
  }
}

.footer a[target='_blank']:after {
  content: none !important;
}

.LabelWhite-Caption {
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: var(--base-white-100);
}

.popup-wrap {
  display: none;
}
</style>
