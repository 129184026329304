import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "facet-list" }
const _hoisted_2 = {
  key: 0,
  class: "lblTitle"
}
const _hoisted_3 = ["onClick", "onKeyup"]
const _hoisted_4 = ["onClick", "onKeyup"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FacetListItem = _resolveComponent("FacetListItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _mergeProps({ class: "divFacetList" }, _ctx.$attrs), [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedValues, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `${item}-${index}`,
          class: _normalizeClass({ selected: _ctx.isSelected(item.name), 'border-top': index === 0 }),
          onClick: ($event: any) => (_ctx.handleDeselect(item.name)),
          onKeyup: _withKeys(($event: any) => (_ctx.handleDeselect(item.name)), ["enter"]),
          tabindex: "0"
        }, [
          _createVNode(_component_FacetListItem, {
            filterType: _ctx.filterType,
            item: item,
            isSelected: true,
            isInSelection: _ctx.isInSelection(item.name)
          }, null, 8, ["filterType", "item", "isInSelection"])
        ], 42, _hoisted_3))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notSelectedValues, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: `${item.name}${item.value}-${index}`,
          class: _normalizeClass({ selected: _ctx.isSelected(item.name), 'border-top': index === 0 }),
          onClick: ($event: any) => (_ctx.handleSelect(item.name)),
          onKeyup: _withKeys(($event: any) => (_ctx.handleSelect(item.name)), ["enter"]),
          tabindex: "0"
        }, [
          _createVNode(_component_FacetListItem, {
            filterType: _ctx.filterType,
            item: item,
            isSelected: false,
            isInSelection: _ctx.isInSelection(item.name)
          }, null, 8, ["filterType", "item", "isInSelection"])
        ], 42, _hoisted_4))
      }), 128))
    ], 16)
  ]))
}