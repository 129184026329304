<template>
  <div class="vehicle-grid-container">
    <AskUsModal :isOpen="isAskAboutCarOpen" :selectedVin="selectedVin" />

    <div v-if="showLoading" class="vehicleSearchResultsGrid">
      <SkeletonLoading v-for="index in 24" :key="index" />
    </div>
    <div v-else class="vehicleSearchResultsGrid">
      <template v-for="item in vehiclesWithPlaceholder" :key="item.key">
        <sc-placeholder
          v-if="item.isGridPlaceholder"
          :isGrid="true"
          name="ctaBanner"
          :rendering="rendering"
        />
        <div class="row-placeholder" v-if="item.isRowPlaceholder">
          <sc-placeholder name="ctaBanner" :rendering="rendering" />
        </div>
        <VehicleCard
          v-else-if="item.vehicle"
          :data-bodystyle="item.vehicle.bodystyle"
          :vehicle="item.vehicle"
          :isCarFavorite="getFavoriteCarStatus(item.vehicle.vin)"
          :showPaymentEstimates="showPaymentEstimates"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import VehicleCard from './VehicleCard.vue';
import SkeletonLoading from '@components-vue/Foundation/Common/Placeholders/SkeletonLoading.vue';
import favoriteService from '@services/favoriteService';
import Cookies from 'js-cookie';
import { mapState } from 'vuex';
import { Placeholder, ComponentRendering } from '@sitecore-jss/sitecore-jss-vue';
import AskUsModal from '@components/Feature/VehicleSearch/AskUsModal.vue';

export default defineComponent({
  name: 'VehicleSearchResultsGrid',
  components: {
    AskUsModal,
    ScPlaceholder: Placeholder,
    SkeletonLoading,
    VehicleCard,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object as PropType<ComponentRendering>,
      default: () => ({}),
    },
  },
  data() {
    return {
      vehicleListFavorites: [],
      isAskAboutCarOpen: false,
      selectedVin: '',
      hasMounted: false,
      itemsPerRow: 4,
    };
  },
  computed: {
    ...mapState('searchResults', ['queryParams', 'srpVehiclesData', 'loading']),
    ...mapState('common', ['zipCode']),
    gridPosition() {
      return this.params?.GridPosition;
    },
    rowPosition() {
      return this.params?.RowPosition;
    },
    vehicles() {
      return this.srpVehiclesData?.items ?? [];
    },
    showLoading() {
      return this.hasMounted && this.loading;
    },
    showPaymentEstimates() {
      return this.fields.showPriceEstimates?.value ?? false;
    },
    vehiclesWithPlaceholder() {
      const vehicles = this.vehicles.map((vehicle) => ({
        key: vehicle.vin,
        vehicle,
        isRowPlaceholder: false,
        isGridPlaceholder: false,
      }));

      const gridPosition = this.gridPosition ?? -1;
      const rowPosition = this.rowPosition ?? -1;

      if (gridPosition >= 0 && vehicles.length > 0) {
        vehicles.splice(gridPosition, 0, {
          key: `placeholder-grid-${gridPosition}`,
          isRowPlaceholder: false,
          isGridPlaceholder: true,
        });
      }

      const rowIndex = rowPosition >= 0 ? rowPosition * this.itemsPerRow : -1;
      if (rowIndex >= 0 && vehicles.length > 0) {
        vehicles.splice(rowIndex, 0, {
          key: `placeholder-row-${rowIndex}`,
          vehicle: vehicles[rowIndex]?.vehicle ?? null,
          isRowPlaceholder: true,
          isGridPlaceholder: false,
        });
      }

      return vehicles;
    },
  },
  methods: {
    getFavoriteCarStatus(vin) {
      return this.vehicleListFavorites.some((vehicle) => vehicle['ProductId'] === vin);
    },
    async loadVehicleListFavorites() {
      const response = await favoriteService.getFavoritesLines();
      this.vehicleListFavorites = response?.Lines ?? [];
    },
    updateItemsPerRow() {
      /* itemsPerRow is hardcoded to match hardcoded
        grid column widths defined in the @media for
        responsive sizing, np 6/21/2024*/
      if (typeof window !== 'undefined') {
        const width = window.innerWidth;
        if (width <= 328) {
          this.itemsPerRow = 1;
        } else if (width <= 625) {
          this.itemsPerRow = 1;
        } else if (width <= 980) {
          this.itemsPerRow = 2;
        } else if (width <= 1535) {
          this.itemsPerRow = 3;
        } else {
          this.itemsPerRow = 4;
        }
      }
    },
  },
  async mounted() {
    this.hasMounted = true;
    this.updateItemsPerRow();
    window.addEventListener('resize', this.updateItemsPerRow);
    await this.loadVehicleListFavorites();

    // ask us modal
    this.emitter.on('ask-about-us-modal:open', (payload) => {
      this.selectedVin = payload.vin;
      this.isAskAboutCarOpen = payload.isOpen;
      Cookies.set('ep_selectedZipcode', this.zipCode);
    });

    // analytics
    let pageType = '';
    let pageSubType = '';

    if (typeof window !== 'undefined') {
      const url = window.location.pathname.split('/').filter(Boolean);
      pageType = url.length > 0 ? `/${url[0]}` : '';
      pageSubType = url.length > 1 ? `/${url.slice(1).join('/')}` : '';
    }

    const obj = {
      event: 'page load',
      LoggedinID: (document.getElementById('hdIsLoggedInID') as HTMLInputElement)?.value || '',
      Pagetype: pageType,
      'Page subtype': pageSubType,
      Location:
        (document.getElementById('hdndefaultLocationName') as HTMLInputElement)?.value || '',
      zip: document.getElementById('ZipcodeInformationHeader')?.innerText || '',
    };
    this.emitter.emit('analytics', obj);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateItemsPerRow);
  },
});
</script>

<style lang="scss">
.vehicle-grid-container {
  order: 5;
  width: 100%;
  block-size: fit-content;
}

.vehicleSearchResultsGrid {
  display: grid;
  gap: 16px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;

  @media only screen and (max-width: 328px) {
    grid-template-columns: repeat(1, 1fr);
    padding-left: max(20px, calc(50% - 140px));
    padding-right: max(20px, calc(50% - 140px));
    justify-items: center;
  }
  @media only screen and (min-width: 328px) and (max-width: 625px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
  @media only screen and (min-width: 625px) and (max-width: 980px) {
    grid-template-columns: repeat(2, minmax(280px, 420px));
  }
  @media only screen and (min-width: 980px) and (max-width: 1535px) {
    grid-template-columns: repeat(3, minmax(280px, 420px));
  }
  @media only screen and (min-width: 1535px) {
    grid-template-columns: repeat(4, minmax(280px, 420px));
    justify-content: start;
  }

  .row-placeholder {
    display: block !important;
    grid-column: 1 / -1;
    width: 100%;
  }
}
</style>
