import { createApp as createVueApp, h, createSSRApp } from 'vue';
import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import AppRoot from './AppRoot.vue';
import { createRouter } from './router';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import config from '@/appConfig';
import componentFactory from './temp/componentFactory';
import createStore from '@/store/index';
import { createHead, VueHeadMixin } from '@unhead/vue';
import vuetify from '@/plugins/vuetify';
import { mergeDeep } from '@/util/commonUtils';
import emitter from '@/util/eventBus';

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState: any, i18n: any, vuexStoreState: any, isSSR: boolean) {
  const router = createRouter(isSSR);
  const graphQLProvider = createGraphQLProvider(initialState);
  const store = createStore();

  if (vuexStoreState) {
    store.replaceState(mergeDeep(store.state, vuexStoreState));
  }

  const vueOptions = {
    router,
    store,
    render: () => h(AppRoot),
    i18n,
  };

  const app = isSSR ? createSSRApp(vueOptions) : createVueApp(vueOptions);

  app.provide(DefaultApolloClient, graphQLProvider);
  app.use(router);
  app.use(SitecoreJssStorePlugin);
  app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
  app.use(i18n);
  app.use(store);
  app.use(vuetify);

  const head = createHead();
  app.use(head);
  app.mixin(VueHeadMixin);

  // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
  if (initialState) {
    app.config.globalProperties.$jss.store.setSitecoreData(initialState);
  }

  // Add emitter to the global instance, so it can be accessed with this.emitter in components
  // Note: the emitter can be imported in non-components directly using "import emitter from '@/util/eventBus"
  app.config.globalProperties.emitter = emitter;

  return { app, router, graphQLProvider, metaInfo: head };
}

export function createGraphQLProvider(initialState: any) {
  const client = initialState?.APOLLO_STATE
    ? GraphQLClientFactory(config.graphQLEndpoint, false, initialState.APOLLO_STATE)
    : GraphQLClientFactory(config.graphQLEndpoint, true);

  return client;
}
