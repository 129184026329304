import { vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "currency-wrapper" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMin)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          id: _ctx.id,
          type: "text",
          ref: "inputMinCurrency",
          class: "input-currency",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.displayValue) = $event)),
          onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
          onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args)), ["enter"])),
          onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
          "data-action": "set-minimum-value",
          "data-testid": "input-range-min"
        }, null, 40, _hoisted_2)), [
          [_vModelText, _ctx.displayValue]
        ])
      : _createCommentVNode("", true),
    (!_ctx.isMin)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          id: _ctx.id,
          type: "text",
          ref: "inputMaxCurrency",
          class: "input-currency",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displayValue) = $event)),
          onBlur: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
          onKeyup: _cache[6] || (_cache[6] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args)), ["enter"])),
          onFocus: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isInputActive = true)),
          "data-action": "set-maximum-value",
          "data-testid": "input-range-max"
        }, null, 40, _hoisted_3)), [
          [_vModelText, _ctx.displayValue]
        ])
      : _createCommentVNode("", true)
  ]))
}