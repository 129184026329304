import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  src: "https://cdn.lordicon.com/utwelszo.json",
  trigger: "loop",
  delay: "1000",
  colors: "primary:#3e8500,secondary:#f42434,tertiary:#666b70,quaternary:#f4f5f7",
  style: {"width":"144px","height":"144px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("lord-icon", _hoisted_1))
}