<template>
  <span>{{ prefix }}{{ min }}&ndash;{{ prefix }}{{ max }}{{ postfix }}</span>
</template>

<script lang="ts">
export default {
  name: 'RangeData',
  props: {
    min: {
      type: String,
      default: '0',
    },
    max: {
      type: String,
      default: '0',
    },
    prefix: {
      type: String,
      default: '',
    },
    postfix: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss"></style>
