import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "disclaimer-container",
  id: "disclaimer-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!

  return (_ctx.hasResults)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_sc_rich_text, {
          field: _ctx.fields.description
        }, null, 8, ["field"])
      ]))
    : _createCommentVNode("", true)
}