import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visitor_identification = _resolveComponent("visitor-identification")!
  const _component_OverlayHandler = _resolveComponent("OverlayHandler")!
  const _component_placeholder = _resolveComponent("placeholder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_visitor_identification),
    _createVNode(_component_OverlayHandler),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.scrollDirection)
    }, [
      (_ctx.route)
        ? (_openBlock(), _createBlock(_component_placeholder, {
            key: 0,
            name: "jss-header",
            rendering: _ctx.route
          }, null, 8, ["rendering"]))
        : _createCommentVNode("", true),
      (_ctx.route)
        ? (_openBlock(), _createBlock(_component_placeholder, {
            key: 1,
            name: "jss-main",
            rendering: _ctx.route,
            class: "jss-main"
          }, null, 8, ["rendering"]))
        : _createCommentVNode("", true),
      (_ctx.route)
        ? (_openBlock(), _createBlock(_component_placeholder, {
            key: 2,
            name: "jss-footer",
            rendering: _ctx.route,
            class: "jss-footer"
          }, null, 8, ["rendering"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}