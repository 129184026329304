<template>
  <select
    class="select-dropdown"
    @change="updateOptions"
    ref="selectItem"
    data-role="drop-down-list"
  >
    <!-- vue3 note: removed v-model here on the above select -->
    <option
      v-for="option in options"
      :selected="option.value === modelValue"
      :key="option.value"
      :value="option.value"
      :data-action-change="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Option } from '@/types/VehicleSearch/Dropdown';

export default defineComponent({
  name: 'Dropdown',
  props: {
    options: {
      type: Array<Option>, // Array of objects with value and label keys
      required: true,
    },
    modelValue: String,
  },
  data() {
    return {
      value: String,
    };
  },
  methods: {
    updateOptions(event) {
      const value = (event?.target as HTMLSelectElement)?.value;
      this.$emit('update:modelValue', value);
      this.$emit('optionSelected', value);
    },
  },
  emits: ['update:modelValue', 'optionSelected'],
});
</script>

<style lang="scss">
.select-dropdown {
  padding: 0 8px;
  height: 40px;
  border: 1px solid #d4d7d9;
  border-radius: 8px;
  appearance: auto;

  /* custom select from bootstrap */
  // do not delete -- components can pass this in as a class prop
  &.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
      url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border-color: #80bdff;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    &:focus::-ms-value {
      color: #495057;
      background-color: #fff;
    }
    &[multiple],
    &[size]:not([size='1']) {
      height: auto;
      padding-right: 0.75rem;
      background-image: none;
    }
    &:disabled {
      color: #6c757d;
      background-color: #e9ecef;
    }
    &::-ms-expand {
      display: none;
    }
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #495057;
    }
  }
  /* END: custom select from bootstrap */
}
</style>
