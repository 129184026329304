<template>
  <div
    v-show="showOverlay"
    class="overlay-container"
    @click="handleClick()"
    :style="{ 'z-index': zIndex }"
  ></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {};
  },
  name: 'Overlay',
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
    clickEvent: {
      type: String,
      default: '',
    },
    zIndex: {
      type: Number,
      default: 71,
    },
  },
  components: {},
  methods: {
    handleClick() {
      this.$emit('click');
      //   if (this.clickEvent) {
      //     this.emitter.emit(this.clickEvent);
      //   }
    },
  },
  emits: ['click'],
});
</script>

<style lang="scss">
.overlay-container {
  max-width: initial;
  height: 100%;
  width: 100%;
  transition: 0.2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #242c33;
  opacity: 0.33;
}
</style>
