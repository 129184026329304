import axios from 'axios';
import config from '../appConfig';

const LOCAL_HOST = config.sitecoreApiHost;

const api = axios.create({
  baseURL: LOCAL_HOST + 'api/sitecore',
});

export default {
  async toggleFavorite(vin: string) {
    return api.post('/Favorites/ToggleFavorite', { productId: vin }).then((response) => {
      return response.data;
    });
  },

  async getFavoritesLines() {
    return api.get('/Favorites/GetFavoriteLines').then((response) => {
      return response.data;
    });
  },
};
