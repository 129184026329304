import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "button-primary-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    role: "button",
    class: "button-primary",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    disabled: _ctx.disabled
  }, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ], 8, _hoisted_1))
}