<template>
  <div class="filter-and-sort-menu">
    <div class="sorting-menu-container">
      <sc-image :media="fields.SortIcon" class="sorting-icon"></sc-image>
      <div class="sort-by">
        <span class="sort-by-label"><sc-text :field="fields.SortLabel"></sc-text> </span>
        <select v-model="selectedSortTypeModel">
          <option
            v-for="(option, index) in mappedSortOptions"
            :key="index"
            class="sort-dropdown-item"
          >
            {{ option.sortName }}
          </option>
        </select>
      </div>
    </div>
    <div class="filter-menu-cta-container" @click="toggleFilterMenu()">
      <sc-image :media="fields.FilterIcon" />
      <sc-text :field="fields.FilterLabel" data-testid="filter-text-mobile" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import { sortMap } from '@util/facetHelper';
import { SortType } from '@/types/VehicleSearch/SearchResults';

export default defineComponent({
  data() {
    return {};
  },
  name: 'FilterAndSortMenu',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ScText: Text,
    ScImage: Image,
  },
  computed: {
    ...mapState('searchResults', ['sortType']),
    sortOptions() {
      return this.fields.SortOptions;
    },
    mappedSortOptions(): SortType[] {
      return this.sortOptions?.map((option, index) => {
        const phraseValue =
          this.sortOptions[index]?.fields?.Phrase?.value?.trim() || 'No Phrase Available';
        const sortBy =
          sortMap.find((map) => phraseValue.toLowerCase().includes(map.key))?.sortBy ||
          'No Sort By Available';
        const sortDirField =
          this.sortOptions[index]?.fields?.['Sort Direction']?.value?.trim() ||
          'No Sort Direction Available';
        const sortDir =
          sortDirField !== 'No Sort Direction Available'
            ? sortDirField.split(',')[0]
            : 'No Sort Direction Available';
        return {
          sortName: phraseValue,
          sortBy: sortBy,
          sortDirection: sortDir,
        };
      });
    },
    selectedSortTypeModel: {
      get() {
        return this.sortType?.sortName;
      },
      set(value) {
        let optionResults = this.mappedSortOptions.filter((x: SortType) => x.sortName === value);
        let option = optionResults.length > 0 ? optionResults[0] : null;

        if (option) {
          this.setSortType(option);

          this.emitter.emit('sort-updated-srp');

          let obj = {
            Selected: option.sortName,
            event: 'sort selected',
          };

          this.emitter.emit('analytics', obj);
        }
      },
    },
  },
  methods: {
    ...mapMutations('searchResults', ['setSortType']),
    toggleFilterMenu() {
      this.emitter.emit('toggle-filter-menu');
    },
  },
});
</script>

<style lang="scss">
.filter-and-sort-menu {
  select {
    appearance: auto;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--supplementary-blue-60);
    background: 0;
    border: 0;
    cursor: pointer;
    position: relative;
    margin-left: -4px;

    @media only screen and (min-width: 1240px) {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .filter-menu-cta-container {
    order: 1;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    position: sticky;
    border-bottom: 1px solid var(--park-gray-90);
    background: var(--base-white-100);
    min-height: 52px;
    border-right: 1px solid var(--park-gray-90);
    z-index: 2;

    @media only screen and (min-width: 1240px) {
      display: none;
    }

    span {
      color: var(--supplementary-blue-60);
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .sort-by {
    /* fix for spacing between sort label and select element */
    select:-moz-last-node {
      margin-left: unset;
    }

    @media only screen and (min-width: 1240px) {
      display: flex;
      flex-direction: row;
      gap: 8px;
      position: relative;
      align-items: center;
    }
  }
  .sorting-menu-container {
    float: right;
    order: 2;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    border-bottom: 1px solid var(--park-gray-90);
    background: transparent;
    gap: 8px;
    z-index: 3;
    position: sticky;
    height: 52px;
    background: var(--base-white-100);

    @media only screen and (max-width: 1024px) {
      display: flex;
      padding-bottom: 0;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
    }
    @media only screen and (min-width: 1240px) {
      order: 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-bottom: unset;
      gap: 8px;
      margin: 24px 24px 0;
      max-height: 40px;
      background-color: var(--whitesmoke);
      width: auto;
    }
  }
  .sort-by-label {
    color: var(--park-gray-0);
    display: block;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;

    @media only screen and (min-width: 1240px) {
      display: inline;
    }
  }
  .sort-dropdown {
    background: var(--base-white-100);
    position: absolute;
    border: 1px solid var(--park-gray-90);
    top: 42px;
    max-width: 144px;

    @media only screen and (min-width: 1240px) {
      top: 24px;
      right: 0;
    }
  }
  .sort-dropdown-item {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: var(--park-gray-0);
    cursor: pointer;
    width: 100%;
    display: inline-block;

    &:hover {
      background: var(--supplementary-blue-60);
      color: var(--base-white-100);
    }
  }
  .sorting-icon {
    @media only screen and (min-width: 1240px) {
      display: none;
    }
  }
}
</style>
