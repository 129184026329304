<template>
  <div class="vehicle-info">
    <img
      :src="vehicle?.images[0] || require('@/assets/images/car-placeholder.png')"
      :alt="vehicle?.name"
    />
    <div class="details">
      <p class="year-miles-price">
        <span>{{ vehicle?.year }}</span>
        <span>{{ formatVehicleMilage(vehicle?.miles) }}</span>
        <span>${{ numberWithCommas(vehicle?.sellingPrice) }}</span>
      </p>
      <p>{{ vehicle?.make }} {{ vehicle?.model }} {{ vehicle?.trim }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { formatVehicleMilage, numberWithCommas } from '@util/formatter';
import { mapState } from 'vuex';

export default defineComponent({
  computed: {
    ...mapState('scheduleTestDrive', ['vehicle']),
  },
  methods: {
    formatVehicleMilage,
    numberWithCommas,
  },
  name: 'ScheduleTestDrive--VehicleInfo',
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.vehicle-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: $spacing_inline-nano;

  img {
    width: 64px;
    height: 64px;
    border-radius: $border-radius-md;
    object-fit: cover;
  }
  .details p {
    width: 100%;
  }
  .year-miles-price {
    font-size: $font-size-xxxs;
    font-weight: $font-weight-light;

    span + span:before {
      display: inline-block;
      content: '|';
      color: $park-gray-70;
      margin: 0 $spacing_inline-nano;
    }
  }
}
</style>
