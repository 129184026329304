<template>
  <div v-if="overlayRoute?.placeholders">
    <v-dialog
      v-model="dialog"
      class="overlay-handler"
      :persistent="(overlayRoute.fields.IsPersistent.value as boolean) || false"
      :class="overlayRoute.fields.ClassName.value as string"
      :id="overlayRoute.fields.ClassName.value as string"
    >
      <v-card>
        <v-card-title v-if="overlayRoute.fields.Title.value as string">
          {{ overlayRoute.fields.Title.value }}
          <v-btn
            v-if="(overlayRoute?.fields?.CloseX?.value as boolean) || false"
            icon="ep-icon-close"
            variant="text"
            @click="dialog = false"
            class="modal-close"
          ></v-btn>
        </v-card-title>
        <v-card-subtitle v-if="overlayRoute.fields.SubTitle.value as string">
          {{ overlayRoute.fields.SubTitle.value }}
        </v-card-subtitle>
        <v-card-text>
          <placeholder name="overlays" :rendering="overlayRoute" />
        </v-card-text>
        <!-- TODO: if necessary, at some point we can add a sitecore option for footer/actions slot -->
        <!--
        <template v-slot:actions>
          <v-btn class="ms-auto" text="Ok" @click="dialog = false"></v-btn>
        </template>
        -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { layoutServiceFactory } from '@lib/layout-service-factory';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';
import { RouteData, Field } from '@sitecore-jss/sitecore-jss-vue';

interface OverlayRoute extends RouteData {
  fields: {
    ClassName: Field;
    CloseX: Field;
    IsPersistent: Field;
    ModalId: Field;
    SubTitle: Field;
    Title: Field;
  };
}

export default defineComponent({
  name: 'Overlay-Handler',
  computed: {},
  data() {
    return {
      overlayRoute: Object as unknown as OverlayRoute,
      dialog: false,
    };
  },
  mounted() {
    this.emitter.on('overlay-handler:open', (name: string) => {
      this.dialog = true;
      this.overlayRoute = {} as OverlayRoute;
      this.fetchOverlayRouteData(name);
    });
  },
  methods: {
    async fetchOverlayRouteData(overlayName: string) {
      const sitecoreOverlayPath = `/sitecore/content/Sonic/EchoPark/site-components/Overlays/${overlayName}`;
      const language = 'en';
      const layoutServiceInstance = layoutServiceFactory.create();

      // get the route data for the new route
      layoutServiceInstance.fetchLayoutData(sitecoreOverlayPath, language).then((x) => {
        if (!x?.sitecore?.route) {
          console.error(`The overlay named ${overlayName} was not found.`);
        }
        this.overlayRoute = (x?.sitecore?.route as OverlayRoute) || ({} as OverlayRoute);
      });
    },
  },
  components: {
    Placeholder,
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.overlay-handler {
  .v-card-title {
    position: relative;
    padding: $spacing_inset-sm $spacing_inset-sm 0;
    margin-bottom: $spacing_stack-xxs;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    line-height: 26px;
    overflow: visible;
  }
  .v-card-subtitle {
    margin-top: -$spacing_stack-xxxs;
    margin-bottom: $spacing_stack-xxs;
    padding: 0 $spacing_inline-xxs;
    white-space: unset;
  }
  .v-card-text {
    padding: 0 !important;
    letter-spacing: 0 !important;
    line-height: 24px;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
