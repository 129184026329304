import Cookies from 'js-cookie';
import { Module } from 'vuex';
import { RootState } from '../State';
import { Dealership } from '@/types/ServiceModels';
import emitter from '@util/eventBus';

type AccountView = 'login' | 'registration' | 'forgotPassword';

export interface CommonState {
  zipCode: string;
  accountFlyout: boolean;
  accountView: AccountView;
  zipFlyout: boolean;
  dealerships: Dealership[];
  urlCount: number;
  headerData: any; // TODO-vue3 type here
}

/* Default State */
const state = (): CommonState => ({
  zipCode: '28205' /* TODO: grab default zip */,
  accountFlyout: false,
  accountView: 'login',
  zipFlyout: false,
  dealerships: [],
  urlCount: 0,
  headerData: {},
});

export const CommonModule = (): Module<CommonState, RootState> => {
  return {
    namespaced: true,
    state,
    mutations: {
      setHeaderData(state, value: any) {
        state.headerData = value;
      },
      decrementUrlCount(state) {
        state.urlCount--;
      },
      incrementUrlCount(state) {
        state.urlCount++;
      },
      setZipCode(state, value: string) {
        if (!value || state.zipCode === value) return;
        if (Cookies.set) {
          Cookies.set('ep_selectedZipcode', value, { expires: 3650 });
          Cookies.set('ep_zipstatus', 'ManualEntry', { expires: 3650 });
        }
        state.zipCode = value;
        const obj = {
          event: 'zip code selected',
          entered: value,
        };

        emitter.emit('analytics', obj);
      },
      setAccountFlyout(state, value: boolean) {
        state.accountFlyout = value;
      },
      setAccountView(state, value: AccountView) {
        state.accountView = value;
      },
      setZipFlyout(state, value: boolean) {
        state.zipFlyout = value;
      },
      setDealerships(state, value: Dealership[]) {
        if (!state.dealerships) state.dealerships = [];
        state.dealerships.length = 0;
        value.forEach((dealer) => {
          state.dealerships.push(dealer);
        });
      },
      initializeZip(state, value: string) {
        if (!value || state.zipCode === value) return;
        state.zipCode = value;
      },
    },
    actions: {},
    getters: {
      nearestDealership(state): Dealership {
        return state.dealerships[0];
      },
      zipCode(state): string {
        return state.zipCode;
      },
    },
  };
};
