<template>
  <v-lazy class="vehicleImageCarousel">
    <v-carousel
      ref="vehicleImageCarousel"
      height="auto"
      show-arrows="hover"
      :hide-delimiter-background="true"
    >
      <v-carousel-item
        v-for="(image, index) in imageList"
        :aspect-ratio="4 / 3"
        :key="index"
        :src="image as string"
        :alt="imageAltText"
        width="100%"
        height="auto"
        @click.prevent.stop="imageClick"
      ></v-carousel-item>
      <v-carousel-item
        class="black-blur-image"
        :src="(lowRezImage as string) || (imageList?.[0] as string)"
        :alt="imageAltText"
        width="400"
        height="300"
      >
        <v-btn class="secondary-light">{{ $t('See car details') }}</v-btn>
      </v-carousel-item>
      <template v-slot:prev="{ props }">
        <span class="carousel-control-prev">
          <span
            class="carousel-control-prev-icon"
            @click.prevent.stop="props.onClick"
            @keydown.prevent.stop.enter="props.onClick"
            role="button"
            tabindex="0"
          ></span>
          <span class="sr-only">Previous slide</span>
        </span>
      </template>
      <template v-slot:next="{ props }">
        <span class="carousel-control-next">
          <span
            class="carousel-control-next-icon"
            @click.prevent.stop="props.onClick"
            @keydown.prevent.stop.enter="props.onClick"
            role="button"
            tabindex="0"
          ></span>
          <span class="sr-only">Next slide</span>
        </span>
      </template>
    </v-carousel>
  </v-lazy>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    imageList: Array<String>,
    lowRezImage: String,
    imageAltText: String,
    seeMoreOnClick: Function,
  },
  name: 'VehicleCardCarousel',
  components: {},
  methods: {
    imageClick() {
      if (this.seeMoreOnClick) {
        this.seeMoreOnClick();
      }
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.vehicleImageCarousel {
  min-height: 200px;
  aspect-ratio: 4/3;

  .v-img {
    cursor: pointer;
  }

  /* INDICATORS STYLES */
  .v-carousel {
    position: relative;
    overflow: visible;

    .v-carousel__controls {
      position: absolute;
      bottom: -14px;
      width: 100%;
      height: 28px;

      .v-ripple__container {
        display: none;
      }
      .v-carousel__controls__item {
        width: 8px;
        height: 24px !important;
        margin: 0;
        padding: 0 10px;

        .v-icon {
          opacity: 1;
          font-size: 8px;
          color: $park-gray-10;

          &:hover {
            opacity: 0.5;
          }
        }
        &.v-btn--active {
          width: 12px;

          .v-icon {
            font-size: 12px;
          }
        }
      }
    }
  }

  /* ARROW CONTROLS */
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 1;
    display: none;
    opacity: 1;
  }
  .vehicle-card-top:focus-within &,
  &:hover {
    .carousel-control-prev,
    .carousel-control-next {
      display: flex;

      @media only screen and (max-width: 480px) {
        display: none;
      }
    }
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: block;
    width: 32px;
    height: 32px;
    background-size: 24px;
    background-color: rgba($park-gray-0, 0.3);
    background-position: center;
    border-radius: 100%;

    &:hover {
      background-color: rgba(36, 44, 51, 0.5);
    }
  }
  .carousel-control-prev-icon {
    background-image: url('~@icons/ArrowIcons/ArrowIconCarousel/ArrowIconCarouselLeft.svg');
  }
  .carousel-control-next-icon {
    background-image: url('~@icons/ArrowIcons/ArrowIconCarousel/ArrowIconCarouselRight.svg');
  }

  /* SEE CAR DETAILS CONTROLS */
  .black-blur-image {
    overflow: hidden;

    .v-responsive.v-img {
      width: 100% !important;
      height: auto !important;
      aspect-ratio: 4/3;
    }
    .v-img__img {
      filter: brightness(45%) blur(7px);
      transform: scale(1.1);
    }
    .v-responsive__content {
      position: relative;

      .v-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: $font-size-xxxs;
      }
    }
  }
}
</style>
