import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "zip-location-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZipLocationEdit = _resolveComponent("ZipLocationEdit")!
  const _component_ZipNearestStoresDisplay = _resolveComponent("ZipNearestStoresDisplay")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ZipLocationEdit, {
            fields: _ctx.fields,
            modelValue: _ctx.zipCode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.zipCode) = $event))
          }, null, 8, ["fields", "modelValue"])
        ]),
        _createVNode(_component_ZipNearestStoresDisplay, {
          fields: _ctx.fields,
          dealerships: _ctx.dealerships,
          closestDealership: _ctx.nearestDealership,
          "data-action": "change-zip-code"
        }, null, 8, ["fields", "dealerships", "closestDealership"])
      ])
    ]),
    _: 1
  }))
}