import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "divRangeInput" }
const _hoisted_2 = {
  key: 0,
  class: "lblTitle"
}
const _hoisted_3 = { class: "divLblMinMaxOuter" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "divMinMaxInputContainer" }
const _hoisted_7 = { class: "divMinInputContainer" }
const _hoisted_8 = ["id", "step"]
const _hoisted_9 = { class: "divMaxInputContainer" }
const _hoisted_10 = ["id", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurrencyInput = _resolveComponent("CurrencyInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", {
        class: "inputMinLbl",
        for: `inputMin-${_ctx.componentName}`
      }, _toDisplayString(_ctx.minLabel), 9, _hoisted_4),
      _createElementVNode("label", {
        class: "inputMaxLbl",
        for: `inputMax-${_ctx.componentName}`
      }, _toDisplayString(_ctx.maxLabel), 9, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.currency)
          ? (_openBlock(), _createBlock(_component_CurrencyInput, {
              key: 0,
              id: `inputMin-${_ctx.componentName}`,
              onEmitUpdate: _ctx.emitUpdate,
              modelValue: _ctx.rangeValue[0],
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rangeValue[0]) = $event)),
              onFocus: _ctx.handleFocus,
              isMin: true
            }, null, 8, ["id", "onEmitUpdate", "modelValue", "onFocus"]))
          : _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 1,
              id: `inputMin-${_ctx.componentName}`,
              class: "inputMin",
              onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
              onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args)), ["enter"])),
              onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
              type: "number",
              step: _ctx.step,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.rangeValue[0]) = $event)),
              "data-testid": "input-range-min"
            }, null, 40, _hoisted_8)), [
              [_vModelText, _ctx.rangeValue[0]]
            ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.currency)
          ? (_openBlock(), _createBlock(_component_CurrencyInput, {
              key: 0,
              id: `inputMax-${_ctx.componentName}`,
              onEmitUpdate: _ctx.emitUpdate,
              onFocus: _ctx.handleFocus,
              modelValue: _ctx.rangeValue[1],
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.rangeValue[1]) = $event)),
              isMin: false
            }, null, 8, ["id", "onEmitUpdate", "onFocus", "modelValue"]))
          : _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 1,
              id: `inputMax-${_ctx.componentName}`,
              class: "inputMax",
              onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args))),
              onKeyup: _cache[7] || (_cache[7] = _withKeys(
//@ts-ignore
(...args) => (_ctx.emitUpdate && _ctx.emitUpdate(...args)), ["enter"])),
              onFocus: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
              type: "number",
              step: _ctx.step,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.rangeValue[1]) = $event)),
              "data-testid": "input-range-max"
            }, null, 40, _hoisted_10)), [
              [_vModelText, _ctx.rangeValue[1]]
            ])
      ])
    ])
  ]))
}