<template>
  <div class="divRangeSlider" data-role="range-slider">
    <div ref="slider"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import noUiSlider, { target } from 'nouislider';
import 'nouislider/dist/nouislider.css';

export default defineComponent({
  name: 'RangeSlider',
  props: {
    setRange: {
      type: Array<number>,
      default: function () {
        return [0, 100];
      },
    },
    range: {
      type: Array<number>,
      default: function () {
        return [0, 100];
      },
    },
    step: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    rangeValue() {
      return this.range;
    },
  },
  data() {
    return {};
  },
  mounted() {
    const slider = this.$refs.slider as target;

    noUiSlider.create(slider, {
      start: [this.rangeValue[0], this.rangeValue[1]],
      connect: true,
      step: this.step,
      range: {
        min: this.setRange[0],
        max: this.setRange[1],
      },
      format: {
        from: (formattedValue) => {
          return Number(formattedValue);
        },
        to: (numericValue) => {
          return Math.round(numericValue);
        },
      },
    });

    // as user drags range slider, send update to parent so it can update the dropdown values
    slider.noUiSlider?.on('slide', (values) => {
      let newMinPosition = values[0];
      let newMaxPosition = values[1];
      if (newMinPosition !== this.rangeValue[0] || newMaxPosition !== this.rangeValue[1]) {
        this.$emit('sliderValueUpdated', [String(values[0]), String(values[1])]);
      }
    });

    // when user drops range slider handle or clicks on the slider bar, send update to parent to apply value
    slider.noUiSlider?.on('change', (values) => {
      this.$emit('sliderValueSet', [String(values[0]), String(values[1])]);
    });
  },
  watch: {
    range(newValue) {
      let slider = this.$refs.slider as target;
      if (!slider.noUiSlider) return;
      let minInt = newValue[0];
      let maxInt = newValue[1];
      let currentPosition = slider.noUiSlider.get();
      let currentMinInt = currentPosition[0];
      let currentMaxInt = currentPosition[1];
      if (minInt !== currentMinInt || maxInt !== currentMaxInt) {
        let slider = this.$refs.slider as target;
        slider.noUiSlider?.set(newValue);
      }
    },
    setRange(newValue) {
      let slider = this.$refs.slider as target;
      if (!slider.noUiSlider) return;
      let minInt = newValue[0];
      let maxInt = newValue[1];
      slider.noUiSlider.updateOptions(
        {
          range: {
            min: Number(minInt),
            max: Number(maxInt),
          },
        },
        true
      );
    },
  },
});
</script>

<style lang="scss">
.divRangeSlider {
  padding: 0 24px 0 28px;
  width: 100% !important;

  .noUi-background {
    background-color: #666b70;
  }
  .noUi-horizontal {
    height: 5px;
    width: calc(100% - 16px);
    background: #e8e9eb;
    border-radius: 11px;
    border: 0;
    margin-bottom: 8px;
    margin-left: 8px;

    .noUi-handle {
      width: 24px !important;
      height: 24px !important;
      right: -10px !important;
      border-radius: 25px !important;
      background-color: #242c33;
      box-shadow: 0 2px 5px 0 #242c33 !important;
      border: 0;
      cursor: url('@assets/icons/Drag.svg'), pointer;
      top: -10px !important;
    }
  }
  .noUi-handle {
    &:after,
    &:before {
      display: none;
    }
  }
  .noUi-connect {
    background: #242c33;
  }
  .noUi-base {
    &:active {
      .noUi-connect,
      .noUi-handle {
        background-color: #3e8500 !important;
      }
    }
    &:hover {
      .noUi-connect,
      .noUi-handle {
        background-color: #326b00;
      }
    }
  }
}
</style>
