<template>
  <div
    ref="root"
    tabindex="0"
    :class="{ 'favorite-icon': true, active: favorite }"
    v-on:click.stop="toogleFavorite"
    v-on:keyup.space="toogleFavorite"
    v-on:keyup.enter="toogleFavorite"
    data-role="favorite"
    :data-value="favorite ? 'true' : 'false'"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24">
      <path
        d="M17.356 3C20.394 3 22.856 5.5 22.856 9C22.856 15.6009 16.1868 19.5341 13.33 21.219C13.1573 21.3208 12.9985 21.4145 12.856 21.5C12.7134 21.4145 12.5546 21.3208 12.3819 21.219C9.52512 19.5341 2.85596 15.6009 2.85596 9C2.85596 5.5 5.35596 3 8.35596 3C10.216 3 11.856 4 12.856 5C13.856 4 15.496 3 17.356 3Z"
      />
    </svg>
    <span class="sr-only">{{ $t('srp2.vehicleTile.favoriteIconLabel') }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'FavoriteButton',
  props: {
    favorite: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toogleFavorite(event: Event) {
      event.preventDefault();
      const rootEl = this.$refs['root'] as HTMLElement;
      rootEl.blur();
      this.$emit('toggle', !this.favorite);
    },
  },
});
</script>

<style lang="scss">
.favorite-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
  border: 1px solid var(--park-gray-80);
  color: var(--park-gray-80);
  cursor: pointer;
  background-color: var(--base-white-100);
  width: 32px;
  height: 32px;
  padding: 16px 17px 16px 16px;
  border-radius: 50%;

  &.active svg,
  &.active:hover svg {
    fill: #62a60a;
  }
  &:hover svg {
    fill: #666b70;
  }
  svg {
    fill: #a2a6ab;
    height: 24px;
    width: 25px;
    position: absolute;
    top: 5px;
    left: 4px;
    z-index: 200;
  }
}
</style>
