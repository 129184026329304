<template>
  <v-row class="style-guide--typography">
    <v-col>
      <h3>Light Background</h3>
      <p class="h1">Heading 1</p>
      <p class="h2">Heading 2</p>
      <p class="h3">Heading 3</p>
      <p class="h4">Heading 4</p>
      <p class="h5">Heading 5</p>
      <p class="h6">Heading 6</p>
      <p class="sub-head">Sub-head</p>
      <p class="large">Paragraph Large</p>
      <p class="small">Paragraph Small</p>
      <a href="#">Link</a>
      <p class="warning">Warning</p>
    </v-col>
    <v-col>
      <h3>Dark Background</h3>
      <div class="dark-background">
        <p class="on-dark">Paragraph on Dark Background</p>
        <a href="#" class="on-dark">Link on Dark Background</a>
        <p class="warning on-dark">Warning on Dark Background</p>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Typography',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.style-guide--typography {
  h3 {
    margin-bottom: 30px;
  }
  a,
  p {
    display: block;
    margin-bottom: 20px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  .dark-background {
    background: $park-gray-10;
    border-radius: $border-radius-md;
    box-shadow: $shadow-level-4;
    padding: $spacing_inset-lg;
  }
  .warning.on-dark {
    border-radius: $border-radius-md;
    box-shadow: $shadow-level-4;
    padding: $spacing_inset-sm;
  }
}
</style>
