import appConfig from '@/appConfig';
import { isNullOrEmpty } from '@/util/commonUtils';
import axios from 'axios';

const API_URL = appConfig.storesApiEndpoint;
const ZIP_REGEX = /^\d+$/;

export default {
  async isValidZip(zipcode: string): Promise<boolean> {
    try {
      if (zipcode == '') {
        return false;
      } else if (zipcode.length > 0) {
        if (!ZIP_REGEX.test(zipcode)) {
          return false;
        } else {
          return await this.isUSAZipCode(zipcode);
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async isUSAZipCode(zipcode: string): Promise<boolean> {
    try {
      const response = await axios.post(API_URL, { zip: zipcode });
      if (response.data) {
        if (!isNullOrEmpty(response.data.Error)) {
          return false;
        } else if (response.data.IsUSAZipcode == 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  },
};
