<template>
  <div class="vehicleInfoButtons">
    <span class="vehicleInfoButtonsCalculator">
      <SrpButton
        class="ignoreFullTileClick"
        onclick="document.activeElement.blur()"
        calculator
        onlyIcon
      />
    </span>
    <span class="vehicleInfoButtonsTestDrive">
      <v-btn
        class="test-drive-button"
        @click.prevent.stop="triggerOverlayHandler('ScheduleTestDrive')"
        v-if="showTestDriveLink"
        data-testid="test-drive-openTestDriveButton"
      >
        <v-icon icon="ep-icon-test-drive" />
        {{ $t('srp2.scheduleTestDrive.buttonText') }}
      </v-btn>
      <span
        @click.prevent.stop="openAskAboutUsModal()"
        v-on:keyup.space="openAskAboutUsModal()"
        v-on:keyup.enter="openAskAboutUsModal()"
        v-if="!showTestDriveLink"
      >
        <SrpButton
          class="ignoreFullTileClick"
          onclick="document.activeElement.blur()"
          :buttonText="$t('askUs')"
          subtract
        />
      </span>
    </span>
  </div>
</template>

<script lang="ts">
import { mapMutations, mapGetters } from 'vuex';
import SrpButton from './SrpButton.vue';
import { Vehicle, Dealership } from '@/types/ServiceModels';
import dealershipSearchService from '@services/dealershipSearchService';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'VehicleCardButtons',
  props: {
    vehicle: Object as PropType<Vehicle>,
  },
  components: {
    SrpButton,
  },
  computed: {
    ...mapGetters('common', ['nearestDealership', 'zipCode']),
    showTestDriveLink() {
      if (this?.nearestDealership?.dealerId === this.vehicle?.dealership) {
        if (this?.nearestDealership?.enableTestDrive) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    ...mapMutations('searchResults', ['setAskUsModalTitle']),
    ...mapMutations('scheduleTestDrive', ['setTestDriveVehicle', 'setTestDriveDealership']),
    openAskAboutUsModal() {
      this.emitter.emit('ask-about-us-modal:open', {
        vin: this.vehicle?.vin,
        isOpen: true,
      });
      this.setAskUsModalTitle(this.vehicle?.make + ' ' + this.vehicle?.model);
    },
    async triggerOverlayHandler(name) {
      const dealership = this.vehicle?.dealership;
      const location = await dealershipSearchService
        .getDealerships(this.zipCode)
        .then((dealershipResults: Dealership[] | null) => {
          return dealershipResults
            ? dealershipResults.find((item) => item.dealerId === dealership)
            : null;
        });

      let obj = {};
      if (location) {
        this.setTestDriveDealership({
          address: location.address,
          city: location.city,
          dealerId: location.dealerId,
          distance: location.distance,
          phone: location.phone,
          state: location.state,
          storeHours: JSON.parse(location.storeHours || '{}'),
          storeId: location.storeId,
          storeName: location.storeName,
          zip: location.zip,
          timeZone: location.timeZone,
        });

        obj = {
          Entered: location.zip,
        };

        this.emitter.emit('analytics', obj);
      }

      obj = {
        link: window.location.href,
      };

      this.emitter.emit('analytics', obj);
      this.setTestDriveVehicle(this.vehicle);
      this.emitter.emit('overlay-handler:open', name);
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.vehicleInfoButtons {
  display: flex;
  gap: 27px;
}
.vehicleInfoButtonsCalculator {
  flex: 1;
}
.vehicleInfoButtonsTestDrive {
  flex: 1;
  text-align: right;
}
.v-btn.test-drive-button {
  max-height: 40px;
  background: none;
  border-color: $system-alert-blue-60;
  color: $system-alert-blue-60;
  font-size: $font-size-xxxs;
  font-weight: $font-weight-bold;
  line-height: 1;
  letter-spacing: 0;
  padding: 0 14px 0 12px;

  &:active,
  &:focus,
  &:focus-visible,
  &:hover {
    background: none;
    border-color: $supplementary-blue-50;
    color: $supplementary-blue-50;
  }
  .v-icon {
    font-size: 22px;
  }
  &:not(.v-btn--icon) .v-btn__content {
    display: flex;
    gap: 4px;
    overflow: visible;
  }
}
</style>
