import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "drive-type-transmission-filter",
  "data-component-name": "drive-type-transmission-filter",
  "data-testid": "drive-type-transmission-filter"
}
const _hoisted_2 = { "data-role": "facet-filter" }
const _hoisted_3 = { class: "driveTypeFilter" }
const _hoisted_4 = { class: "transmissionTypeFilter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMenuLineItem = _resolveComponent("FilterMenuLineItem")!
  const _component_FacetList = _resolveComponent("FacetList")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterMenuLineItem, {
            onClick: _ctx.expandFilter,
            isSelected: _ctx.isFilterSelected,
            title: _ctx.$t('Drive Type Transmission Filter Title')
          }, null, 8, ["onClick", "isSelected", "title"])
        ], 512), [
          [_vShow, !_ctx.driveTypeFilterSelected && !_ctx.filterSelected]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FacetList, {
              modelValue: _ctx.driveTypeModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.driveTypeModel) = $event)),
              title: _ctx.titleDriveType,
              data: _ctx.facetListDriveType,
              filterType: _ctx.driveFilterType,
              "data-action-select": _ctx.titleDriveType,
              "data-testid": "drive-type-list"
            }, null, 8, ["modelValue", "title", "data", "filterType", "data-action-select"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_FacetList, {
              modelValue: _ctx.transmissionTypeModel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.transmissionTypeModel) = $event)),
              title: _ctx.titleTransmission,
              data: _ctx.facetListTransmission,
              filterType: _ctx.transmissionFilterType,
              "data-action-select": _ctx.titleTransmission,
              "data-testid": "transmission-list"
            }, null, 8, ["modelValue", "title", "data", "filterType", "data-action-select"])
          ])
        ], 512), [
          [_vShow, _ctx.driveTypeFilterSelected]
        ])
      ])
    ]),
    _: 1
  }))
}