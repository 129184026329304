import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowIconPagination = _resolveComponent("ArrowIconPagination")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    id: _ctx.id,
    class: _normalizeClass(['navigationButton', { disabled: _ctx.disabled }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleButtonClick()))
  }, [
    _createVNode(_component_ArrowIconPagination, {
      direction: _ctx.direction,
      disabled: _ctx.disabled
    }, null, 8, ["direction", "disabled"])
  ], 10, _hoisted_1))
}