import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "vehicle-grid-container" }
const _hoisted_2 = {
  key: 0,
  class: "vehicleSearchResultsGrid"
}
const _hoisted_3 = {
  key: 1,
  class: "vehicleSearchResultsGrid"
}
const _hoisted_4 = {
  key: 1,
  class: "row-placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AskUsModal = _resolveComponent("AskUsModal")!
  const _component_SkeletonLoading = _resolveComponent("SkeletonLoading")!
  const _component_sc_placeholder = _resolveComponent("sc-placeholder")!
  const _component_VehicleCard = _resolveComponent("VehicleCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AskUsModal, {
      isOpen: _ctx.isAskAboutCarOpen,
      selectedVin: _ctx.selectedVin
    }, null, 8, ["isOpen", "selectedVin"]),
    (_ctx.showLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(24, (index) => {
            return _createVNode(_component_SkeletonLoading, { key: index })
          }), 64))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vehiclesWithPlaceholder, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.key
            }, [
              (item.isGridPlaceholder)
                ? (_openBlock(), _createBlock(_component_sc_placeholder, {
                    key: 0,
                    isGrid: true,
                    name: "ctaBanner",
                    rendering: _ctx.rendering
                  }, null, 8, ["rendering"]))
                : _createCommentVNode("", true),
              (item.isRowPlaceholder)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_sc_placeholder, {
                      name: "ctaBanner",
                      rendering: _ctx.rendering
                    }, null, 8, ["rendering"])
                  ]))
                : (item.vehicle)
                  ? (_openBlock(), _createBlock(_component_VehicleCard, {
                      key: 2,
                      "data-bodystyle": item.vehicle.bodystyle,
                      vehicle: item.vehicle,
                      isCarFavorite: _ctx.getFavoriteCarStatus(item.vehicle.vin),
                      showPaymentEstimates: _ctx.showPaymentEstimates
                    }, null, 8, ["data-bodystyle", "vehicle", "isCarFavorite", "showPaymentEstimates"]))
                  : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
  ]))
}