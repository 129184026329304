<template>
  <v-row class="style-guide--border-width">
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box box-border border-width-none"></div>
      <code>.border-width-none</code>
      <code>$border-width-none</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box box-border border-width-hairline"></div>
      <code>.border-width-hairline</code>
      <code>$border-width-hairline</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box box-border border-width-thin"></div>
      <code>.border-width-thin</code>
      <code>$border-width-thin</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box box-border border-width-thick"></div>
      <code>.border-width-thick</code>
      <code>$border-width-thick</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box box-border border-width-heavy"></div>
      <code>.border-width-heavy</code>
      <code>$border-width-heavy</code>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--BorderWidth',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.style-guide--border-width {
  .box.box-border {
    border: 0 solid $base-black-100;
  }
}
</style>
