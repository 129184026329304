import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "divRangeDropdown",
  "data-testid": "div-range-dropdown"
}
const _hoisted_2 = {
  class: "lblTitle",
  "data-testid": "label-title"
}
const _hoisted_3 = {
  class: "divLblMinMaxOuter",
  "data-testid": "label-min-max-outer"
}
const _hoisted_4 = {
  class: "selectMinLbl",
  for: "textbox",
  "data-testid": "range-label-min"
}
const _hoisted_5 = {
  class: "selectMaxLbl",
  for: "textbox",
  "data-testid": "range-label-max"
}
const _hoisted_6 = {
  class: "divMinMaxSelectContainer",
  "data-testid": "min-max-select-container"
}
const _hoisted_7 = {
  class: "divMinSelectContainer",
  "data-testid": "min-select-container"
}
const _hoisted_8 = {
  class: "divMaxSelectContainer",
  "data-testid": "max-select-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.minLabel), 1),
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.maxLabel), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Dropdown, {
          options: _ctx.options.map((option) => ({ value: option, label: option })),
          onOptionSelected: _ctx.emitUpdate,
          modelValue: _ctx.selectedValue[0],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue[0]) = $event))
        }, null, 8, ["options", "onOptionSelected", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Dropdown, {
          options: _ctx.options.map((option) => ({ value: option, label: option })),
          onOptionSelected: _ctx.emitUpdate,
          modelValue: _ctx.selectedValue[1],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedValue[1]) = $event))
        }, null, 8, ["options", "onOptionSelected", "modelValue"])
      ])
    ])
  ]))
}