<template>
  <span>{{ prefix }}{{ item }}{{ postfix }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StringData',
  props: {
    item: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    postfix: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss"></style>
