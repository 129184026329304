<template>
  <div class="day-and-time" :class="selectedDayAndTime ? '' : 'mb-0'" v-if="view === 'dayAndTime'">
    <div id="day-picker" class="day-picker">
      <p class="h6" data-testid="test-drive-dayLabel">{{ fields?.dayLabel?.value }}</p>
      <div class="day-toggle-group" data-testid="test-drive-dayGroup">
        <v-btn-toggle v-model="selectedDay" v-for="date in appointments" :key="date">
          <v-btn
            @click="setDay(date)"
            @keyup.enter="setDay(date)"
            :class="selectedDay === date ? 'active' : ''"
          >
            <span v-html="getDayString(date.day)"></span>
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div id="time-picker" class="time-picker" v-if="selectedDay.day">
      <div class="time-picker-heading">
        <p class="h6" data-testid="test-drive-timeLabel">{{ fields.timeLabel.value }}</p>
        <p class="busy-label" v-if="fields.showUsuallyBusy.value">
          <v-icon icon="ep-icon-time"></v-icon>{{ fields.usuallyBusyLabel.value }}
        </p>
      </div>
      <div class="time-toggle-group" data-testid="test-drive-timeGroup">
        <v-btn-toggle v-model="selectedTime" v-for="timeSlot in selectedDay.times" :key="timeSlot">
          <v-btn
            @click="setTime(timeSlot.time)"
            @keyup.enter="setTime(timeSlot.time)"
            :class="selectedTime === timeSlot.time ? 'active' : ''"
          >
            <v-icon
              icon="ep-icon-time"
              v-if="timeSlot.usuallyBusy && fields.showUsuallyBusy.value"
            ></v-icon>
            {{ timeSlot.time }}
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div
      id="continue-button"
      class="continue-button"
      v-if="selectedDayAndTime"
      @click="setDayAndTime"
      @keyup.enter="setDayAndTime"
    >
      <div class="button-and-error">
        <v-btn :loading="loading" data-testid="test-drive-dayAndTimeContinueButton">{{
          fields.dayTimePageContinueButtonText.value
        }}</v-btn>
        <p v-if="showErrorMessage" class="v-messages__message">
          Please select a time before proceeding.
        </p>
      </div>
      <p v-if="fields.showUsuallyBusy.value">{{ fields.usuallyBusyNote.value }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters, mapMutations } from 'vuex';
import { defineComponent } from 'vue';

interface TimeSlot {
  time: string;
  usuallyBusy: boolean;
}

interface Appointment {
  day: string;
  times: TimeSlot[];
}

const emptySelectedDay = { day: '', times: [] };

export default defineComponent({
  name: 'ScheduleTestDrive--DayAndTime',
  components: {},
  computed: {
    ...mapGetters('scheduleTestDrive', ['day', 'time', 'view', 'dealership']),
  },
  data() {
    return {
      loading: false,
      appointments: [] as Appointment[],
      showUsuallyBusy: false,
      selectedDay: emptySelectedDay as Appointment,
      selectedTime: '',
      selectedDayAndTime: false,
      showErrorMessage: false,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    errorComponent: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    if (this.fields?.amountOfDays?.value) {
      this.generateAppointments(this.fields?.amountOfDays?.value);
    } else {
      this.generateAppointments(6);
    }
  },
  methods: {
    ...mapMutations('scheduleTestDrive', [
      'setTestDriveDay',
      'setTestDriveTime',
      'setTestDriveView',
    ]),
    generateAppointments(days) {
      const storeHours = this.dealership.storeHours;
      const now = new Date();
      const appointments: Appointment[] = [];
      let validDaysCount = 0;
      let dayIndex = 0;

      while (validDaysCount < days) {
        const currentDay = new Date();
        currentDay.setDate(now.getDate() + dayIndex);
        currentDay.setHours(0, 0, 0, 0);

        const dayName = currentDay.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
        const dayHours = storeHours[dayName];

        if (dayHours) {
          const [openTime, closeTime] = dayHours.split('-');
          let openHour = parseInt(openTime.slice(0, 2), 10);
          const closeHour = parseInt(closeTime.slice(0, 2), 10);

          const times: TimeSlot[] = [];

          if (dayIndex === 0) {
            const currentHour = now.getHours();
            if (currentHour >= openHour) {
              openHour = currentHour + 1;
            }
          }

          for (let hour = openHour; hour < closeHour; hour++) {
            times.push({
              time: `${this.formatTime(hour)}–${this.formatTime(hour + 1)}`,
              usuallyBusy: false,
            });
          }

          if (times.length > 0) {
            appointments.push({
              day: currentDay.toISOString().split('T')[0],
              times,
            });

            validDaysCount++;
          }
        }

        dayIndex++;
      }

      this.appointments = appointments;
    },
    getWeekDates() {
      const currentDate = new Date();
      const weekDates: { dayName: string; date: Date }[] = [];
      const currentDay = currentDate.getDay();
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - (currentDay === 0 ? 6 : currentDay - 1));

      for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        const dayName = date.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
        weekDates.push({ dayName, date });
      }

      return weekDates;
    },
    formatTime(hour: number): string {
      const period = hour >= 12 ? 'PM' : 'AM';
      const formattedHour = hour > 12 ? hour - 12 : hour;
      return `${formattedHour} ${period}`;
    },
    setDay(day: Appointment) {
      const previousTimeAvailable = day.times.some(
        (timeSlot) => timeSlot.time === this.selectedTime
      );

      if (!previousTimeAvailable) {
        this.selectedTime = '';
      }
      this.selectedDay = day;

      this.$nextTick(() => {
        document?.getElementById('time-picker')?.scrollIntoView({ behavior: 'smooth' });
      });
    },
    setTime(time: string) {
      this.selectedDayAndTime = true;
      this.selectedTime = time;
      this.$nextTick(() => {
        document?.getElementById('continue-button')?.scrollIntoView({ behavior: 'smooth' });
      });
    },
    getDayString(day: any) {
      const normalizedDay = day.replaceAll('-', '/');
      const today = new Date();
      const date = new Date(`${normalizedDay} GMT-12:00`);
      let buttonText = date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: undefined,
        month: 'long',
        day: 'numeric',
      });

      return date.toLocaleDateString() === today.toLocaleDateString()
        ? `Today,<br/>${buttonText.split(',')[1]}`
        : buttonText.replace(',', ',<br/>');
    },
    setDayAndTime() {
      if (!this.selectedTime) {
        this.showErrorMessage = true;
        return;
      }

      this.showErrorMessage = false;
      this.loading = true;
      this.setTestDriveDay(this.selectedDay.day || emptySelectedDay);
      this.setTestDriveTime(this.selectedTime);
      this.loading = false;
      this.setTestDriveView('contactInfo');
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;
@use 'sass:math';

$gap: 16px;

.day-and-time {
  padding: $spacing_stretch-sm;
  margin-bottom: 136px;

  @include media-breakpoint-down(md) {
    padding-bottom: 24px;
  }
  @include media-breakpoint-up(lg) {
    width: (500px - $spacing_inline-xxs - $spacing_inline-sm);
    padding: 0;
    margin: 0 $spacing_inline-xxs 0 $spacing_inline-sm;
  }
}

.h6 {
  margin-bottom: $spacing_stack-xxxs;
}

.v-btn-group--density-default.v-btn-group {
  height: unset;
}

.day-toggle-group,
.time-toggle-group {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
}

.day-toggle-group {
  .v-btn-group {
    width: calc(50% - math.div($gap, 2));
  }

  @include media-breakpoint-down(xxs) {
    .v-btn-group {
      width: 100%;
    }
    .v-btn {
      padding: 4px;
      font-size: $font-size-xxs;
    }
  }

  @include media-breakpoint-up(md) {
    .v-btn-group {
      width: calc((100% / 3) - math.div($gap * 2, 3));
    }
    .v-btn {
      padding: $spacing_squish-nano;
    }
  }
}

.busy-label {
  color: $park-gray-20;
  font-size: $font-size-xxxs;
  margin-bottom: $spacing_stack-xxxs;

  i {
    font-size: $font-size-xxs;
    margin-right: $spacing_inline-nano;
  }
}

.time-toggle-group {
  @include media-breakpoint-up(md) {
    margin-bottom: $spacing_stack-sm;
  }

  .v-btn-group {
    @include media-breakpoint-up(md) {
      width: calc(50% - math.div($gap, 2));
    }
  }
  .v-btn-toggle {
    position: relative;

    i {
      color: $park-gray-20;
      font-size: $font-size-xxs;
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .v-btn.active i {
      top: 15px;
      left: 15px;
    }
  }
}

.time-picker {
  scroll-margin-top: 20px;
  margin-top: $spacing_stack-sm;
}

.time-picker-heading {
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.continue-button {
  background: $base-white-100;
  padding: $spacing_stretch-sm;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  max-width: 100vw;
  box-shadow: 0px -8px 7px 0px #00000012;
  z-index: 1000;
  border-radius: 0;

  @include media-breakpoint-down(md) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    bottom: unset;
    padding: 0;
    margin: $spacing_stack-sm auto $spacing_stack-xs;
    width: auto;
    max-width: 336px;
    box-shadow: none;
    border-radius: $border-radius-md;
  }

  .v-btn {
    width: 100%;
    padding-left: 16px !important;
    padding-right: 16px !important;
    border-radius: $border-radius-lg;
  }

  .button-and-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .v-messages__message {
    margin-top: 4px;
    text-align: center;
    color: $supplementary-red-50 !important;
    font-size: $font-size-xxxxs;
    line-height: $line-height-md;
  }
}
.day-toggle-group .v-btn,
.time-toggle-group .v-btn {
  &:hover {
    background-color: #f4f5f7 !important;
    cursor: pointer;
  }
}
</style>
