<!--
  A generic panel that slides in from the right side of the view.
-->
<template>
  <div class="flyout-container">
    <Overlay :showOverlay="isOpen" :zIndex="75" @click="$emit('close')" />
    <div class="flyout-slot-container">
      <div :class="{ show_panel: isOpen }" class="flyout-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Overlay from '@components-vue/Foundation/Common/Overlay/Overlay.vue';

export default defineComponent({
  data() {
    return {};
  },
  name: 'Flyout',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Overlay,
  },
  methods: {
    handleOverlayClick() {},
  },
  emits: ['close'],
});
</script>

<style lang="scss">
.flyout-container {
  position: relative;
  z-index: 9999;
}
.flyout-slot-container {
  position: fixed;
  z-index: 81;
}
.flyout-content {
  width: 100%;
  height: 100%;
  background: var(--base-white-100);
  padding: 24px;
  overflow: auto;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 12;
  transition: all 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: var(--green-green-60) var(--accent-warm-gray-warm-gray-80);

  @media (min-width: 576px) {
    max-width: 380px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: var(--accent-warm-gray-warm-gray-80);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--green-green-60);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--green-green-60);
  }
  &.show_panel {
    right: -5px;
    width: calc(100% + 5px);
  }
  .ParagraphParkGray-BodySM {
    text-align: center;

    .SystemLInk-Body-SM {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .field-validation-error {
    color: var(--supplementary-red-50);
  }
  .field-validation-success {
    color: var(--green-50);
  }
}
</style>
