import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "body-style-filter",
  "data-component-name": "body-style-filter",
  "data-testid": "body-style-filter"
}
const _hoisted_2 = { "data-role": "facet-filter" }
const _hoisted_3 = { class: "bodyStyleFilter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMenuLineItem = _resolveComponent("FilterMenuLineItem")!
  const _component_FacetList = _resolveComponent("FacetList")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterMenuLineItem, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandFilter())),
            isSelected: !!_ctx.facetFilters[_ctx.filterType],
            title: _ctx.$t('Body Style Filter Title'),
            "data-for": _ctx.$t('Body Style Filter Title')
          }, null, 8, ["isSelected", "title", "data-for"])
        ], 512), [
          [_vShow, !_ctx.bodyStyleFilterSelected && !_ctx.filterSelected]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_FacetList, {
              title: _ctx.title,
              data: _ctx.facetList,
              filterType: _ctx.filterType,
              modelValue: _ctx.facetModel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.facetModel) = $event))
            }, null, 8, ["title", "data", "filterType", "modelValue"])
          ])
        ], 512), [
          [_vShow, _ctx.bodyStyleFilterSelected]
        ])
      ])
    ]),
    _: 1
  }))
}