import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@icons/info.svg'


const _hoisted_1 = { class: "vehicleInfoPrice" }
const _hoisted_2 = {
  class: "vehicleInfoPriceFullPrice",
  "data-testid": "vehicle-price"
}
const _hoisted_3 = { class: "vehicleInfoPriceFullPriceCurrencySymbol" }
const _hoisted_4 = {
  key: 0,
  class: "vehicleInfoPriceInstallmentPrice",
  "data-testid": "vehicle-payment"
}
const _hoisted_5 = { class: "tooltipContainer" }
const _hoisted_6 = ["id", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.currencySymbol), 1),
      _createTextVNode(_toDisplayString(_ctx.formatVehiclePrice(_ctx.vehicle.sellingPrice)), 1)
    ]),
    (_ctx.showPayEstimate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.formatVehicleInstallmentPrice(
          Math.floor(_ctx.vehicle.estimatedPayLow ?? 0.0),
          Math.ceil(_ctx.vehicle.estimatedPayHigh ?? 0.0)
        )) + _toDisplayString(_ctx.$t('Monthly payment')) + " ", 1),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_v_tooltip, {
              text: _ctx.$t('financing-tooltip'),
              location: "top",
              class: "top"
            }, {
              activator: _withCtx(({ props }) => [
                _createElementVNode("img", _mergeProps({
                  id: `priceTooltipIcon-${_ctx.vehicle.vin}`,
                  src: _imports_0,
                  tabindex: "0"
                }, props, {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
                  alt: _ctx.$t('financing-tooltip')
                }), null, 16, _hoisted_6)
              ]),
              _: 1
            }, 8, ["text"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}