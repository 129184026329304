<template>
  <div>
    <div class="vehicleInfoDetails">
      <span>
        {{ vehicle.year }}
      </span>
      <span class="vehicleInfoDetailsSeparator"> | </span>
      <span class="vehicleInfoDetailsMileage">
        {{ formatVehicleMilage(vehicle.miles || 0) }} {{ $t('Car miles') }}
      </span>
      <span class="vehicleInfoDetailsSeparator"> | </span>
      <v-tooltip
        :text="$t('srp.common.copiedToClipboard')"
        v-model="showStockNumberTooltip"
        location="top"
        class="top"
        :open-on-hover="false"
      >
        <template v-slot:activator="{ props }">
          <div
            class="vehicleInfoDetailsStockNumber"
            :id="`vehicleInfoDetailsStockNumber-${vehicle.stockNumber}`"
            @click.prevent.stop="copyToClipboard"
            v-bind="props"
          >
            {{ $t('Stock Number') }} {{ vehicle.stockNumber }}
          </div>
        </template>
      </v-tooltip>
    </div>
    <h2 class="vehicleInfoName">
      {{ formatVehicleName(vehicle.make, vehicle.model, vehicle.trim) }}
    </h2>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { formatVehicleMilage, formatVehicleName } from '@util/formatter';
import { Vehicle } from '@/types/ServiceModels';

export default defineComponent({
  name: 'VehicleCardDetails',
  components: {},
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
  },
  data() {
    return {
      showStockNumberTooltip: false,
    };
  },
  methods: {
    formatVehicleMilage,
    formatVehicleName,
    copyToClipboard(_e) {
      navigator.clipboard.writeText(this.vehicle.stockNumber || '');
      this.showStockNumberTooltip = true;
      setTimeout(() => {
        this.showStockNumberTooltip = false;
      }, 1000);
    },
  },
});
</script>

<style lang="scss">
.vehicleInfoDetails {
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin-bottom: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}
.vehicleInfoDetailsSeparator {
  margin-left: 4px;
  margin-right: 4px;
  color: var(--park-gray-70);

  @media only screen and (max-width: 342px) {
    margin-left: 4px;
    margin-right: 4px;
  }
  @media only screen and (min-width: 625px) and (max-width: 675px) {
    margin-left: 3px;
    margin-right: 3px;
  }
}
.vehicleInfoDetailsMileage {
  color: var(--park-gray-0);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
}
.vehicleInfoDetailsStockNumber {
  color: var(--park-gray-20);
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vehicleInfoName {
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
