import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "feature-filter",
  "data-component-name": "feature-filter"
}
const _hoisted_2 = { "data-role": "facet-filter" }
const _hoisted_3 = { class: "full-height-container" }
const _hoisted_4 = { class: "title-block" }
const _hoisted_5 = {
  key: 0,
  class: "featureFilter"
}
const _hoisted_6 = { class: "menu-title-block" }
const _hoisted_7 = { class: "featureFilter" }
const _hoisted_8 = { class: "menu-title-block" }
const _hoisted_9 = { class: "featureFilter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMenuLineItem = _resolveComponent("FilterMenuLineItem")!
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_FacetList = _resolveComponent("FacetList")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterMenuLineItem, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandFilter())),
            isSelected: !!_ctx.facetFilters[_ctx.filterType],
            title: _ctx.fields.MenuTitle.value
          }, null, 8, ["isSelected", "title"])
        ], 512), [
          [_vShow, !_ctx.featureFilterSelected && !_ctx.filterSelected]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_sc_text, {
              field: _ctx.fields.MenuTitle,
              class: "title-text"
            }, null, 8, ["field"])
          ]),
          (_ctx.selectedFeatures && _ctx.selectedFeatures.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_FacetList, {
                  data: _ctx.selectedFeatures,
                  filterType: _ctx.filterType
                }, null, 8, ["data", "filterType"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_sc_text, {
              field: _ctx.fields.CommonFeaturesTitle,
              class: "menu-title-text"
            }, null, 8, ["field"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FacetList, {
              data: _ctx.popularFeatureOptions,
              filterType: _ctx.filterType,
              "data-testid": "popular-features-list"
            }, null, 8, ["data", "filterType"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_sc_text, {
              field: _ctx.fields.OtherFeaturesTitle,
              class: "menu-title-text"
            }, null, 8, ["field"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_FacetList, {
              data: _ctx.otherFeatureOptions,
              filterType: _ctx.filterType,
              "data-testid": "other-features-list"
            }, null, 8, ["data", "filterType"])
          ])
        ], 512), [
          [_vShow, _ctx.featureFilterSelected]
        ])
      ])
    ]),
    _: 1
  }))
}