import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flyout-container" }
const _hoisted_2 = { class: "flyout-slot-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Overlay, {
      showOverlay: _ctx.isOpen,
      zIndex: 75,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, null, 8, ["showOverlay"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([{ show_panel: _ctx.isOpen }, "flyout-content"])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ])
  ]))
}