<template>
  <div class="location-card-zip">
    <div v-if="dealership" class="location-card-info-zip">
      <div class="location-card-info-title-zip">
        <div class="store-content">
          <a class="store-name" :href="dealership.url" target="_self" role="link" tabindex="-1">
            {{ dealership.storeName }}
            <span class="distance-store">
              ({{ dealership.distance?.toFixed() }} {{ $t('srp.common.mi') }})
            </span>
          </a>
        </div>
      </div>
      <div class="container-general">
        <div class="left-side">
          <p class="address-store">
            {{ dealership.address }}<br />
            {{ dealership.city }}, {{ dealership.state }} {{ dealership.zip }}<br />
          </p>
          <div class="phone-number-section">
            <p class="telephone-store">
              <a
                :href="'tel:' + dealership.phone"
                tabindex="-1"
                class="ancStorePhone"
                :data-storename="dealership.storeName"
                :data-storephone="dealership.phone"
                v-if="dealership.phone"
                >{{ dealership.phone }}</a
              >
            </p>
          </div>
        </div>
        <div class="right-side">
          <a
            :href="directionsLink"
            class="directions-link"
            role="link"
            tabindex="-1"
            target="_blank"
            :data-storename="dealership.storeName"
            aria-describedby="audioeye_new_window_message"
          >
            <div class="button-directions">
              <slot name="icon-direction-image"
                ><svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill=""
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id=" Map / Directions">
                    <path
                      id="Arrow Path"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.5 13.369C7.5 12.911 7.873 12.539 8.33 12.539H16.124L12.5402 16.5605C12.1728 16.9728 12.2091 17.6049 12.6214 17.9724C13.0337 18.34 13.666 18.3037 14.0335 17.8913L19.1229 12.1807C19.4246 11.8421 19.4253 11.3313 19.1244 10.9919L14.0325 5.2484C13.6661 4.8351 13.034 4.79718 12.6208 5.1637C12.2078 5.53006 12.1698 6.16185 12.5361 6.57499L16.05 10.539H8.33C6.77 10.539 5.5 11.808 5.5 13.369V18.164C5.5 18.7163 5.94772 19.164 6.5 19.164C7.05228 19.164 7.5 18.7163 7.5 18.164V13.369Z"
                      fill="#006FA6"
                    />
                  </g>
                </svg>
              </slot>
            </div>
            <div class="directions-description">
              <slot name="direction-direction-text">{{ $t('srp.common.getDirections') }}</slot>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Dealership } from '@/types/ServiceModels';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'DealershipCard',
  props: {
    dealership: Object as PropType<Dealership>,
    directionsOrigin: String,
  },
  computed: {
    directionsLink(): string {
      if (!this.dealership) return '';
      const store = this.dealership;
      const origin = `${this.directionsOrigin}`;
      const address = `${store.address} ${store.city}, ${store.state} ${store.zip}`;
      return `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
        origin
      )}&destination=${encodeURIComponent(address)}`;
    },
  },
});
</script>
<style lang="scss">
.location-card-zip {
  &,
  .location-card-info-zip,
  .location-card-info-title-zip {
    margin-bottom: 12px;
  }
  .store-name {
    text-decoration: none;
    color: var(--park-gray-0, #242c33);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;

    .distance-store {
      font-weight: 300;
    }
  }
  .button-directions {
    display: flex;
    width: 40px;
    height: 40px;
    //padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    border: 1px solid var(--system-alert-blue-60);
  }
  &,
  .location-card-info-zip {
    background-color: inherit;
  }
  .phone-number-section {
    padding-top: 13px;
  }
  .container-general {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .left-side {
    flex: 1;
  }
  .address-store {
    font-weight: 400;
    font-size: 14px;
  }
  .right-side {
    align-self: flex-end;
    padding-bottom: 8px;
  }
  .directions-link {
    text-decoration: none;
    padding-top: 8px;
    display: grid;
    justify-items: center;
    color: var(--supplementary-blue-60);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: var(--supplementary-blue-60);
    }
    &:active {
      cursor: pointer;
      text-decoration: underline;
      color: var(--supplementary-blue-50);
    }
  }
  .telephone-store {
    padding-top: 12px;
    text-decoration: none;
    align-self: stretch;
    color: var(--system-alert-blue-60);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    a {
      font-weight: 500;
      color: var(--system-alert-blue-60);

      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: var(--supplementary-blue-60);
      }
      &:active {
        cursor: pointer;
        text-decoration: underline;
        color: var(--supplementary-blue-50);
      }
    }
  }
  .directions-description {
    padding-top: 5px !important;
    text-decoration: none;
    height: 21px;
    align-self: stretch;
    color: var(--system-alert-blue-60);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
</style>
