<template>
  <div class="modal show ask-us-modal" id="AskUsModal" v-if="isOpen">
    <div class="askUsModalContent">
      <AskUsForm :askUsVehicle="getVehicleByVin(selectedVin)" />
    </div>
  </div>
</template>

<script lang="ts">
import AskUsForm from '@components/Feature/VehicleSearch/Forms/AskUsForm.vue';
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AskUsModal',
  props: {
    isOpen: Boolean,
    selectedVin: String,
  },
  computed: {
    ...mapGetters('searchResults', ['getVehicleByVin']),
  },
  components: {
    AskUsForm,
  },
});
</script>

<style lang="scss">
.ask-us-modal {
  display: flex;
  min-height: calc(100% - 80px);
  background-color: var(--park-gray-0-op50);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.askUsModalContent {
  display: flex;
  background-color: var(--base-white-100);
  padding: 24px;
  margin: auto auto;
  min-width: 328px;
  max-width: 500px;
  border-radius: 16px;
}

@media only screen and (max-width: 625px) {
  .ask-us-modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .askUsModalContent {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

@media only screen and (max-width: 375px) {
  .ask-us-modal {
    width: 100%;
    border-radius: 0;
    overflow: auto;
  }
  .askUsModalContent {
    width: 100%;
    border-radius: 0;
    height: auto;
  }
}
</style>
