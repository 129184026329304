import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pageNavigationBox"
}
const _hoisted_2 = { id: "SrpPaginationText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationButton = _resolveComponent("NavigationButton")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_ctx.hasResults)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_tooltip, {
          text: `Previous ${_ctx.take}`,
          location: "top",
          class: "top"
        }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_NavigationButton, _mergeProps({
              id: "navButtonLeft",
              direction: "left",
              disabled: _ctx.isFirstPage,
              handleButtonClick: _ctx.goToPreviousPage
            }, props), null, 16, ["disabled", "handleButtonClick"])
          ]),
          _: 1
        }, 8, ["text"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.startCarIndex) + "-" + _toDisplayString(_ctx.lastCarIndex) + " of " + _toDisplayString(_ctx.formattedVehicleAmount), 1)
        ]),
        _createVNode(_component_v_tooltip, {
          text: `Next ${_ctx.take}`,
          location: "top",
          class: "top"
        }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_NavigationButton, _mergeProps({
              id: "navButtonRight",
              direction: "right",
              disabled: _ctx.isLastPage,
              handleButtonClick: _ctx.goToNextPage
            }, props), null, 16, ["disabled", "handleButtonClick"])
          ]),
          _: 1
        }, 8, ["text"])
      ]))
    : _createCommentVNode("", true)
}