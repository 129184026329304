let _resetDefault = false;

export function onLoginMount() {
  const toggleSignIn = (visible) => {
    $('#signInContainer').toggleClass(['show-signIn', 'show_panel'], visible);
    $('.signInOuterContainer').toggleClass('d-none', !visible);
  };

  const toggleRegistration = (visible) => {
    $('#registrationContainer').toggleClass(['show_panel', 'show-createAccount'], visible);
    $('.registrationOuterContainer').toggleClass('d-none', !visible);
  };

  const toggleForgotPassword = (visible) => {
    $('#forgotPasswordContainer').toggleClass(['show_panel', 'show-forgotpassword'], visible);
    $('.forgotPasswordOuterContainer').toggleClass('d-none', !visible);
  };

  $('.guest-user').click(function () {
    if ($(this).attr('rel') === 'show-signIn') {
      $('#signInContainer').addClass(['show_panel', 'show-signIn']);
      $('.account_overlay').addClass('show_panel');
    }
  });

  $('#registrationLink').click(function () {
    toggleSignIn(false);
    toggleRegistration(true);
  });

  $('#signLinkRegistration').click(function () {
    toggleSignIn(true);
    toggleRegistration(false);
  });

  $('#passwordLink').click(function () {
    toggleSignIn(false);
    toggleForgotPassword(true);
  });

  $('#signInForgotPassword, #forgotPassGoBack').click(function () {
    toggleForgotPassword(false);
    toggleSignIn(true);
  });

  $('.account_overlay').click(function () {
    $('.create_account_close').trigger('click');
  });

  $('.create_account_close').click(function () {
    $('.account_overlay').removeClass('show_panel');
    toggleForgotPassword(false);
    toggleRegistration(false);
    toggleSignIn(true);
    $('html, body').removeClass('open_create_account');
    $('.create_account').hasClass('accountCreated-content') && location.reload();
    $('.create_account').removeClass().addClass('create_account');

    $('#RegisterForm')
      .find('.form-control')
      .each(function () {
        $(this)[0].value = null;
        $(this).blur();
        $(this).removeClass('input-validation-error');
        $(this).addClass('field-validation-valid');
      });

    $('#RegisterForm')
      .find('.field-validation-error')
      .each(function () {
        $(this).html(null);
      });

    $('.password-info').hide();
    $('.forgotpassword-content').show();
    $('.form-content.forgotpasswordSentEmail-content').hide();
  });

  $('.accountCreatedUsedCars').click(function (e) {
    e.preventDefault();
    $('.account_overlay').removeClass('show_panel');
    $('html, body').removeClass('open_create_account');
    $('.create_account').hasClass('accountCreated-content') && location.reload();
    $('.create_account').removeClass().addClass('create_account');
  });
}
