import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "subtitle",
  "data-testid": "test-drive-subtitle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScheduleTestDriveVehicleAndLocation = _resolveComponent("ScheduleTestDriveVehicleAndLocation")!
  const _component_ScheduleTestDriveDayAndTime = _resolveComponent("ScheduleTestDriveDayAndTime")!
  const _component_ScheduleTestDriveContact = _resolveComponent("ScheduleTestDriveContact")!
  const _component_ScheduleTestDriveConfirmation = _resolveComponent("ScheduleTestDriveConfirmation")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.view === 'confirmation' ? '' : _ctx.fields.subtitle.value), 1),
    _createVNode(_component_ScheduleTestDriveVehicleAndLocation, { fields: _ctx.fields }, null, 8, ["fields"]),
    _createVNode(_component_ScheduleTestDriveDayAndTime, { fields: _ctx.fields }, null, 8, ["fields"]),
    _createVNode(_component_ScheduleTestDriveContact, { fields: _ctx.fields }, null, 8, ["fields"]),
    _createVNode(_component_ScheduleTestDriveConfirmation, { fields: _ctx.fields }, null, 8, ["fields"])
  ], 64))
}