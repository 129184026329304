import { createStore } from 'vuex';
import { CommonModule } from './modules/Common';
import { ScheduleTestDriveModule } from './modules/ScheduleTestDrive';
import { SearchResultsModule } from './modules/SearchResults';
import { RootState } from './State';

const state = (): RootState => ({});

/* NOTE: If any values are added or renamed from any state module, you must check that they are 
updated accordingly in VehicleSearchPageFluxStateContextProcessor.cs (if required) */
export default () =>
  createStore<RootState>({
    state,
    modules: {
      common: CommonModule(),
      scheduleTestDrive: ScheduleTestDriveModule(),
      searchResults: SearchResultsModule(),
    },
  });
