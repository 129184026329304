let _resetDefault = false;

export function onSignInMount() {
  $('#UserName').focus(function () {
    focus('#UserName');
  });

  $('#UserName').blur(function (_key) {
    focusOut('#UserName');
  });

  $('#loginpassword').focus(function () {
    focusPassword('#loginpassword');
  });

  $('#loginpassword').blur(function (_key) {
    focusOutPassword('#loginpassword');
  });

  $('#UserName').keyup(function () {
    validateRequired('Email', '#UserName');
  });

  $('#loginpassword').keyup(function () {
    validateRequiredPassword('Password', '#loginpassword');
  });

  $('#viewPasswordIconLogin').click(function () {
    $('#viewPasswordIconLogin').toggleClass('off');

    if ('password' == $('.view_password').parent().find('input').attr('type')) {
      $('.view_password').parent().find('input').prop('type', 'text');
    } else {
      $('.view_password').parent().find('input').prop('type', 'password');
    }
  });

  $('#LoginForm').submit(function (e) {
    e.preventDefault();

    if (validateFields() == true) {
      const formData = {};
      var dataArray = $('#LoginForm').serializeArray();
      for (var i = 0; i < dataArray.length; i++) {
        formData[dataArray[i].name] = dataArray[i].value;
      }
      $.ajax({
        type: 'POST',
        url: e.target.action,
        data: formData,
        dataType: 'json',
        beforeSend: function (_results) {
          $('#divFlyoutWait').show();
          DisableForm();
        },
        success: function (results) {
          // console.log(results);
          EnableForm();
          if (results != undefined) {
            if (results.HasErrors != undefined && results.HasErrors) {
              $('#divFlyoutWait').hide();
              $('#divLoginError').removeClass('hide');
              var customError = results.Errors.length > 1 ? results.Errors[1] : results.Errors[0];
              $('#pLoginErrorMessage').html(customError);
            } else if (results.Success != undefined && results.Success) {
              $('#LoginForm .alert-warning').addClass('hide');
              location.reload();
            } else {
              $('#divFlyoutWait').hide();
              // console.log(results);
            }
          } else {
            $('#divFlyoutWait').hide();
          }
        },
        error: function (error) {
          console.error(error);
          $('#divFlyoutWait').hide();
          EnableForm();
        },
      });
    }
  });
}

function validateFields() {
  let validate = true;
  if (validateRequired('Email', '#UserName') == false) validate = false;
  if (validateRequiredPassword('Password', '#loginpassword') == false) validate = false;
  return validate;
}

function validateRequired(name, id) {
  if ($(id).val().length == 0) {
    $(id).removeClass('valid');
    $(id).addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(document.createTextNode(name + ' is required.'));
    var span = $(id).parent().find('.field-validation-valid')[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $(id).parent().find('.field-validation-valid').addClass('field-validation-error');
      $(id).parent().find('.field-validation-valid').removeClass('field-validation-valid');
    }
    return false;
  } else {
    $(id).addClass('valid');
    $(id).removeClass('input-validation-error');
    var span = $(id).parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $(id).parent().find('.field-validation-valid').addClass('field-validation-valid');
      $(id).parent().find('.field-validation-valid').removeClass('field-validation-error');
    }
    return true;
  }
}

function validateRequiredPassword(name, id) {
  if ($(id).val().length == 0) {
    $(id).removeClass('valid');
    $(id).addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(document.createTextNode(name + ' is required.'));
    var span = $(id).parent().parent().find('.field-validation-valid')[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $(id).parent().parent().find('.field-validation-valid').addClass('field-validation-error');
      $(id).parent().parent().find('.field-validation-valid').removeClass('field-validation-valid');
    }
    return false;
  } else {
    $(id).addClass('valid');
    $(id).removeClass('input-validation-error');
    var span = $(id).parent().parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $(id).parent().parent().find('.field-validation-valid').addClass('field-validation-valid');
      $(id).parent().parent().find('.field-validation-valid').removeClass('field-validation-error');
    }
    return true;
  }
}

function DisableForm() {
  $('*', 'form').prop('disabled', true);
}

function EnableForm() {
  $('*', 'form').prop('disabled', false);
}

export const focus = (id) => {
  if (!$(id).parent().hasClass('focused')) $(id).parent().addClass('focused');
  if (!$(id).parent().hasClass('Filled')) $(id).parent().addClass('Filled');
};

export const focusOut = (id) => {
  $(id).parent().removeClass('Filled');
  if ($(id).val().length === 0) $(id).parent().removeClass('focused');
  else if (!$(id).parent().hasClass('Active')) $(id).parent().addClass('Active');
};

export const focusPassword = (id) => {
  if (!$(id).parent().parent().hasClass('focused')) $(id).parent().parent().addClass('focused');
  if (!$(id).parent().parent().hasClass('Filled')) $(id).parent().parent().addClass('Filled');
};

export const focusOutPassword = (id) => {
  $(id).parent().parent().removeClass('Filled');
  if ($(id).val().length === 0) $(id).parent().parent().removeClass('focused');
  else if (!$(id).parent().parent().hasClass('Active')) $(id).parent().parent().addClass('Active');
};
