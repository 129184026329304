import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "filter-and-sort-menu" }
const _hoisted_2 = { class: "sorting-menu-container" }
const _hoisted_3 = { class: "sort-by" }
const _hoisted_4 = { class: "sort-by-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_sc_text = _resolveComponent("sc-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sc_image, {
        media: _ctx.fields.SortIcon,
        class: "sorting-icon"
      }, null, 8, ["media"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_sc_text, {
            field: _ctx.fields.SortLabel
          }, null, 8, ["field"])
        ]),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSortTypeModel) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedSortOptions, (option, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: index,
              class: "sort-dropdown-item"
            }, _toDisplayString(option.sortName), 1))
          }), 128))
        ], 512), [
          [_vModelSelect, _ctx.selectedSortTypeModel]
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "filter-menu-cta-container",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleFilterMenu()))
    }, [
      _createVNode(_component_sc_image, {
        media: _ctx.fields.FilterIcon
      }, null, 8, ["media"]),
      _createVNode(_component_sc_text, {
        field: _ctx.fields.FilterLabel,
        "data-testid": "filter-text-mobile"
      }, null, 8, ["field"])
    ])
  ]))
}