export class HeaderMethods {
  readonly element: HTMLElement;
  readonly uid: string;

  constructor(element: HTMLElement, uid: string) {
    this.element = element;
    this.uid = uid;
  }

  setupZipElement(): string {
    const headerElements = this.element.getElementsByClassName('header_right');
    let targetElement = '';
    if (headerElements.length > 0) {
      const containerElement = headerElements[0];
      const headerElement = document.createElement('div');
      const targetElementId = `headerZipCodeDisplay_${this.uid}`;
      targetElement = `#${targetElementId}`;
      headerElement.setAttribute('id', targetElementId);
      containerElement.prepend(headerElement);
    }
    return targetElement;
  }

  setupSubMenu() {
    const moreLinkElements = this.element.getElementsByClassName('more_links');
    for (const element of moreLinkElements) {
      const link = element.querySelector('a');
      if (link !== null) {
        link.addEventListener('click', function (event: Event) {
          const subMenuElements = element.getElementsByClassName('submenu');
          for (const submenu of subMenuElements) {
            submenu.classList.toggle('active');
          }
          event.stopPropagation();
        });
      }
    }
    document.addEventListener('click', function () {
      const subMenuElements = document.getElementsByClassName('submenu');
      for (const submenu of subMenuElements) {
        submenu.classList.remove('active');
      }
    });
  }
  setupMobileMenu() {
    const toggleMenus = this.element.getElementsByClassName('toggle_menu');
    for (const menu of toggleMenus) {
      menu.addEventListener('click', function (event: Event) {
        document.documentElement.classList.add('menu-open');
        event.stopPropagation();
      });
    }
    const closers = document.getElementsByClassName('menu_close');
    for (const closer of closers) {
      closer.addEventListener('click', function (event: Event) {
        document.documentElement.classList.remove('menu-open');
        event.stopPropagation();
      });
    }
    document.addEventListener('click', function () {
      document.documentElement.classList.remove('menu-open');
    });
  }
}
