<template>
  <transition name="slide">
    <div data-component-name="year-filter">
      <div v-show="!yearFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!facetFilters['year']"
          :title="title"
        />
      </div>
      <div v-show="yearFilterSelected">
        <div class="yearRangeFrameDropdown" data-role="drop-down-list">
          <RangeDropdown
            :title="title"
            :selected="yearRange.map((option) => String(option))"
            :options="yearOptions"
            @optionSelected="optionSelected"
            :step="1"
            :minLabel="minLabel"
            :maxLabel="maxLabel"
          ></RangeDropdown>
        </div>
        <div class="yearRangeFrameSlider" data-role="range-slider">
          <RangeSlider
            :step="1"
            :range="yearRange"
            :setRange="setRange"
            v-model="yearModel"
            @sliderValueUpdated="updateYearRange"
            @sliderValueSet="updateYearStore"
          ></RangeSlider>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import RangeSlider from './RangeSlider.vue';
import RangeDropdown from './RangeDropdown.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';

export default defineComponent({
  name: 'YearFilter',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    RangeSlider,
    RangeDropdown,
    FilterMenuLineItem,
  },
  data() {
    return {
      yearOptions: [] as string[],
      yearRange: [] as number[],
      setRange: [] as number[],
      title: this.$t('Year Filter Title'),
      minLabel: this.$t('Min Label'),
      maxLabel: this.$t('Max Label'),
      filterType: 'year',
      setUpdateStoreYear: true,
    };
  },
  computed: {
    ...mapState('searchResults', {
      yearFilterSelected: (state) => state.yearFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      filterSelected: (state) => state.filterSelected,
      facetFilters: (state) => state.facetFilters ?? {},
      vehicleYearMin: (state) => state.srpVehiclesData?.facets?.year?.min,
      vehicleYearMax: (state) => state.srpVehiclesData?.facets?.year?.max,
    }),
    yearModel: {
      get() {
        return this.yearOptions;
      },
      set(value) {
        this.updateYearStore(value);
      },
    },
  },
  created() {
    this.setUpdateStoreYear = false;
    this.setYearRange();
  },
  methods: {
    ...mapMutations('searchResults', [
      'setFilterSelected',
      'setYearFilterSelected',
      'setResetSelectedFacetItems',
      'setNumericFacetFilter',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setYearFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    setYearRange() {
      const currentYear = new Date().getFullYear();
      const startYear = parseInt(String(this.vehicleYearMin ?? 2000));

      this.yearOptions = [];
      for (let year = startYear; year <= currentYear; year++) {
        this.yearOptions.push(String(year));
      }

      this.setRange = [startYear, currentYear];
      this.yearRange = [startYear, currentYear];
    },
    updateYearRange(values) {
      this.setUpdateStoreYear = true;
      let minInt = parseInt(values[0]);
      let maxInt = parseInt(values[1]);
      this.yearRange = [minInt, maxInt];
    },
    updateYearStore(values: number[]) {
      if (this.setUpdateStoreYear) {
        this.setUpdateStoreYear = false;
        const minInt = values[0];
        const maxInt = values[1];
        const data = {
          name: 'year',
          value: { min: minInt, max: maxInt },
        };

        this.yearRange = [minInt, maxInt];
        this.setNumericFacetFilter(data);
        this.emitter.emit('filter-updated-srp');
      }
    },
    optionSelected(values) {
      this.setUpdateStoreYear = true;
      this.updateYearStore(values);
    },
  },
  watch: {
    facetFilters: {
      handler: async function (newValue) {
        if (!newValue.hasOwnProperty(this.filterType)) {
          this.yearRange = [this.setRange[0], this.setRange[1]];
          this.setUpdateStoreYear = false;
        } else {
          this.yearRange = [
            this.facetFilters[this.filterType].min,
            this.facetFilters[this.filterType].max,
          ];
        }
      },
      deep: true,
    },
    vehicleYearMin() {
      this.setYearRange();
    },
    vehicleYearMax() {
      this.setYearRange();
    },
  },
});
</script>

<style lang="scss">
.yearRangeFrameDropdown {
  padding-top: 20px;
  padding-bottom: 0 !important;

  .filter-selected & {
    padding-top: 0;
  }
}
.yearRangeFrameSlider {
  padding-top: 0;
  padding-bottom: 20px !important;
}
</style>
