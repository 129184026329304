import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "dynamicLinkArea" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButtonCarousel = _resolveComponent("TextButtonCarousel")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicLinks, (list, key) => {
      return (_openBlock(), _createBlock(_component_TextButtonCarousel, {
        class: "dynamicLinkItem",
        title: list.title,
        textBunttonList: list.links,
        carouselId: list.id,
        onBtnclick: _ctx.btnClickHandler,
        btnStyle: _ctx.btnStyle(),
        key: `link-carousel-${key}`
      }, null, 8, ["title", "textBunttonList", "carouselId", "onBtnclick", "btnStyle"]))
    }), 128))
  ], 512)), [
    [_vShow, _ctx.dynamicLinks.length]
  ])
}