<template>
  <button
    type="button"
    role="button"
    class="button-primary"
    @click="$emit('click')"
    :disabled="disabled"
  >
    <div class="button-primary-text">{{ text }}</div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button', // eslint-disable-line vue/no-reserved-component-names
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  components: {},
});
</script>

<style lang="scss">
.button-primary {
  display: flex;
  width: 280px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: var(--system-green-50);
  text-align: center;
  vertical-align: middle;
  border: 3px solid transparent;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  cursor: pointer;
  border-radius: 28px;

  @media only screen and (min-width: 625px) {
    display: flex;
    max-width: 336px;
    height: 56px;
  }

  &:hover {
    background: var(--system-green-40);
  }
  &:active {
    background: var(--system-green-60);
  }
  &:disabled {
    background: var(--warm-gray-90);
  }
  &:focus {
    background: var(--system-green-50);
  }
  &:disabled .button-primary-text {
    color: var(--park-gray-0);
    opacity: 0.3;
  }
}
.button-primary-text {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.4px;
  padding: 17px 37px;
  text-decoration: none;
  width: auto;
  color: var(--base-white-100);
}
</style>
