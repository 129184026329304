<template>
  <v-row class="style-guide--shadows">
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box border-radius-md shadow-level-1"></div>
      <code>.shadow-level-1</code>
      <code>$shadow-level-1</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box border-radius-md shadow-level-2"></div>
      <code>.shadow-level-2</code>
      <code>$shadow-level-2</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box border-radius-md shadow-level-3"></div>
      <code>.shadow-level-3</code>
      <code>$shadow-level-3</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xl="2">
      <div class="box border-radius-md shadow-level-4"></div>
      <code>.shadow-level-4</code>
      <code>$shadow-level-4</code>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Shadows',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss"></style>
