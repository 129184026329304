import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "contact-info"
}
const _hoisted_2 = { class: "header-info" }
const _hoisted_3 = {
  id: "contact-form",
  class: "contact-form"
}
const _hoisted_4 = {
  id: "book-button",
  class: "book-button"
}
const _hoisted_5 = {
  key: 0,
  class: "v-messages__message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rich_text = _resolveComponent("rich-text")!
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_ctx.view === 'contactInfo')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_rich_text, {
            "data-testid": "test-drive-contactHeading",
            tag: "p",
            class: "h6",
            field: _ctx.fields.contactHeading
          }, null, 8, ["field"]),
          _createVNode(_component_sc_text, {
            "data-testid": "test-drive-contactDetails",
            tag: "p",
            class: "small",
            field: _ctx.fields.contactDetails
          }, null, 8, ["field"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_form, {
            ref: "form",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.valid) = $event)),
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.firstname,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstname) = $event)),
                        rules: [_ctx.rules.firstname],
                        label: _ctx.fields.firstNameLabel.value,
                        name: "firstname"
                      }, null, 8, ["modelValue", "rules", "label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.lastname,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastname) = $event)),
                        rules: [_ctx.rules.lastname],
                        label: _ctx.fields.lastNameLabel.value,
                        name: "lastname"
                      }, null, 8, ["modelValue", "rules", "label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                        rules: [_ctx.rules.email],
                        label: _ctx.fields.emailLabel.value,
                        name: "email"
                      }, null, 8, ["modelValue", "rules", "label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.mobile,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.mobile) = $event)),
                        rules: [_ctx.rules.mobile],
                        label: `${_ctx.fields.mobileLabel.value} ${_ctx.fields.phoneNumberOptional.value ? '(Optional)' : ''}`,
                        onInput: _ctx.mobileMask,
                        name: "mobile",
                        maxlength: "14"
                      }, null, 8, ["modelValue", "rules", "label", "onInput"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { "no-gutters": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_v_btn, {
                          type: "submit",
                          loading: _ctx.loading,
                          onClick: _ctx.validateForm,
                          onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.valid && _ctx.setContactInfo(_ctx.firstname, _ctx.lastname, _ctx.email, _ctx.mobile)), ["enter"])),
                          "data-testid": "test-drive-contactConfirmationButton"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.fields.contactButtonText.value), 1)
                          ]),
                          _: 1
                        }, 8, ["loading", "onClick"]),
                        (_ctx.showErrorMessage)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Review & complete the missing information. "))
                          : _createCommentVNode("", true),
                        _createVNode(_component_rich_text, {
                          tag: "p",
                          field: _ctx.fields.contactButtonNote
                        }, null, 8, ["field"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]))
    : _createCommentVNode("", true)
}