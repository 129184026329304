import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "srptoggle",
  class: "shopCar-section",
  "data-testid": "filter-toggle"
}
const _hoisted_2 = { class: "shopCar-section--tabs" }
const _hoisted_3 = ["onClick", "id", "data-action-toggle"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("button", {
            type: "button",
            class: _normalizeClass(option.value == _ctx.modelValue ? 'toggleBtn active' : 'toggleBtn'),
            onClick: ($event: any) => (_ctx.updateValue(option.value)),
            key: option.value,
            id: option.text,
            "data-action-toggle": option.text,
            "data-testid": "toggle-button"
          }, _toDisplayString(option.text), 11, _hoisted_3))
        }), 128))
      ])
    ])
  ]))
}