<template>
  <div id="srptoggle" class="shopCar-section" data-testid="filter-toggle">
    <div>
      <div class="shopCar-section--tabs">
        <button
          type="button"
          v-for="option in options"
          :class="option.value == modelValue ? 'toggleBtn active' : 'toggleBtn'"
          @click="updateValue(option.value)"
          :key="option.value"
          :id="option.text"
          :data-action-toggle="option.text"
          data-testid="toggle-button"
        >
          {{ option.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TextToggle',
  props: {
    options: Array,
    modelValue: Boolean,
  },
  data() {
    return {};
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(newValue) {
      if (this.modelValue == newValue) {
        return;
      }
      this.$emit('update:modelValue', newValue);
    },
  },
});
</script>

<style lang="scss">
.shopCar-section--tabs {
  border-radius: 20px;
  border: 1px solid var(--park-gray-80);
  background: var(--base-white-100);
  padding: 4px;
  margin: 0 24px;
  display: flex;
  justify-content: center;

  .toggleBtn {
    max-width: 327px;
    margin: 0 auto;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    color: var(--park-gray-0);
    cursor: pointer;
    padding: 8px 16px;
    border: 0;
    border-radius: 16px;
    display: flex;
    flex: 1;
    justify-content: center;
    white-space: nowrap;
    background-color: transparent;

    &.active {
      background-color: var(--system-green-50);
      color: var(--base-white-100);
    }
    &:hover:not(.active) {
      background-color: transparent;
    }
  }
}
</style>
