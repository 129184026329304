<template>
  <transition name="slide">
    <div class="feature-filter" data-component-name="feature-filter">
      <div v-show="!featureFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!facetFilters[filterType]"
          :title="fields.MenuTitle.value"
        />
      </div>
      <div v-show="featureFilterSelected" class="full-height-container">
        <div class="title-block">
          <sc-text :field="fields.MenuTitle" class="title-text" />
        </div>
        <div class="featureFilter" v-if="selectedFeatures && selectedFeatures.length > 0">
          <FacetList :data="selectedFeatures" :filterType="filterType"></FacetList>
        </div>
        <div class="menu-title-block">
          <sc-text :field="fields.CommonFeaturesTitle" class="menu-title-text" />
        </div>
        <div class="featureFilter">
          <FacetList :data="popularFeatureOptions" :filterType="filterType" data-testid='popular-features-list'></FacetList>
        </div>
        <div class="menu-title-block">
          <sc-text :field="fields.OtherFeaturesTitle" class="menu-title-text" />
        </div>
        <div class="featureFilter">
          <FacetList :data="otherFeatureOptions" :filterType="filterType" data-testid='other-features-list'></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import FacetList from './FacetList.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper';
import { FacetListItemValue } from '@/types/VehicleSearch/Facets';

export default defineComponent({
  name: 'FeatureFilter',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FacetList,
    ScText: Text,
    FilterMenuLineItem,
  },
  data() {
    return {
      filterType: facetMetaData.feature.key,
      selectedFeatures: [],
      popularFeatureOptions: [],
      otherFeatureOptions: [],
    };
  },
  computed: {
    ...mapState('searchResults', {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      featureFilterSelected: (state) => state.featureFilterSelected,
      featureCount: (state) => state.facetCounts?.feature || {},
    }),
    facetListCommonFeatures() {
      return this.fields?.CommonFeatures?.map((x) => ({
        name: x.fields?.Feature?.value,
        value: this.getCount(x),
        scImage: x.fields?.FeatureImage,
      }));
    },
    facetListOtherFeatures() {
      return this.fields?.OtherFeatures?.map((x) => ({
        name: x.fields?.Feature?.value,
        value: this.getCount(x),
      }));
    },
    getCount() {
      return (x) =>
        this.featureCount.hasOwnProperty(x.fields?.Feature?.value)
          ? this.featureCount[x.fields.Feature.value]
          : 0;
    },
    /* from facetListCommonFeatures and facetListOtherFeatures, fish out the currently selected facets */
    selectedFeatureFacets() {
      return this.facetFilters.feature?.facetValue
        ?.map((filter) => {
          return (
            this.facetListCommonFeatures.find((commonFacet) => {
              return commonFacet.name === filter;
            }) ||
            this.facetListOtherFeatures.find((otherFacet) => {
              return otherFacet.name === filter;
            })
          );
        })
        .filter((item) => item); // remove undefined
    },
  },
  methods: {
    ...mapMutations('searchResults', [
      'setFilterSelected',
      'setFeatureFilterSelected',
      'setResetSelectedFacetItems',
      'setStringFacetFilter',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setFeatureFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
    setSelections() {
      this.selectedFeatures = this.selectedFeatureFacets || [];
      this.popularFeatureOptions = this.facetListCommonFeatures.filter((item) => {
        return !this.selectedFeatures.some((facet: FacetListItemValue) => facet.name === item.name);
      });
      this.otherFeatureOptions = this.facetListOtherFeatures.filter((item) => {
        return !this.selectedFeatures.some((facet: FacetListItemValue) => facet.name === item.name);
      });
    },
    updateFeatureCounts(options, facets) {
      return options.map((item) => {
        const matchingItem = facets.find((facet) => facet.name === item.name);
        return matchingItem ? { ...item, value: matchingItem.value } : item;
      });
    },
    updateFacetCounts() {
      const commonFacets = this.facetListCommonFeatures;
      this.popularFeatureOptions = this.updateFeatureCounts(
        this.popularFeatureOptions,
        commonFacets
      );

      const otherFacets = this.facetListOtherFeatures;
      this.otherFeatureOptions = this.updateFeatureCounts(this.otherFeatureOptions, otherFacets);

      const selectedFacets = this.selectedFeatureFacets;
      this.selectedFeatures = this.updateFeatureCounts(this.selectedFeatures, selectedFacets);
    },
    updateStore(data) {
      this.setStringFacetFilter(data);
      this.emitter.emit('filter-updated-srp');
    },
  },
  watch: {
    featureFilterSelected(isSelected) {
      if (isSelected) {
        this.emitter.emit('fetch-facet-count');
        this.emitter.on('facet-count-fetched', () => {
          /* when opening the filter, make sure the "new" lists are populated to represent
          what selections belong at the top of the screen and remove the selected ones
          from the popular/more features sections */
          this.setSelections();
          this.emitter.off('facet-count-fetched');
        });
      }
    },
    facetFilters: {
      handler: async function () {
        if (this.featureFilterSelected) {
          this.emitter.emit('fetch-facet-count');
          this.emitter.on('facet-count-fetched', () => {
            /* when facet filters change, only update the counts from the "old" list to the "new"
             - no need to update selections to top of page*/
            this.updateFacetCounts();
            this.emitter.off('facet-count-fetched');
          });
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss">
.feature-filter {
  .title-block {
    padding: 16px 24px 32px;
    justify-content: flex-start;
    align-items: center;
    gap: 84px;
    display: inline-flex;
    width: 100%;
  }

  .title-text {
    color: var(--park-gray-0);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    word-wrap: break-word;
  }

  .menu-title-text {
    color: var(--park-gray-0);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  .menu-title-block {
    padding: 16px 24px;
    justify-content: flex-start;
    align-items: center;
    gap: 84px;
    display: inline-flex;
    width: 100%;
  }

  .facet-list {
    width: 100%;
  }

  .featureFilter {
    padding-bottom: 32px;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #d9d9d9 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 7px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 8px;
    border: 1px solid #ffffff;
  }

  .full-height-container {
    height: 88vh; /* 100% of the viewport height */
    overflow-y: auto; /* Enable vertical scrolling if the content overflows */
  }
}
</style>
