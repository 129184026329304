<template>
  <div class="signInOuterContainer">
    <div id="signInContainer" class="create_account">
      <sc-rich-text :field="fields.html" />
    </div>
  </div>
</template>

<script lang="ts">
import { RichText } from '@sitecore-jss/sitecore-jss-vue';
import { onLoginMount } from '@assets/js/login';
import { onSignInMount } from '@assets/js/signIn';

export default {
  name: 'EchoParkLogin',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ScRichText: RichText,
  },
  mounted() {
    onLoginMount();
    onSignInMount();
  },
  updated() {
    onLoginMount();
    onSignInMount();
  },
};
</script>

<style lang="scss">
#signInContainer {
  z-index: 81;
}
.signInOuterContainer {
  position: fixed;
  z-index: 999;

  .btn {
    background-color: var(--green-50);
    color: var(--base-white-100);
  }
}
.account_overlay,
.calculator_overlay,
.car_flyout_overlay,
.create_account_overlay {
  width: 100%;
  height: 100%;
  background-color: #242c33;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.show_panel {
    opacity: 0.33;
    visibility: visible;
  }
}
.open_create_account {
  overflow: hidden;
}

.create_account {
  width: 100%;
  height: 100%;
  background: var(--base-white-100);
  padding: 24px;
  overflow: auto;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 12;
  transition: all 0.3s ease-in-out;
  scrollbar-width: thin;
  scrollbar-color: var(--green-green-60) var(--accent-warm-gray-warm-gray-80);

  @media (min-width: 576px) {
    max-width: 380px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: var(--accent-warm-gray-warm-gray-80);
  }
  &::-webkit-scrollbar-thumb {
    &,
    &:hover {
      background: var(--green-green-60);
    }
  }
  &.show-signIn .signIn-content {
    display: block;
  }
  &.show_panel {
    right: -5px;
    width: calc(100% + 5px);
  }
  &.show-forgotpassword .forgotpassword-content {
    display: block;
  }
  &.show-forgotpasswordSentEmail .forgotpasswordSentEmail-content {
    display: block;
  }
  &.show-accountCreated .accountCreated-content {
    display: block;
  }

  .create_account_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    min-height: 24px;
    position: relative;
  }
  .ParagraphParkGray-BodySM {
    text-align: center;

    .SystemLInk-Body-SM {
      text-decoration: none;
    }
  }
  .formCenter {
    max-width: 375px;
    margin: 0 auto;

    @media (min-width: 576px) {
      max-width: inherit;
    }
  }
  .create_account_close {
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: 0;
  }
  .goback {
    min-width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .account_infobox {
    width: 100%;
    max-width: 286px;
    margin: 48px auto 24px;
    text-align: center;

    .status_icon {
      margin-bottom: 24px;
    }
    .HeadingParkGray-H4 {
      margin-bottom: 12px;
    }
  }
}
.welcome_title {
  margin-bottom: 24px;

  .HeadingParkGray-H4 {
    margin-bottom: 8px;
  }
}
#createAccountHeaderContainer {
  z-index: 13;
  background: transparent;
  right: -5px;
  width: calc(100% + 5px);
  pointer-events: none;
}
</style>
