<template>
  <div class="currency-wrapper">
    <input
      v-if="isMin"
      :id="id"
      type="text"
      ref="inputMinCurrency"
      class="input-currency"
      v-model="displayValue"
      @blur="emitUpdate"
      @keyup.enter="emitUpdate"
      @focus="handleFocus"
      data-action="set-minimum-value"
      data-testid="input-range-min"
    />
    <input
      v-if="!isMin"
      :id="id"
      type="text"
      ref="inputMaxCurrency"
      class="input-currency"
      v-model="displayValue"
      @blur="emitUpdate"
      @keyup.enter="emitUpdate"
      @focus="isInputActive = true"
      data-action="set-maximum-value"
      data-testid="input-range-max"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CurrencyInput',
  props: {
    modelValue: {
      type: Number,
    },
    isMin: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      isInputActive: false,
    };
  },
  emits: ['focus', 'emitUpdate', 'update:modelValue'],
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          return this.modelValue?.toString();
        } else {
          return '$ ' + this.modelValue?.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
        }
      },
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ''));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    handleFocus() {
      this.isInputActive = true;
      this.$emit('focus');
    },
    emitUpdate() {
      this.isInputActive = false;
      this.$emit('emitUpdate');
    },
  },
});
</script>

<style lang="scss">
.currency-wrapper {
  display: flex;
  width: 100px;
  height: 40px;
  padding: 0 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--park-gray-80);

  .input-currency {
    border: none;
    display: inline;
    width: 100%;
    color: var(--park-gray-0);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &:active,
    &:focus {
      padding-left: 22px;
      margin-left: -9px;
      border-radius: 8px;
      height: 40px;
      width: 100px;
      line-height: 40px;
    }
  }
}
</style>
