import { FacetFilters, FacetMetaData } from '@/types/VehicleSearch/Facets';

const mmt = {
  key: 'mmt',
  valuesToFilter: ['mmt', 'facetValue', 'type'],
  type: 'hierarchical',
};

const bodystyle = {
  key: 'bodystyle',
  type: 'string',
};

const extcolor = {
  key: 'extcolor',
  postfix: ' Ext',
  type: 'string',
};

const intcolor = {
  key: 'intcolor',
  postfix: ' Int',
  urlPrefix: 'int-',
  type: 'string',
};

const enginedescription = {
  key: 'enginedescription',
  type: 'string',
};

const transmission = {
  key: 'transmission',
  type: 'string',
};

const feature = {
  key: 'feature',
  type: 'string',
};

const drivetype = {
  key: 'drivetype',
  type: 'string',
};

const price = {
  prefix: '$',
  key: 'price',
  urlPrefix: 'price=',
  type: 'numeric',
};

const payment = {
  prefix: '$',
  key: 'payment',
  urlPrefix: 'payment=',
  type: 'numeric',
};

const year = {
  key: 'year',
  urlPrefix: 'year=',
  type: 'numeric',
};

const miles = {
  postfix: ' mi',
  key: 'miles',
  urlPrefix: 'miles=',
  type: 'numeric',
};

const hmpg = {
  postfix: ' mpg',
  key: 'hmpg',
  urlPrefix: 'hmpg=',
  type: 'numeric',
};

const trim = {
  key: 'trim',
  type: 'string',
};

const model = {
  key: 'model',
  type: 'string',
};

const page = {
  key: 'page',
  urlPrefix: 'page=',
};

const dealer = {
  key: 'dealer',
  urlPrefix: 'dealer=',
};

const store = {
  key: 'store',
  urlPrefix: 'store=',
};

const ship = {
  key: 'ship',
  urlPrefix: 'ship=',
};

const sort = {
  key: 'sort',
  urlPrefix: 'sort=',
  sortByMap: {
    distance: 'distance',
    sellingPrice: 'price',
    miles: 'mileage',
    year: 'year',
    dateinstockticks: 'new-arrivals',
  },
  sortDirMap: {
    asc: 'low',
    desc: 'high',
  },
};

const radius = {
  key: 'radius',
  urlPrefix: 'radius=',
  radiusMap: {
    nationwide: {
      key: 'nationwide',
      value: -1,
    },
  },
};

export const facetMetaData: FacetMetaData = {
  mmt,
  trim,
  bodystyle,
  extcolor,
  intcolor,
  enginedescription,
  transmission,
  feature,
  drivetype,
  price,
  payment,
  year,
  miles,
  hmpg,
  page,
  dealer,
  store,
  ship,
  sort,
  radius,
  model,
};

export const sortMap = [
  { key: 'distance', sortBy: 'distance' },
  { key: 'price', sortBy: 'sellingPrice' },
  { key: 'mileage', sortBy: 'miles' },
  { key: 'year', sortBy: 'year' },
  { key: 'new', sortBy: 'dateinstockticks' },
];

export const urlSortOrder = [
  mmt.key,
  bodystyle.key,
  extcolor.key,
  intcolor.key,
  enginedescription.key,
  transmission.key,
  feature.key,
  drivetype.key,
  price.key,
  payment.key,
  year.key,
  miles.key,
  hmpg.key,
  radius.key,
];

export const filterChipSortOrder = [
  mmt.key,
  bodystyle.key,
  price.key,
  payment.key,
  miles.key,
  year.key,
  feature.key,
  hmpg.key,
  enginedescription.key,
  drivetype.key,
  transmission.key,
  extcolor.key,
  intcolor.key,
];

export function getNumericKeys() {
  return Object.entries(facetMetaData)
    .filter(([_key, value]) => value['type'] === 'numeric')
    .map(([key, _value]) => key);
}

export function getStringKeys() {
  return Object.entries(facetMetaData)
    .filter(([_key, value]) => value['type'] === 'string')
    .map(([key, _value]) => key);
}

export function orderedFacets(facetFilters: FacetFilters, sortOrder: string[]) {
  if (
    !facetFilters ||
    typeof facetFilters !== 'object' ||
    !sortOrder ||
    !Array.isArray(sortOrder)
  ) {
    return {};
  }

  const sortedKeys = Object.keys(facetFilters).sort(
    (a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)
  );

  const orderedFacets = {};
  sortedKeys.forEach((key) => {
    orderedFacets[key] = facetFilters[key];
  });

  return orderedFacets;
}

export function getMmtKeys(obj) {
  let keys: string[] = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key !== 'selected' && !key.includes('|')) {
      keys.push(key);
      if (typeof obj[key] === 'object') {
        keys = keys.concat(getMmtKeys(obj[key]));
      }
    }
  }

  return keys.filter((item) => !mmt.valuesToFilter.includes(item));
}

export function getCanonicalMmtKeys(obj) {
  let keys: string[] = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key !== 'selected' && !key.includes('|')) {
      keys.push(key);
      if (typeof obj[key] === 'object') {
        keys = keys.concat(getCanonicalMmtKeys(obj[key]));
        break;
      }
    }
  }

  return keys.filter((item) => !mmt.valuesToFilter.includes(item));
}
