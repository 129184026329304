import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "color-filter",
  "data-testid": "color-filter"
}
const _hoisted_2 = { "data-role": "filter-toggle" }
const _hoisted_3 = { class: "lblTitle" }
const _hoisted_4 = { class: "text-toggle d-flex justify-center" }
const _hoisted_5 = {
  class: "colorFilter",
  "data-testid": "exterior-colors"
}
const _hoisted_6 = {
  class: "colorFilter",
  "data-testid": "interior-colors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMenuLineItem = _resolveComponent("FilterMenuLineItem")!
  const _component_TextToggle = _resolveComponent("TextToggle")!
  const _component_FacetList = _resolveComponent("FacetList")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterMenuLineItem, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandFilter())),
            isSelected: 
            _ctx.facetFilters[_ctx.extColorFilterType] || _ctx.facetFilters[_ctx.intColorFilterType] ? true : false
          ,
            title: _ctx.title,
            "data-for": _ctx.title
          }, null, 8, ["isSelected", "title", "data-for"])
        ], 512), [
          [_vShow, !_ctx.colorFilterSelected && !_ctx.filterSelected]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_TextToggle, {
              options: _ctx.toggleOptions,
              modelValue: _ctx.exteriorSelected,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.exteriorSelected) = $event))
            }, null, 8, ["options", "modelValue"])
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FacetList, {
              modelValue: _ctx.facetModelExt,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.facetModelExt) = $event)),
              data: _ctx.facetListExtColor,
              filterType: _ctx.extColorFilterType
            }, null, 8, ["modelValue", "data", "filterType"])
          ], 512), [
            [_vShow, _ctx.exteriorSelected]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FacetList, {
              modelValue: _ctx.facetModelInt,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.facetModelInt) = $event)),
              data: _ctx.facetListIntColor,
              filterType: _ctx.intColorFilterType
            }, null, 8, ["modelValue", "data", "filterType"])
          ], 512), [
            [_vShow, !_ctx.exteriorSelected]
          ])
        ], 512), [
          [_vShow, _ctx.colorFilterSelected]
        ])
      ])
    ]),
    _: 1
  }))
}