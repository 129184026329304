import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "mpg-engine-type-filter",
  "data-testid": "mpg-engine-type-filter"
}
const _hoisted_2 = { "data-role": "facet-filter" }
const _hoisted_3 = {
  class: "mpgRangeFrameInput",
  "data-testid": "range-input"
}
const _hoisted_4 = { class: "mpgRangeFrameSlider" }
const _hoisted_5 = {
  class: "engineTypeFilter",
  style: {"max-height":"50%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMenuLineItem = _resolveComponent("FilterMenuLineItem")!
  const _component_RangeInput = _resolveComponent("RangeInput")!
  const _component_RangeSlider = _resolveComponent("RangeSlider")!
  const _component_FacetList = _resolveComponent("FacetList")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterMenuLineItem, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandFilter())),
            isSelected: !!(_ctx.facetFilters[_ctx.filterTypeMpg] || _ctx.facetFilters[_ctx.filterTypeEngine]),
            title: _ctx.$t('MPG Engine Filter Title')
          }, null, 8, ["isSelected", "title"])
        ], 512), [
          [_vShow, !_ctx.mpgEngineFilterSelected && !_ctx.filterSelected]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_RangeInput, {
              title: _ctx.titleMpg,
              range: _ctx.mpgRange,
              onInputValueSet: _ctx.updateMpgStore,
              onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setUpdateStoreMpg = true)),
              setRange: _ctx.setRange,
              minLabel: _ctx.minLabel,
              maxLabel: _ctx.maxLabel,
              "data-action": "range-input",
              "component-name": "MpgEngineTypeFilter"
            }, null, 8, ["title", "range", "onInputValueSet", "setRange", "minLabel", "maxLabel"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_RangeSlider, {
              style: {"width":"80%"},
              range: _ctx.mpgRange,
              setRange: _ctx.setRange,
              modelValue: _ctx.mpgModel,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mpgModel) = $event)),
              onSliderValueUpdated: _ctx.updateMpgRange,
              onSliderValueSet: _ctx.updateMpgStore,
              "data-action": "range-slider"
            }, null, 8, ["range", "setRange", "modelValue", "onSliderValueUpdated", "onSliderValueSet"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_FacetList, {
              modelValue: _ctx.engineModel,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.engineModel) = $event)),
              title: _ctx.titleEngineType,
              data: _ctx.facetList,
              filterType: _ctx.filterTypeEngine
            }, null, 8, ["modelValue", "title", "data", "filterType"])
          ])
        ], 512), [
          [_vShow, _ctx.mpgEngineFilterSelected]
        ])
      ])
    ]),
    _: 1
  }))
}