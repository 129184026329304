<template>
  <transition name="slide">
    <div
      class="body-style-filter"
      data-component-name="body-style-filter"
      data-testid="body-style-filter"
    >
      <div v-show="!bodyStyleFilterSelected && !filterSelected" data-role="facet-filter">
        <FilterMenuLineItem
          @click="expandFilter()"
          :isSelected="!!facetFilters[filterType]"
          :title="$t('Body Style Filter Title')"
          :data-for="$t('Body Style Filter Title')"
        />
      </div>
      <div v-show="bodyStyleFilterSelected">
        <div class="bodyStyleFilter">
          <FacetList
            :title="title"
            :data="facetList"
            :filterType="filterType"
            v-model="facetModel"
          ></FacetList>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import FacetList from './FacetList.vue';
import FilterMenuLineItem from './FilterMenuLineItem.vue';
import { facetMetaData } from '@util/facetHelper';

export default defineComponent({
  name: 'BodyStyleFilter',
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FacetList,
    FilterMenuLineItem,
  },
  data() {
    return {
      title: this.$t('Body Style Filter Title'),
      filterType: facetMetaData.bodystyle.key,
      cachedCount: {},
      updateCachedCount: true,
    };
  },
  computed: {
    ...mapState('searchResults', {
      facetFilters: (state) => state.facetFilters ?? {},
      filterSelected: (state) => state.filterSelected,
      bodyStyleFilterSelected: (state) => state.bodyStyleFilterSelected,
      vehicleData: (state) => state.srpVehiclesData,
      bodyStyleCount: (state) => state.facetCounts?.bodystyle || {},
      recentSelectedFilter: (state) => state.recentSelectedFilter,
    }),
    facetList() {
      const facets = this.fields?.BodyStyle?.map((x) => ({
        name: x.displayName,
        value: this.getCount(x),
        scImage: x.fields?.BodyStyleIcon,
      }));

      return facets;
    },
    getCount() {
      return (x) =>
        this.bodyStyleCount.hasOwnProperty(x.displayName) ? this.bodyStyleCount[x.displayName] : 0;
    },
    icons() {
      return 'BodyStyleIcons';
    },
    facetModel() {
      return this.facetList;
    },
  },
  methods: {
    ...mapMutations('searchResults', [
      'setFilterSelected',
      'setBodyStyleFilterSelected',
      'setResetSelectedFacetItems',
    ]),
    expandFilter() {
      this.setFilterSelected(true);
      this.setBodyStyleFilterSelected(true);
      this.setResetSelectedFacetItems(true);
    },
  },
  watch: {
    recentSelectedFilter(newValue, oldValue) {
      if (newValue === this.filterType && oldValue !== this.filterType)
        this.updateCachedCount = false;
      else this.updateCachedCount = true;
    },
    bodyStyleCount() {
      this.cachedCount = this.bodyStyleCount;
    },
    bodyStyleFilterSelected(isSelected) {
      if (isSelected) {
        this.emitter.emit('fetch-facet-count', this.filterType);
      }
    },
    facetFilters: {
      handler: async function () {
        if (this.updateCachedCount && this.bodyStyleFilterSelected) {
          this.emitter.emit('fetch-facet-count', this.filterType);
          this.cachedCount = this.bodyStyleCount;
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss">
.body-style-filter {
  .facet-list {
    width: 100%;
  }
}
.bodyStyleFilter {
  padding-top: 20px;
  padding-bottom: 20px;

  .filter-selected & {
    padding-top: 0;
  }
}
</style>
