import config from './temp/config';

const apiKey = process.env.SITECORE_API_KEY || config.sitecoreApiKey;
let apiHost = process.env.SITECORE_API_HOST || config.sitecoreApiHost;
let sitecoreApiHost;

if (typeof window !== 'undefined' && config.deployedEnv && config.deployedEnv != 'false') {
  apiHost = '/';
  sitecoreApiHost = `${window.location.protocol}//${window.location.hostname}/`;
} else if (!apiHost.endsWith('/')) {
  apiHost = apiHost + '/';
  sitecoreApiHost = apiHost;
}
const DEALERSHIP_API_URL = `${apiHost}api/dealership-search`;
const VEHICLE_API_URL = `${apiHost}api/vehicle-inventory-search`;
const STORES_API_URL = `${apiHost}api/sitecore/Stores/IsUSAZipcode`;
const LOGIN_API_URL = `${apiHost}api/cxa/EchoParkLogin/LoginUser`;
const REGISTRATION_API_URL = `${apiHost}/api/cxa/Registration/RegisterUser`;
const COUNT_API_URL = `${apiHost}api/facet-counts`;

export default {
  sitecoreApiKey: apiKey,
  sitecoreApiHost: sitecoreApiHost || '/',
  jssAppName: config.jssAppName || 'echopark-jss-app',
  site: config.jssAppName,
  defaultLanguage: config.defaultLanguage || 'en',
  graphQLEndpointPath: !config.deployedEnv ? config.graphQLEndpointPath : '/api/echopark-jss-app',
  graphQLEndpoint: !config.deployedEnv
    ? config.graphQLEndpoint
    : `${apiHost}api/echopark-jss-app?sc_apikey=${apiKey}`,
  dealershipApiEndpoint: DEALERSHIP_API_URL,
  vehicleApiEndpoint: VEHICLE_API_URL,
  storesApiEndpoint: STORES_API_URL,
  loginApiEndpoint: LOGIN_API_URL,
  registrationApiEndpoint: REGISTRATION_API_URL,
  countApiEndpoint: COUNT_API_URL,
  layoutServiceConfigurationName: config.layoutServiceConfigurationName,
};
