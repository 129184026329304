import { vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "overlay-container",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick())),
    style: _normalizeStyle({ 'z-index': _ctx.zIndex })
  }, null, 4)), [
    [_vShow, _ctx.showOverlay]
  ])
}