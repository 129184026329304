import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vehicleInfoButtons" }
const _hoisted_2 = { class: "vehicleInfoButtonsCalculator" }
const _hoisted_3 = { class: "vehicleInfoButtonsTestDrive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SrpButton = _resolveComponent("SrpButton")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_SrpButton, {
        class: "ignoreFullTileClick",
        onclick: "document.activeElement.blur()",
        calculator: "",
        onlyIcon: ""
      })
    ]),
    _createElementVNode("span", _hoisted_3, [
      (_ctx.showTestDriveLink)
        ? (_openBlock(), _createBlock(_component_v_btn, {
            key: 0,
            class: "test-drive-button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.triggerOverlayHandler('ScheduleTestDrive')), ["prevent","stop"])),
            "data-testid": "test-drive-openTestDriveButton"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { icon: "ep-icon-test-drive" }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('srp2.scheduleTestDrive.buttonText')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.showTestDriveLink)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.openAskAboutUsModal()), ["prevent","stop"])),
            onKeyup: [
              _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.openAskAboutUsModal()), ["space"])),
              _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.openAskAboutUsModal()), ["enter"]))
            ]
          }, [
            _createVNode(_component_SrpButton, {
              class: "ignoreFullTileClick",
              onclick: "document.activeElement.blur()",
              buttonText: _ctx.$t('askUs'),
              subtract: ""
            }, null, 8, ["buttonText"])
          ], 32))
        : _createCommentVNode("", true)
    ])
  ]))
}