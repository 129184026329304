<template>
  <v-row class="style-guide--tooltips">
    <v-col>
      <h3>Default Tooltip</h3>
      <p>
        The default tootip with no location or class will display on hover to the right of the
        target.
      </p>
      <v-tooltip
        text="This is the default tooltip. It shows to the right of the button when you hover it."
      >
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props">Default Tooltip (right)</v-btn>
        </template>
      </v-tooltip>
      <code>
        &lt;v-tooltip text="This is the default tooltip. It shows to the right of the button when
        you hover it."&gt;<br />
        &nbsp;&nbsp;&lt;template v-slot:activator="{ props }"&gt;<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&lt;v-btn v-bind="props"&gt;Default Tooltip (right)&lt;/v-btn&gt;<br />
        &nbsp;&nbsp;&lt;/template&gt;<br />
        &lt;/v-tooltip&gt;
      </code>
    </v-col>
    <v-col>
      <h3>Defined Location</h3>
      <p>Add a location and class to the tooltip element to move where the tooltip is shown.</p>
      <div>
        <v-tooltip
          text="This tooltip shows above the button when you hover it."
          location="top"
          class="top"
        >
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props">Tooltip Top</v-btn>
          </template>
        </v-tooltip>
        <code>&lt;v-tooltip location="top" class="top" /&gt;</code>
      </div>
      <div>
        <v-tooltip
          text="This tooltip shows below the button when you hover it."
          location="bottom"
          class="bottom"
        >
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props">Tooltip Bottom</v-btn>
          </template>
        </v-tooltip>
        <code>&lt;v-tooltip location="bottom" class="bottom" /&gt;</code>
      </div>
      <div>
        <v-tooltip
          text="This tooltip shows to the left of the button when you hover it."
          location="left"
          class="left"
        >
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props">Tooltip Left</v-btn>
          </template>
        </v-tooltip>
        <code>&lt;v-tooltip location="left" class="left" /&gt;</code>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Tooltips',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
@use '@/assets/styles/variables' as *;

.style-guide--tooltips {
  code {
    width: fit-content;
    background: $park-gray-100;
    border-radius: $border-radius-md;
    color: $base-black-100 !important;
    margin: $spacing_stack-xxxs 0 $spacing_stack-sm;
    padding: $spacing_inset-xs;
  }
}
</style>
