import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@icons/icons-menu-close.svg'


const _hoisted_1 = {
  class: "zipchange-header",
  "data-component-name": "zip-location-edit"
}
const _hoisted_2 = { class: "ziplocation-header-top" }
const _hoisted_3 = {
  key: 0,
  class: "zipLocation_flyout_close"
}
const _hoisted_4 = { class: "location-info-text mb-2" }
const _hoisted_5 = { class: "zip-styling" }
const _hoisted_6 = { class: "small-info-text" }
const _hoisted_7 = { class: "zipInput-wrapper" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "zip-flyout-validation" }
const _hoisted_10 = {
  key: 0,
  class: "field-validation-error"
}
const _hoisted_11 = {
  key: 1,
  class: "field-validation-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_image = _resolveComponent("sc-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showClose)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setZipFlyout(false)))
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_sc_text, {
          field: _ctx.fields.titleFlyout
        }, null, 8, ["field"]),
        _createVNode(_component_sc_image, {
          class: "icon-location-header-flyout",
          media: _ctx.fields.iconLocationFlyout
        }, null, 8, ["media"]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.zipCode), 1),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_sc_text, {
            field: _ctx.fields.descriptionFlyout
          }, null, 8, ["field"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue) = $event)),
        placeholder: _ctx.$t('Zip'),
        autocomplete: "postal-code",
        onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.changeZip && _ctx.changeZip(...args)), ["enter"])),
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        maxlength: "5",
        "data-for": "new-zip-code"
      }, null, 40, _hoisted_8), [
        [_vModelText, _ctx.modelValue]
      ]),
      _createElementVNode("button", {
        type: "button",
        class: "change-zip-button",
        role: "button",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.changeZip && _ctx.changeZip(...args))),
        "data-action": "commit-zip-code-change"
      }, _toDisplayString(_ctx.$t('srp.common.change')), 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.zipValidationFailed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t('SRP2.ValidationMessages.ValidZipCode')), 1))
        : _createCommentVNode("", true),
      (_ctx.zipUpdated)
        ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('SRP2.ValidationMessages.ChangeZipSuccessMessage')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}