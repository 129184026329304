<template>
  <div>
    <h1>Page not found</h1>
    <p>This page does not exist.</p>
    <p v-if="context && context.site">
      Site: {{ context.site.name }}
      <br />
      Language: {{ context.language }}
    </p>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';

export default {
  name: 'not-found',
  head() {
    const { t } = useI18n();
    return { title: t('page-not-found') };
  },
  props: {
    context: {
      type: Object,
    },
  },
};
</script>
