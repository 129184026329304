import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "shop-by-store-toggle",
  "data-role": "toggle-shopping-options"
}
const _hoisted_2 = { class: "center-options" }
const _hoisted_3 = { class: "shoppingRadius w-100" }
const _hoisted_4 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_5 = { class: "mb-6 mx-6" }
const _hoisted_6 = { class: "shopByRadiusLabel" }
const _hoisted_7 = {
  class: "storesInRadiusLabel text-center",
  "data-testid": "storesInRadiusLabel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextToggle = _resolveComponent("TextToggle")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FacetDealershipPicker = _resolveComponent("FacetDealershipPicker")!

  return (_ctx.toggleComponentVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TextToggle, {
            options: _ctx.toggleOptions,
            modelValue: _ctx.shopByStore,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shopByStore) = $event))
          }, null, 8, ["options", "modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (!_ctx.shopByStore)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Shopping Radius')), 1),
                  _createVNode(_component_Dropdown, {
                    class: "custom-select mt-0",
                    options: _ctx.radiusOptions,
                    modelValue: _ctx.selectedRadiusModel,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedRadiusModel) = $event)),
                    "data-action": "toggle-shop-by-radius"
                  }, null, 8, ["options", "modelValue"])
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getText()), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.shopByStore)
            ? (_openBlock(), _createBlock(_component_FacetDealershipPicker, {
                key: 1,
                label: "Select your stores",
                showMoreText: "Show more",
                showLessText: "Show less",
                "data-action": "select-dealership"
              }))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}