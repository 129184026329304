import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["content"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.visitorIdentificationTimestamp)
    ? (_openBlock(), _createElementBlock("meta", {
        key: 0,
        name: "VIcurrentDateTime",
        content: _ctx.visitorIdentificationTimestamp
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}