<template>
  <div class="vehicleInfoLocation">
    <div class="vehicleInfoLocationPickup">
      {{ shippingLabel }}
    </div>
    <div class="vehicleInfoLocationName">
      {{ shippingStore }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VehicleCardLocation',
  props: {
    shippingLabel: String,
    shippingStore: String,
  },
});
</script>

<style lang="scss">
.vehicleInfoLocation {
  text-align: right;
  color: var(--park-gray-0);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: 16px;
  display: flex;
  flex-flow: column;
  align-self: flex-end;
  padding-bottom: 7px;
}
.vehicleInfoLocationPickup {
  color: var(--park-gray-0);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.vehicleInfoLocationName {
  color: var(--park-gray-0);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
</style>
