<template>
  <Flyout :isOpen="zipFlyout" @close="setZipFlyout(false)">
    <div class="zip-flyout">
      <div
        class="zipLocation_flyout newZipLocation"
        aria-hidden="true"
        tabindex="-1"
        :style="zipFlyout ? 'right: 0' : ''"
      >
        <div class="zipLocation_flyout_title">
          <ZipLocationEdit :fields="fields" :showClose="true" v-model="zipCode" />
        </div>
        <div class="zip-flyout-locations">
          <ZipNearestStoresDisplay
            :fields="fields"
            :dealerships="dealerships"
            :closestDealership="nearestDealership"
            :loading="loading"
          />
        </div>
      </div>
    </div>
  </Flyout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { mapState, mapMutations, mapGetters } from 'vuex';
import ZipLocationEdit from '@components-vue/Feature/ZipFlyout/ZipLocationEdit.vue';
import ZipNearestStoresDisplay from '@components-vue/Feature/ZipFlyout/ZipNearestStoresDisplay.vue';
import Flyout from '@components-vue/Foundation/Common/Overlay/Flyout.vue';

export default defineComponent({
  name: 'ZipFlyout',
  methods: {
    ...mapMutations('common', ['setZipFlyout', 'setZipCode']),
  },
  components: {
    Flyout,
    ZipLocationEdit,
    ZipNearestStoresDisplay,
  },
  computed: {
    ...mapState('common', ['zipFlyout', 'dealerships', 'zipCode']),
    ...mapGetters('common', ['nearestDealership']),
    zipCode: {
      get() {
        return this.$store.state['common'].zipCode;
      },
      set(value) {
        this.setZipCode(value);
        this.emitter.emit('filter-updated-srp');
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>

<style lang="scss">
.zip-flyout-locations {
  margin: 32px -24px;
}
</style>
