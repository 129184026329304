import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { "data-component-name": "year-filter" }
const _hoisted_2 = { "data-role": "facet-filter" }
const _hoisted_3 = {
  class: "yearRangeFrameDropdown",
  "data-role": "drop-down-list"
}
const _hoisted_4 = {
  class: "yearRangeFrameSlider",
  "data-role": "range-slider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterMenuLineItem = _resolveComponent("FilterMenuLineItem")!
  const _component_RangeDropdown = _resolveComponent("RangeDropdown")!
  const _component_RangeSlider = _resolveComponent("RangeSlider")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FilterMenuLineItem, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expandFilter())),
            isSelected: !!_ctx.facetFilters['year'],
            title: _ctx.title
          }, null, 8, ["isSelected", "title"])
        ], 512), [
          [_vShow, !_ctx.yearFilterSelected && !_ctx.filterSelected]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_RangeDropdown, {
              title: _ctx.title,
              selected: _ctx.yearRange.map((option) => String(option)),
              options: _ctx.yearOptions,
              onOptionSelected: _ctx.optionSelected,
              step: 1,
              minLabel: _ctx.minLabel,
              maxLabel: _ctx.maxLabel
            }, null, 8, ["title", "selected", "options", "onOptionSelected", "minLabel", "maxLabel"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_RangeSlider, {
              step: 1,
              range: _ctx.yearRange,
              setRange: _ctx.setRange,
              modelValue: _ctx.yearModel,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.yearModel) = $event)),
              onSliderValueUpdated: _ctx.updateYearRange,
              onSliderValueSet: _ctx.updateYearStore
            }, null, 8, ["range", "setRange", "modelValue", "onSliderValueUpdated", "onSliderValueSet"])
          ])
        ], 512), [
          [_vShow, _ctx.yearFilterSelected]
        ])
      ])
    ]),
    _: 1
  }))
}