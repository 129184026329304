import mitt, { Emitter } from 'mitt';
import { Events } from '@/types/events';

const emitter: Emitter<Events> = mitt<Events>();

/* Handle vue-independent events */
/* Other events are handled within the vue component tree */
emitter.on('analytics', (eventData) => {
  if (typeof window !== 'undefined') {
    if (window['dataLayer']) {
      window['dataLayer'].push(eventData);
    } else {
      window['dataLayer'] = [];
      window['dataLayer'].push(eventData);
    }
  }
});

export default emitter;
