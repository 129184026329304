let _resetDefault = false;

export function onRegistrationMount() {
  $('#txtRegFirstName').focus(function () {
    focus('#txtRegFirstName');
  });

  $('#txtRegFirstName').blur(function () {
    focusOut('#txtRegFirstName');
  });

  $('#txtRegLastName').focus(function () {
    focus('#txtRegLastName');
  });

  $('#txtRegLastName').blur(function () {
    focusOut('#txtRegLastName');
  });

  $('#useremailid').focus(function () {
    focus('#useremailid');
  });

  $('#useremailid').blur(function () {
    focusOut('#useremailid');
  });

  $('#txtRegPhoneNumber').focus(function () {
    focus('#txtRegPhoneNumber');
  });

  $('#txtRegPhoneNumber').blur(function () {
    focusOut('#txtRegPhoneNumber');
  });

  $('#reg_enter_password').focus(function () {
    focusPassword('#reg_enter_password');
  });

  $('#reg_enter_password').blur(function () {
    focusOutPassword('#reg_enter_password');
  });

  $('#reg_confirm_password').focus(function () {
    focusPassword('#reg_confirm_password');
  });

  $('#reg_confirm_password').blur(function () {
    focusOutPassword('#reg_confirm_password');
  });

  $('#txtRegFirstName').keyup(function () {
    validateRequired('First Name', '#txtRegFirstName');
  });

  $('#txtRegLastName').keyup(function () {
    validateRequired('Last Name', '#txtRegLastName');
  });

  $('#useremailid').keyup(function () {
    validateRequired('Email', '#useremailid');
  });

  $('#useremailid').blur(function () {
    validateEmail();
  });

  $('#txtRegPhoneNumber').blur(function () {
    validateNumber();
  });

  $('#reg_enter_password').keyup(function () {
    validateRequiredPassword('Password', '#reg_enter_password');
    validatePasswordRequirements();
  });

  $('#reg_enter_password').blur(function () {
    validateRequiredPassword('Password', '#reg_enter_password');
    validatePassword();
  });

  $('#reg_enter_password').focus(function () {
    $('.password-info').show();
  });

  $('#reg_confirm_password').keyup(function () {
    validateRequiredPassword('Confirmation Password', '#reg_confirm_password');
  });

  $('#reg_confirm_password').keyup(function () {
    validateRequiredPassword('Confirmation Password', '#reg_confirm_password');
  });

  $('#reg_confirm_password').blur(function () {
    validadeConfirmPassword();
  });

  $('#viewPasswordIconRegistration').click(function () {
    $('#viewPasswordIconRegistration').toggleClass('off');

    if ('password' == $('#viewPasswordIconRegistration').parent().find('input').attr('type')) {
      $('#viewPasswordIconRegistration').parent().find('input').prop('type', 'text');
    } else {
      $('#viewPasswordIconRegistration').parent().find('input').prop('type', 'password');
    }
  });

  $('#viewPasswordIconRegistrationConfirm').click(function () {
    $('#viewPasswordIconRegistrationConfirm').toggleClass('off');

    if (
      'password' == $('#viewPasswordIconRegistrationConfirm').parent().find('input').attr('type')
    ) {
      $('#viewPasswordIconRegistrationConfirm').parent().find('input').prop('type', 'text');
    } else {
      $('#viewPasswordIconRegistrationConfirm').parent().find('input').prop('type', 'password');
    }
  });

  $('#txtRegPhoneNumber').on('input', function (e) {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  });

  $('#RegisterForm').submit(function (e) {
    e.preventDefault();
    if (validateSubmit()) {
      const formData = {};
      var dataArray = $('#RegisterForm').serializeArray();
      for (var i = 0; i < dataArray.length; i++) {
        formData[dataArray[i].name] = dataArray[i].value;
      }

      $.ajax({
        type: 'POST',
        url: e.target.action,
        data: formData,
        dataType: 'json',
        beforeSend: function (_results) {
          $('.flyoutLoader').show();
          DisableForm();
        },
        success: function (results) {
          $('.flyoutLoader').hide();
          EnableForm();

          if (results !== undefined) {
            if (results.HasErrors != undefined && results.HasErrors) {
              const customError = results.Errors.length > 1 ? results.Errors[1] : results.Errors[0];
              $('#pRegistationErrorMessage').html(customError);
              $('#spPreQualify').removeClass('hide');
              $('#pRegistationErrorMessage').show();
            } else if (results.Success != undefined && results.Success) {
              $('#registrationContainer').toggleClass(['show_panel', 'show-createAccount'], false);
              $('.registrationOuterContainer').toggleClass('d-none', true);

              $('#accountConfirmationContainer').addClass(['show_panel', 'create_account']);
              $('.accountConfirmationOuterContainer').toggleClass('d-none', false);

              const manageAccountPath = '/my-account';

              // Update the icon in the header
              $('.user_profile .guest-user').prop({
                href: manageAccountPath,
                rel: '',
                class: 'registered-user',
              });
            } else {
              // console.log(results);
            }
          }
        },
        error: function (error) {
          console.error(error);
          $('.flyoutLoader').hide();
          EnableForm();
        },
      });
    }
  });
}
function validateRequired(name, id) {
  if ($(id).val().length == 0) {
    $(id).removeClass('valid');
    $(id).addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(document.createTextNode(name + ' is required.'));
    var span = $(id).parent().find('.field-validation-valid')[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $(id).parent().find('.field-validation-valid').addClass('field-validation-error');
      $(id).parent().find('.field-validation-valid').removeClass('field-validation-valid');
    }
    return false;
  } else {
    $(id).addClass('valid');
    $(id).removeClass('input-validation-error');
    var span = $(id).parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $(id).parent().find('.field-validation-error').addClass('field-validation-valid');
      $(id).parent().find('.field-validation-error').removeClass('field-validation-error');
    }
    return true;
  }
}

function validateRequiredPassword(name, id) {
  if ($(id).val().length == 0) {
    $(id).removeClass('valid');
    $(id).addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(document.createTextNode(name + ' is required.'));
    var span = $(id).parent().parent().find('.field-validation-valid')[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $(id).parent().parent().find('.field-validation-valid').addClass('field-validation-error');
      $(id).parent().parent().find('.field-validation-valid').removeClass('field-validation-valid');
    }
    return false;
  } else {
    $(id).addClass('valid');
    $(id).removeClass('input-validation-error');
    var span = $(id).parent().parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $(id).parent().parent().find('.field-validation-error').addClass('field-validation-valid');
      $(id).parent().parent().find('.field-validation-error').removeClass('field-validation-error');
    }
    return true;
  }
}

function validateEmail() {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if ($('#useremailid').val().length == 0) return false;
  if (!regex.test($('#useremailid').val())) {
    $('#useremailid').removeClass('valid');
    $('#useremailid').addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(
      document.createTextNode('Oops! Check that you have an @ sign and domain provided.')
    );
    var span = $('#useremailid').parent().find('.field-validation-valid')[0];
    if (span) {
      $('#useremailid').parent().find('.field-validation-valid').addClass('field-validation-error');
      $('#useremailid')
        .parent()
        .find('.field-validation-valid')
        .removeClass('field-validation-valid');
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
    }
    return false;
  } else {
    $('#useremailid').addClass('valid');
    $('#useremailid').removeClass('input-validation-error');
    var span = $('#useremailid').parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $('#useremailid').parent().find('.field-validation-error').addClass('field-validation-valid');
      $('#useremailid')
        .parent()
        .find('.field-validation-error')
        .removeClass('field-validation-error');
    }
    return true;
  }
}

function validatePasswordRequirements() {
  let password = $('#reg_enter_password').val();
  let valid = true;
  if (password.length >= 6) {
    $('#length').addClass('valid');
  } else {
    $('#length').removeClass('valid');
    valid = false;
  }
  var regex = /(?=.*[A-Z])/;
  if (regex.test($('#reg_enter_password').val())) {
    $('#capital').addClass('valid');
  } else {
    $('#capital').removeClass('valid');
    valid = false;
  }
  regex = /(?=.*[a-z])/;
  if (regex.test($('#reg_enter_password').val())) {
    $('#lowercase').addClass('valid');
  } else {
    $('#lowercase').removeClass('valid');
    valid = false;
  }
  regex = /(?=.*\d)/;
  if (regex.test($('#reg_enter_password').val())) {
    $('#numerical').addClass('valid');
  } else {
    $('#numerical').removeClass('valid');
    valid = false;
  }
  return valid;
}

function validatePassword() {
  if (validatePasswordRequirements() == false) {
    $('#reg_enter_password').removeClass('valid');
    $('#reg_enter_password').addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(
      document.createTextNode(
        'Enter a password that is 6 or more characters, with at least 1 number, 1 uppercase letter, and 1 lowercase letter.'
      )
    );
    var span = $('#reg_enter_password').parent().parent().find('.field-validation-valid')[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $('#reg_enter_password')
        .parent()
        .parent()
        .find('.field-validation-valid')
        .addClass('field-validation-error');
      $('#reg_enter_password')
        .parent()
        .parent()
        .find('.field-validation-valid')
        .removeClass('field-validation-valid');
    }
  } else {
    $('#reg_enter_password').addClass('valid');
    $('#reg_enter_password').removeClass('input-validation-error');
    var span = $('#reg_enter_password').parent().parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $('#reg_enter_password')
        .parent()
        .parent()
        .find('.field-validation-error')
        .addClass('field-validation-valid');
      $('#reg_enter_password')
        .parent()
        .parent()
        .find('.field-validation-error')
        .removeClass('field-validation-error');
    }
  }
}

function validadeConfirmPassword() {
  if ($('#reg_confirm_password').val().length == 0) return false;
  if ($('#reg_confirm_password').val() != $('#reg_enter_password').val()) {
    $('#reg_confirm_password').removeClass('valid');
    $('#reg_confirm_password').addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(document.createTextNode('Passwords do not match.'));
    var span = $('#reg_confirm_password').parent().parent().find('.field-validation-valid')[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $('#reg_confirm_password')
        .parent()
        .parent()
        .find('.field-validation-valid')
        .addClass('field-validation-error');
      $('#reg_confirm_password')
        .parent()
        .parent()
        .find('.field-validation-valid')
        .removeClass('field-validation-valid');
    }
    return false;
  } else {
    $('#reg_confirm_password').addClass('valid');
    $('#reg_confirm_password').removeClass('input-validation-error');
    var span = $('#reg_confirm_password').parent().parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $('#reg_confirm_password')
        .parent()
        .parent()
        .find('.field-validation-error')
        .addClass('field-validation-valid');
      $('#reg_confirm_password')
        .parent()
        .parent()
        .find('.field-validation-error')
        .removeClass('field-validation-error');
    }
    return true;
  }
}

function DisableForm() {
  $('*', 'form').prop('disabled', true);
}

function EnableForm() {
  $('*', 'form').prop('disabled', false);
}

function validateNumber() {
  if ($('#txtRegPhoneNumber').val().length == 0) return true;
  let number = $('#txtRegPhoneNumber').val();

  number = number.replace(/([-()])+/g, '');
  number = number.replace(' ', '');
  if (number.length < 10) {
    $('#txtRegPhoneNumber').removeClass('valid');
    $('#txtRegPhoneNumber').addClass('input-validation-error');
    var spanError = document.createElement('SPAN');
    spanError.appendChild(document.createTextNode('Enter a 10-digits phone number'));
    var span = $('#txtRegPhoneNumber').parent().find('.field-validation-valid')[0];
    if (span) {
      if (span.innerHTML.length <= 0) span.appendChild(spanError);
      $('#txtRegPhoneNumber')
        .parent()
        .find('.field-validation-valid')
        .addClass('field-validation-error');
      $('#txtRegPhoneNumber')
        .parent()
        .find('.field-validation-valid')
        .removeClass('field-validation-valid');
    }
    return false;
  } else {
    $('#txtRegPhoneNumber').addClass('valid');
    $('#txtRegPhoneNumber').removeClass('input-validation-error');
    var span = $('#txtRegPhoneNumber').parent().find('.field-validation-error')[0];
    if (span) {
      if (span.innerHTML.length > 0) span.innerHTML = '';
      $('#txtRegPhoneNumber')
        .parent()
        .find('.field-validation-error')
        .addClass('field-validation-valid');
      $('#txtRegPhoneNumber')
        .parent()
        .find('.field-validation-error')
        .removeClass('field-validation-error');
    }

    return true;
  }
}

function validateSubmit() {
  let valid = true;
  if (validateRequired('First Name', '#txtRegFirstName') == false) valid = false;
  if (validateRequired('Last Name', '#txtRegLastName') == false) valid = false;
  if (validateRequired('Email', '#useremailid') == false) valid = false;
  if (validateRequiredPassword('Password', '#reg_enter_password') == false) valid = false;
  if (validateRequiredPassword('Confirmation Password', '#reg_confirm_password') == false)
    valid = false;
  if (validateEmail() == false) valid = false;
  if (validateNumber() == false) valid = false;
  if (validadeConfirmPassword() == false) valid = false;
  if (validatePasswordRequirements() == false) valid = false;
  return valid;
}

export const focus = (id) => {
  if (!$(id).parent().hasClass('focused')) $(id).parent().addClass('focused');
  if (!$(id).parent().hasClass('Filled')) $(id).parent().addClass('Filled');
};

export const focusOut = (id) => {
  $(id).parent().removeClass('Filled');
  if ($(id).val().length === 0) $(id).parent().removeClass('focused');
  else if (!$(id).parent().hasClass('Active')) $(id).parent().addClass('Active');
};

export const focusPassword = (id) => {
  if (!$(id).parent().parent().hasClass('focused')) $(id).parent().parent().addClass('focused');
  if (!$(id).parent().parent().hasClass('Filled')) $(id).parent().parent().addClass('Filled');
};

export const focusOutPassword = (id) => {
  $(id).parent().parent().removeClass('Filled');
  if ($(id).val().length === 0) $(id).parent().parent().removeClass('focused');
  else if (!$(id).parent().parent().hasClass('Active')) $(id).parent().parent().addClass('Active');
};
