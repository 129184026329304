<template>
  <div class="style-guide--forms-and-inputs">
    <v-row>
      <v-col cols="12" md="6">
        <h3>Checkboxes</h3>
        <v-checkbox :model-value="false" label="Unchecked"></v-checkbox>
        <v-checkbox :model-value="true" label="Checked"></v-checkbox>
        <v-checkbox :model-value="false" label="Disabled [Unchecked]" disabled></v-checkbox>
        <v-checkbox :model-value="true" label="Disabled [Checked]" disabled></v-checkbox>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Radios</h3>
        <v-radio-group v-model="radios">
          <v-radio label="Option 01 [Selected by default]" value="one"></v-radio>
          <v-radio label="Option 02" value="two"></v-radio>
          <v-radio label="Option 03" value="three"></v-radio>
        </v-radio-group>
        <v-radio-group v-model="disabledRadios">
          <v-radio label="Option 04 [Disabled, Unselected]" value="four" disabled></v-radio>
          <v-radio label="Option 05 [Disabled, Selected]" value="five" disabled></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Toggles / Switches</h3>
        <v-switch label="Default"></v-switch>
        <v-switch label="Selected" :model-value="true"></v-switch>
        <v-switch label="Disabled" disabled></v-switch>
        <v-switch label="Disabled [Selected]" :model-value="true" disabled></v-switch>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Sliders</h3>
        <v-slider v-model="slider" strict>
          <template v-slot:prepend>
            <v-label>Lower Bound</v-label>
          </template>
          <template v-slot:append>
            <v-label>Upper Bound</v-label>
          </template>
        </v-slider>
        <v-range-slider v-model="sliderRange" strict>
          <template v-slot:prepend>
            <v-label>Lower Bound</v-label>
          </template>
          <template v-slot:append>
            <v-label>Upper Bound</v-label>
          </template>
        </v-range-slider>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <h3>Segmented Controls</h3>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn-toggle v-model="segmentedControl1" class="segmented-controls">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn-toggle v-model="segmentedControl2" class="segmented-controls active-green">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn-toggle v-model="segmentedControl3" class="segmented-controls background-white">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <h3>Box Selectors</h3>
          </v-col>
          <v-col cols="12" md="4" style="max-width: 400px">
            <v-btn-toggle v-model="boxSelectors" class="box-selectors">
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
              <v-btn>Label</v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Text Fields</h3>
        <v-text-field :rules="[rules.required]" label="First name"></v-text-field>
        <v-text-field :rules="[rules.required]" label="Last name"></v-text-field>
        <v-text-field
          label="Email address"
          placeholder="johndoe@gmail.com"
          type="email"
        ></v-text-field>
        <v-text-field label="Disabled Input" model-value="Filled out" disabled></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <h3>Selects</h3>
        <v-select
          label="Select a number"
          :items="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]"
          :rules="[rules.required]"
        ></v-select>
        <v-select
          label="State"
          :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
          :rules="[rules.required]"
        ></v-select>
        <v-select
          label="Disabled Select"
          :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"
          disabled
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <h3>Textareas</h3>
        <v-textarea label="Please provide your comments"></v-textarea>
        <v-textarea label="This textarea is disabled" disabled></v-textarea>
      </v-col>
      <v-col cols="12" md="6">
        <h3>&nbsp;</h3>
        <v-textarea
          :rules="[rules.required]"
          label="This textarea is required"
          model-value="This is pre-filled"
        ></v-textarea>
        <v-textarea :rules="[rules.required]" label="This textarea is required"></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--Colors',
  components: {},
  data() {
    return {
      boxSelectors: 1,
      disabledRadios: 'five',
      radios: 'one',
      rules: {
        required: (value) => !!value || 'Field is required',
      },
      segmentedControl1: 1,
      segmentedControl2: 3,
      segmentedControl3: 2,
      slider: [35],
      sliderRange: [20, 70],
    };
  },
});
</script>

<style lang="scss">
.style-guide--forms-and-inputs {
  h3 {
    margin-bottom: 20px;
  }
}
</style>
