<template>
  <SearchController>
    <div class="contentBlock">
      <div class="filter" :class="{ filterExpanded: showOverlay }">
        <placeholder name="filter-menu" :rendering="rendering" />
      </div>

      <div id="divChipContentContainer" class="srp-main-content">
        <div class="filter-and-sort-menu-container">
          <placeholder name="sort-menu" :rendering="rendering" />
        </div>
        <div class="filter-chip-main">
          <placeholder
            name="filter-chip"
            :rendering="rendering"
            data-testid="filter-chip-container"
          />
        </div>
        <div class="srp-content">
          <placeholder name="srp-content" :rendering="rendering" />
        </div>
      </div>

      <Overlay :showOverlay="showOverlay" :clickEvent="'toggle-filter-menu'" />
    </div>
  </SearchController>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { mapMutations } from 'vuex';
import Overlay from '@components-vue/Foundation/Common/Overlay/Overlay.vue';
import SearchController from '@components-vue/Listeners/SearchController.vue';
import { ComponentRendering, Placeholder } from '@sitecore-jss/sitecore-jss-vue';

export default defineComponent({
  name: 'VehicleSearchContainer',
  components: { SearchController, Placeholder, Overlay },
  data() {
    return {
      showOverlay: false,
    };
  },
  props: {
    fields: {
      type: Object as PropType<Object>, // TODO: add type here
      default: () => ({}),
    },
    rendering: {
      type: Object as PropType<ComponentRendering>,
      default: () => ({}),
    },
  },
  methods: {
    ...mapMutations('searchResults', ['setQueryParams']),
    handleResize() {
      if (typeof window !== 'undefined' && window.innerWidth >= 1240) {
        this.showOverlay = false;
      }
    },
    triggerOverlayHandler(name: string) {
      this.emitter.emit('overlay-handler:open', name);
    },
    triggerCalculatorFlyoutHandler(vin: string) {
      this.emitter.emit('calculator-flyout:open', vin);
    },
  },
  mounted() {
    this.emitter.on('toggle-filter-menu', () => {
      this.showOverlay = !this.showOverlay;
    });

    this.setQueryParams(this.$route.query);
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
});
</script>

<style lang="scss">
.srp-main-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.contentBlock {
  background-color: var(--whitesmoke);
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

.filter-chip-main {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 70;

  .scrollUp & {
    top: 72px;
    z-index: 99;
  }
}

@media only screen and (min-width: 1240px) {
  .filter-and-sort-menu-container {
    order: 2;
  }
  .filter-chip-main {
    order: 1;
  }
  .srp-content {
    order: 3;
  }
}

.filterExpanded {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  flex: none;
  width: 100%;

  @media only screen and (min-width: 625px) {
    width: auto;
  }

  @media only screen and (min-width: 1240px) {
    flex: unset;
  }
}
</style>
