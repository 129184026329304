import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  class: "btnText",
  tabindex: "0",
  "data-testid": "filter-chip-non-distance-ship-button-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "filter-chip-item",
    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click'))),
    id: _ctx.id,
    tabindex: "-1"
  }, [
    _renderSlot(_ctx.$slots, "value", {}, () => [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.value), 1)
    ]),
    (_ctx.isCloseable)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "closeBtn",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"])),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"]), ["enter"])),
          tabindex: "0"
        }, " × ", 32))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}