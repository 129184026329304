import axios from 'axios';
import appConfig from '@/appConfig';
import { Dealership } from '@/types/ServiceModels';

export default {
  getDealerships: async function (zipCode) {
    const apiUrl = appConfig.dealershipApiEndpoint;
    let result: Dealership[] | null = null;
    await axios
      .get(apiUrl, { params: { zip: zipCode } })
      .then((resp) => {
        result = resp.data.items;
      })
      .catch((e) => {
        console.error(e);
      });
    return result;
  },
};
