import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@icons/ArrowPath.svg'
import _imports_1 from '@icons/check.png'
import _imports_2 from '@icons/Path_MMT.svg'


const _hoisted_1 = { class: "h-list-container" }
const _hoisted_2 = {
  key: 0,
  class: "h-list-title"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick", "onKeyup"]
const _hoisted_5 = { class: "image-icon" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "Option",
  class: "imgArrow"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "Check",
  class: "imgCheck"
}
const _hoisted_8 = { class: "lblName" }
const _hoisted_9 = { class: "lblCount ml-1" }
const _hoisted_10 = ["onClick", "onKeyup"]
const _hoisted_11 = ["alt"]
const _hoisted_12 = ["onClick", "onKeyup"]
const _hoisted_13 = { class: "image-icon" }
const _hoisted_14 = {
  key: 0,
  src: _imports_0,
  alt: "Option",
  class: "imgArrow"
}
const _hoisted_15 = {
  key: 1,
  src: _imports_1,
  alt: "Check",
  class: "imgCheck"
}
const _hoisted_16 = { class: "lblName" }
const _hoisted_17 = { class: "lblCount ml-1" }
const _hoisted_18 = ["onClick", "onKeyup"]
const _hoisted_19 = ["alt"]
const _hoisted_20 = {
  key: 2,
  class: "h-no-items"
}
const _hoisted_21 = {
  key: 3,
  class: "h-no-items d-flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.data && _ctx.data.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedFacets, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["h-list-element", { selected: _ctx.isSelected(`${item.fullName}~${_ctx.id}`) }]),
              key: `${item.fullName}~${_ctx.id}`
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["divItemInfo", {
            'hover-underline': _ctx.isSelected(`${item.fullName}~${_ctx.id}`),
          }]),
                style: _normalizeStyle({
            width: _ctx.isSelected(`${item.fullName}~${_ctx.id}`) && _ctx.subMenu ? '80%' : '100%',
          }),
                onClick: ($event: any) => (_ctx.deselect(`${item.fullName}~${_ctx.id}`)),
                onKeyup: _withKeys(($event: any) => (_ctx.deselect(`${item.fullName}~${_ctx.id}`)), ["enter"]),
                tabindex: "0"
              }, [
                _createElementVNode("div", _hoisted_5, [
                  (item.descendant)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                    : _createCommentVNode("", true),
                  (_ctx.isSelected(`${item.fullName}~${_ctx.id}`))
                    ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(item.name), 1),
                    _createElementVNode("span", _hoisted_9, "(" + _toDisplayString(item.value.toLocaleString()) + ")", 1)
                  ])
                ])
              ], 46, _hoisted_4),
              (!item.lastChild && _ctx.subMenu && _ctx.isSelected(`${item.fullName}~${_ctx.id}`))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "h-sub-menu d-flex align-center",
                    onClick: ($event: any) => (_ctx.onSelect(item.name)),
                    onKeyup: _withKeys(($event: any) => (_ctx.onSelect(item.name)), ["enter"]),
                    tabindex: "0"
                  }, [
                    _createTextVNode(_toDisplayString(item.subMenu ? item.subMenu : _ctx.subMenu), 1),
                    _createElementVNode("img", {
                      src: _imports_2,
                      class: "arrow-icon ml-1",
                      alt: _ctx.$t('srp.common.rightArrowIcon')
                    }, null, 8, _hoisted_11)
                  ], 40, _hoisted_10))
                : _createCommentVNode("", true)
            ], 2))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notSelectedFacets, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["h-list-element", { selected: _ctx.isSelected(`${item.fullName}~${_ctx.id}`) }]),
              key: `${item.fullName}~${_ctx.id}`
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["divItemInfo", {
            'hover-underline': _ctx.isInSelection(`${item.fullName}~${_ctx.id}`),
          }]),
                style: _normalizeStyle({
            width: _ctx.isInSelection(`${item.fullName}~${_ctx.id}`) && _ctx.subMenu ? '80%' : '100%',
          }),
                onClick: ($event: any) => (_ctx.select(`${item.fullName}~${_ctx.id}`)),
                onKeyup: _withKeys(($event: any) => (_ctx.select(`${item.fullName}~${_ctx.id}`)), ["enter"]),
                tabindex: "0"
              }, [
                _createElementVNode("div", _hoisted_13, [
                  (item.descendant)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                    : _createCommentVNode("", true),
                  (_ctx.isInSelection(`${item.fullName}~${_ctx.id}`))
                    ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, [
                    _createElementVNode("span", _hoisted_16, _toDisplayString(item.name), 1),
                    _createElementVNode("span", _hoisted_17, "(" + _toDisplayString(item.value.toLocaleString()) + ")", 1)
                  ])
                ])
              ], 46, _hoisted_12),
              (!item.lastChild && _ctx.subMenu && _ctx.isInSelection(`${item.fullName}~${_ctx.id}`))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "h-sub-menu d-flex align-center",
                    onClick: ($event: any) => (_ctx.onSelect(item.name)),
                    onKeyup: _withKeys(($event: any) => (_ctx.onSelect(item.name)), ["enter"]),
                    tabindex: "0"
                  }, [
                    _createTextVNode(_toDisplayString(item.subMenu ? item.subMenu : _ctx.subMenu), 1),
                    _createElementVNode("img", {
                      src: _imports_2,
                      class: "arrow-icon ml-1",
                      alt: _ctx.$t('srp.common.rightArrowIcon')
                    }, null, 8, _hoisted_19)
                  ], 40, _hoisted_18))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_20, "No items to display")),
    (_ctx.data && !_ctx.data.some((x) => !(x as MMTFilterSelection).hidden))
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, " No Results "))
      : _createCommentVNode("", true)
  ]))
}