<template>
  <div class="vehicleInfoPrice">
    <div class="vehicleInfoPriceFullPrice" data-testid="vehicle-price">
      <span class="vehicleInfoPriceFullPriceCurrencySymbol">{{ currencySymbol }}</span
      >{{ formatVehiclePrice(vehicle.sellingPrice) }}
    </div>
    <div
      class="vehicleInfoPriceInstallmentPrice"
      v-if="showPayEstimate"
      data-testid="vehicle-payment"
    >
      {{
        formatVehicleInstallmentPrice(
          Math.floor(vehicle.estimatedPayLow ?? 0.0),
          Math.ceil(vehicle.estimatedPayHigh ?? 0.0)
        )
      }}{{ $t('Monthly payment') }}
      <span class="tooltipContainer">
        <v-tooltip :text="$t('financing-tooltip')" location="top" class="top">
          <template v-slot:activator="{ props }">
            <img
              :id="`priceTooltipIcon-${vehicle.vin}`"
              src="~@icons/info.svg"
              tabindex="0"
              v-bind="props"
              @click.stop.prevent
              :alt="$t('financing-tooltip')"
            />
          </template>
        </v-tooltip>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { formatVehiclePrice, formatVehicleInstallmentPrice } from '@util/formatter';
import { Vehicle } from '@/types/ServiceModels';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'VehicleCardPrice',
  data() {
    return {
      show: false,
      focus: false,
      currencySymbol: '$',
    };
  },
  props: {
    vehicle: {
      type: Object as PropType<Vehicle>,
      required: true,
    },
    showPayEstimate: Boolean,
  },
  methods: {
    formatVehiclePrice,
    formatVehicleInstallmentPrice,
  },
});
</script>

<style lang="scss">
.vehicleInfoPrice {
  flex: 1;

  .vehicleInfoLocation {
    flex: 1;
  }
  .vehicleInfoPriceFullPrice {
    color: var(--system-green-50);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
  .vehicleInfoPriceFullPriceCurrencySymbol {
    font-size: 14px;
    vertical-align: text-top;
    line-height: 28px;
  }
  .vehicleInfoPriceInstallmentPrice {
    color: var(--park-gray-0);
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    display: flex;
  }
  .tooltipContainer {
    z-index: 1;
    position: relative;
    opacity: 100;
    display: flex;
    align-items: center;

    img {
      height: 16px;
      width: 16px;
      margin-left: 4px;

      &:focus-visible {
        border-radius: 50%;
      }
    }
  }
  .tooltip {
    border-color: transparent;

    .tooltip-inner {
      border-radius: 6px;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      background: var(--park-gray-0);
      padding: 16px;
      text-align: center;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: Roboto, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.2px;
    }
    .arrow::before {
      top: -0.5px;
      border-top-color: var(--park-gray-0);
    }
  }
}
</style>
