import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "show-me-subtext" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoundCheckToggleSwitch = _resolveComponent("RoundCheckToggleSwitch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.shipToStoreContainer)
    }, [
      _createElementVNode("label", null, _toDisplayString(_ctx.$t('Ship to store')), 1),
      _createVNode(_component_RoundCheckToggleSwitch, {
        modelValue: _ctx.shipToStoreToggle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.shipToStoreToggle) = $event)),
        disabled: _ctx.shopByStore,
        componentName: "shipToStoreContainer"
      }, null, 8, ["modelValue", "disabled"])
    ], 2),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('Show me cars that can ship to a nearby store')), 1)
  ]))
}