import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "round-check-toggle-switch" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["checked", "disabled", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "switch",
      for: _ctx.componentName,
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateToggle && _ctx.updateToggle(...args)), ["prevent"])),
      onKeyup: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateToggle && _ctx.updateToggle(...args)), ["prevent"])),
      tabindex: "0"
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.toggleValue) = $event)),
        checked: _ctx.toggleValue,
        disabled: _ctx.disabled,
        id: _ctx.componentName
      }, null, 8, _hoisted_3), [
        [_vModelCheckbox, _ctx.toggleValue]
      ]),
      _createElementVNode("span", {
        class: _normalizeClass(["slider round", { 'toggle-disabled': _ctx.disabled }])
      }, null, 2)
    ], 40, _hoisted_2)
  ]))
}