import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["selected", "value", "data-action-change"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    class: "select-dropdown",
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateOptions && _ctx.updateOptions(...args))),
    ref: "selectItem",
    "data-role": "drop-down-list"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        selected: option.value === _ctx.modelValue,
        key: option.value,
        value: option.value,
        "data-action-change": option.value
      }, _toDisplayString(option.label), 9, _hoisted_1))
    }), 128))
  ], 544))
}