<template>
  <transition>
    <div>
      <div class="zip-location-container">
        <ZipLocationEdit :fields="fields" v-model="zipCode" />
      </div>
      <ZipNearestStoresDisplay
        :fields="fields"
        :dealerships="dealerships"
        :closestDealership="nearestDealership"
        data-action="change-zip-code"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapState, mapMutations, mapGetters } from 'vuex';
import ZipLocationEdit from '@components-vue/Feature/ZipFlyout/ZipLocationEdit.vue';
import ZipNearestStoresDisplay from '@components-vue/Feature/ZipFlyout/ZipNearestStoresDisplay.vue';

export default defineComponent({
  name: 'ZipLocation',
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    ZipLocationEdit,
    ZipNearestStoresDisplay,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('common', ['zipCode', 'dealerships']),
    ...mapGetters('common', ['nearestDealership']),
    ...mapState('searchResults', ['radius']),
    zipCode: {
      get() {
        return this.$store.state['common'].zipCode;
      },
      set(value) {
        this.setZipCode(value);
        this.emitter.emit('filter-updated-srp');
      },
    },
  },
  methods: {
    ...mapMutations('searchResults', ['setRadius']),
    ...mapMutations('common', ['setZipCode']),
    toggleFilterMenu() {
      this.emitter.emit('toggle-filter-menu');
    },
  },
});
</script>

<style lang="scss">
.zip-location-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 32px;
}
</style>
