import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contentBlock" }
const _hoisted_2 = {
  id: "divChipContentContainer",
  class: "srp-main-content"
}
const _hoisted_3 = { class: "filter-and-sort-menu-container" }
const _hoisted_4 = { class: "filter-chip-main" }
const _hoisted_5 = { class: "srp-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_placeholder = _resolveComponent("placeholder")!
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_SearchController = _resolveComponent("SearchController")!

  return (_openBlock(), _createBlock(_component_SearchController, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["filter", { filterExpanded: _ctx.showOverlay }])
        }, [
          _createVNode(_component_placeholder, {
            name: "filter-menu",
            rendering: _ctx.rendering
          }, null, 8, ["rendering"])
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_placeholder, {
              name: "sort-menu",
              rendering: _ctx.rendering
            }, null, 8, ["rendering"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_placeholder, {
              name: "filter-chip",
              rendering: _ctx.rendering,
              "data-testid": "filter-chip-container"
            }, null, 8, ["rendering"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_placeholder, {
              name: "srp-content",
              rendering: _ctx.rendering
            }, null, 8, ["rendering"])
          ])
        ]),
        _createVNode(_component_Overlay, {
          showOverlay: _ctx.showOverlay,
          clickEvent: 'toggle-filter-menu'
        }, null, 8, ["showOverlay"])
      ])
    ]),
    _: 1
  }))
}