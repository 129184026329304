<template>
  <v-container>
    <v-row>
      <v-col>
        <rich-text :field="fields.BodyCopy" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import { RichText, ComponentRendering } from '@sitecore-jss/sitecore-jss-vue';

export default defineComponent({
  name: 'RichTextOverlay',
  components: {
    RichText,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    rendering: {
      type: Object as PropType<ComponentRendering>,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    errorComponent: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>

<style lang="scss"></style>
