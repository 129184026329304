const HierarchicalFacetFactory = (function () {
  function canHandle(type: string) {
    return type === 'hierarchical';
  }

  function create(name: string, value: any) {
    function toQueryString() {
      const exctractedString = extractFacetStringFromHierarchy(value.facetValue);
      return `${name}:${exctractedString}`;
    }

    return {
      toQueryString,
    };
  }

  function extractFacetStringFromHierarchy(obj: any) {
    const combinations: any[] = [];

    function extractCombos(prefix: string, currentObj: any) {
      for (const key in currentObj) {
        if (currentObj[key].selected) {
          const tempCombo = prefix ? `${prefix}|${key}` : key;

          if (Object.values(currentObj[key]).some((x: any) => x?.selected === true)) {
            extractCombos(tempCombo, currentObj[key]);
          } else {
            combinations.push(tempCombo);
          }
        }
      }
    }

    extractCombos('', obj);
    return combinations.join('~');
  }

  return {
    canHandle,
    create,
  };
})();

const NumericRangeFacetFactory = (function () {
  function canHandle(type: string) {
    return type === 'numericRange';
  }

  function create(name: string, value: { min: string; max: string }) {
    function toQueryString() {
      return `${name}:${value.min}|${value.max}`;
    }

    return {
      toQueryString,
    };
  }

  return {
    canHandle,
    create,
  };
})();

const StringFacetFactory = (function () {
  function canHandle(type: string) {
    return type === 'string';
  }

  function create(name: string, value: any) {
    function toQueryString() {
      return `${name}:${value.facetValue.join('~')}`;
    }

    return {
      toQueryString,
    };
  }

  return {
    canHandle,
    create,
  };
})();

export const FacetFiltersManager = function () {
  const facetFilters: any[] = [];
  const facetFilterTypes = [NumericRangeFacetFactory, StringFacetFactory, HierarchicalFacetFactory];

  const addFacetFilter = function (filter: any) {
    facetFilters.push(filter);
  };

  const facetCount = function () {
    return facetFilters.length;
  };

  const createFacetFilter = function (name: string, value: any) {
    for (const filterKey in facetFilterTypes) {
      const facetFilterType = facetFilterTypes[filterKey];

      if (facetFilterType.canHandle(value.type)) {
        return facetFilterType.create(name, value);
      }
    }
    throw `Unable to handle ${name} facet of type ${value.type}`;
  };

  const toQueryString = function () {
    if (facetFilters.length === 0) {
      return '';
    }

    return facetFilters.map((x) => x.toQueryString()).join(',');
  };

  return {
    addFacetFilter,
    createFacetFilter,
    toQueryString,
    facetCount,
  };
};
