import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_NavigationZipCode = _resolveComponent("NavigationZipCode")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sc_rich_text, {
      field: _ctx.fields.html
    }, null, 8, ["field"]),
    (_ctx.mounted && _ctx.targetElement != null)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: _ctx.targetElement
        }, [
          _createVNode(_component_NavigationZipCode, { zipcode: _ctx.zipCode }, null, 8, ["zipcode"])
        ], 8, ["to"]))
      : _createCommentVNode("", true)
  ]))
}