<template>
  <v-row class="style-guide--border-radius">
    <v-col cols="12" md="4" lg="3" xxl="2">
      <div class="box black border-radius-none"></div>
      <code>.border-radius-none</code>
      <code>$border-radius-none</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xxl="2">
      <div class="box black border-radius-sm"></div>
      <code>.border-radius-sm</code>
      <code>$border-radius-sm</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xxl="2">
      <div class="box black border-radius-md"></div>
      <code>.border-radius-md</code>
      <code>$border-radius-md</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xxl="2">
      <div class="box black border-radius-lg"></div>
      <code>.border-radius-lg</code>
      <code>$border-radius-lg</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xxl="2">
      <div class="box black border-radius-pill"></div>
      <code>.border-radius-pill</code>
      <code>$border-radius-pill</code>
    </v-col>
    <v-col cols="12" md="4" lg="3" xxl="2">
      <div class="box black border-radius-circular"></div>
      <code>.border-radius-circular</code>
      <code>$border-radius-circular</code>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StyleGuide--BorderRadius',
  components: {},
  data() {
    return {};
  },
});
</script>

<style lang="scss">
.style-guide--border-radius {
  .box.border-radius-pill {
    width: 100px;
    height: 40px;
    margin-top: 24px;
  }
}
</style>
