<!-- To prevent the missing dealerships on the initial load, we
 need to pre-populate dealerships when the app is loaded - currently
 this happens in SearchController in vue2, but we need to adjust for
 vue3 to be more generic -->
<template>
  <div class="storeCards mb-2">
    <div class="closestStore">
      <div class="closest-store-text mb-4">Your nearest pickup store:</div>
      <DealershipCard
        v-if="dealerships"
        :fields="fields"
        :dealership="closestDealership"
        :directionsOrigin="zipCode"
      />
    </div>

    <div class="other-stores-text">
      <sc-text :field="fields?.otherStoreDescription" />
    </div>

    <!-- Show nearest store (above) + the 2 following nearest -->
    <div v-for="index in 2" :key="index" class="dealer-cards-wrapper">
      <DealershipCard
        v-if="dealerships"
        :fields="fields"
        :dealership="dealerships[index]"
        :directionsOrigin="zipCode"
      />

      <!-- no line on the last one -->
      <span v-if="index < 2" class="zipFlyout-information-divider my-4"></span>
    </div>

    <div class="div-loading flyoutLoader" :class="{ 'd-block': loading }"></div>

    <div class="all-stores-selection">
      <a class="all-stores-link" v-bind:href="fields?.allStoresLink.value" target="_BLANK">
        <sc-text :field="fields?.allStoresDescription" />
      </a>
      <div class="arrow-icon"><sc-image :media="fields?.allStoresIcon" /></div>
    </div>
  </div>
  <!-- end storeCards div -->
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Dealership } from '@/types/ServiceModels';
import DealershipCard from '@components-vue/Feature/Shop/DealershipCard.vue';
import { Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ZipNearestStoresDisplay',
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
    dealerships: {
      type: Array as PropType<Dealership[]>,
      default: () => [],
    },
    closestDealership: {
      type: Object as PropType<Dealership>,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DealershipCard,
    ScText: Text,
    ScImage: Image,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('common', ['zipCode']),
  },
});
</script>

<style lang="scss">
.storeCards {
  display: flex;
  flex-direction: column;
}
.all-stores-selection {
  padding-top: 32px;
  margin: 0 auto;

  .arrow-icon {
    display: inline-block;
    padding: 0 8px;
  }
}
.all-stores-link {
  color: var(--supplementary-blue-60);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration: none;
  padding-right: 4px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--supplementary-blue-60);
  }
  &:active {
    cursor: pointer;
    text-decoration: underline;
    color: var(--supplementary-blue-50);
  }
}
.closestStore {
  background-color: var(--park-gray-100) !important;
  padding: 16px 24px;
  border-top: 1px solid var(--park-gray-90);
  border-bottom: 1px solid var(--park-gray-90);
}
.closest-store-text {
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: var(--park-gray);
}
.other-stores-text {
  color: var(--park-gray);
  font-size: 16px;
  font-family: Roboto, serif;
  font-weight: 300;
  line-height: 24px;
  word-wrap: break-word;
  margin: 32px 24px 24px 24px;
}
.dealer-cards-wrapper {
  margin: 0 24px;
}
.zipFlyout-information-divider {
  border-bottom: solid 1px var(--park-gray-90);
  display: block;
}
</style>
