import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "modal show ask-us-modal",
  id: "AskUsModal"
}
const _hoisted_2 = { class: "askUsModalContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AskUsForm = _resolveComponent("AskUsForm")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AskUsForm, {
            askUsVehicle: _ctx.getVehicleByVin(_ctx.selectedVin)
          }, null, 8, ["askUsVehicle"])
        ])
      ]))
    : _createCommentVNode("", true)
}